<template>
  <v-divider></v-divider>
  <v-tooltip location="right">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        @click="setGroupInModal"
        class="mx-auto justify-start elevation-0 primaryBackground cursorPointer"
        style="width: 100%; display: flex; align-items: center;"
      >
        <div class="contentWrapper pl-4 py-2 primaryBackground">
          <div class="notranslate text-white">
        {{ group.groupName }} 
          </div>
        </div>
      </div>
    </template>
    <span v-html="groupMembersTooltip()"></span>
  </v-tooltip>
  
  <v-divider v-if="isLastItem"></v-divider>
</template>

<script>
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import DefaultAvatar from "../image/defaultAvatar.vue";
import { setSendGroupChatMessageModalEvent, setFinishGroupChatEvent } from "../../effector/modals";

export default {
  props: ["group","admin", "section", "typeData", "isLastItem"],
  components: {
    DefaultAvatar,
    DeleteBasket,
  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      showGroupProfile: false,
      failedImage: false,
      isMobile: isMobile(),
    };
  },
  methods: {
    groupMembersTooltip() {
      return this.group.members.filter(member => !member.isRemoved).map((member) => {
        return `${member.user?.user?.name}`;
      }).join("<br>");
    },
    getGroupTitle(group, admin) {
      console.log('group', admin);
      return this.group.groupName || "Unknown Group";
    },
    removeGroup() {
      // setFinishGroupChatEvent(this.group)
    },
    setGroupInModal() {
      setSendGroupChatMessageModalEvent({
        show: true,
        groupData: this.group, 
        messageUIDs:[]
      });
    },
    onImgError() {
      this.failedImage = true;
    },
  },
  computed: {
    avatarSrc() {
      return this.failedImage ? "img/default_profile_picture.png" : this.admin?.user?.avatar || "img/default_profile_picture.png";
    },
    adminName() {
      return this.admin?.user?.name || "Unknown User";
    },
    validGroup() {
      return this.admin && this.group && this.admin.user.name && this.group.groupName;
    },
  },
};
</script>

<style lang="scss">
.customePadding {
  padding-left: 16px !important;
  padding-inline-start: 16px !important;
}

.removeAvatarButtons {
  position: absolute;
  border-radius: 10px;
  text-align: center;
  top: 50%;
  left: 5px;
  &:hover {
    display: block;
  }
}
.removeAvatarButtons:hover {
  display: block;
}
.buttonTrashStatus{
  background: transparent;
  border: none;
  box-shadow: none;
}

.contentWrapper {
  max-width: 180px;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.cursorPointer {
  cursor: pointer;
}
.grayScale {
  filter: grayscale(100%);
}
</style>
