<template>
  <v-card flat class="mx-auto primaryBackground" width="100%">
    <GroupSearch
    :active="active"
    :setList="setList"
    :mini="mini"
    :isGuest="isGuest"
    :isMobile="isMobile"
    :setCurrentContentVisile="setCurrentContentVisile"
    :filterGroup="filterGroup"
    :sortedGroups="sortedGroups"
    :searchFoundSomethingGroup="searchFoundSomethingGroup"
    v-if="searchFoundSomethingGroup && state.showGroupSearch"
    />
  </v-card>
  </template>


<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import { isMobile } from "../../lib/mobileUtil";
import GroupSearch from "./groupSearch.vue";
import { useStore } from "effector-vue/composition";;
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { groupChattingInfoStore, getGroupChatByUserUIdEvent} from "../../effector/users/groupChattingInfo";

export default {
  components: {
    GroupSearch
  },
  props: ["setList", "active", "groupChattingFor", "mini" ,"openSidebar"],
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
      groupUsesEffect: groupChattingInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      moment,
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      openedList: false,
      opened: [],
      sortedGroupChatting: [],
      // Effector
      ...effector,
    };
  },
  mounted() {
    this.handleGroupChatting();
  },
  watch: {
    getGroupUsesEffect: {
      immediate: true,
      deep:true,
      handler(newPromise) {
        if (newPromise instanceof Promise) {
          newPromise
            .then(response => {
              this.sortedGroupChatting = Object.values(response.groups || {});
            })
            .catch(error => {
              console.error("Error fetching group chatting info:", error);
            });
        }
      },
    },
    groupChattingList: {
      immediate: true,
      deep:true,
      handler: function (groupList) {
        console.log("groupChattingList", groupList);
      },
    },
    mini() {
      if (this._debounceOpener) clearTimeout(this._debounceOpener);
      if (this.mini || this.opened?.length) {
        this.openedList = !this.mini || this.opened?.length;
      } else {
        this._debounceOpener = setTimeout(() => {
          this.openedList = !this.mini || this.opened?.length;
        }, 1200);
      }
    },
},
  methods: {
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },  
    handleGroupChatting() {
      getGroupChatByUserUIdEvent();
    },
    filterGroup(group) {
        return(this.state.searchGroupTerm.length === 0 ||
          `${(group.groupName || "")}`.trim().toLowerCase().replace(/\s{2,}/g, ' ').indexOf(
            this.state.searchGroupTerm.trim().toLowerCase().replace(/\s{2,}/g, ' ')
          ) !== -1)
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    }
  },
  computed: {
    getGroupUsesEffect() {
      return this.groupUsesEffect;
    },
    groupChattingAlias() {
      return store.state.namespaceSettings.groupChattingAlias;
    },
    isActive() {
      return this.active == "groupchatting";
    },
    getTitleGroupChatting() {
      const groupChattingName = this.$t(
        "components.drawerContentExpansion.groupchat"
      );
      if (this.groupChattingFor === this.state.ownUUID) {
        return groupChattingName
      } else {
        return (
          groupChattingName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.groupChattingFor) +
          " " +
          this.getNameForUuid(this.groupChattingFor)
        );
      }
    },
    groupList() {
      return store.getGroupList(this.ownUUID);
    },
    getconnectedGroupChattings() {
      let connectedGroupChattings = [];
      return connectedGroupChattings;
    },
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    searchFoundSomethingGroup() {
        console.log(this.state.searchGroupTerm, this.sortedGroups, this.state.showGroupSearch, (this.state.searchGroupTerm.length >= 3 && this.sortedGroups.length > 0), this.state.searchGroupTerm.length, this.sortedGroups.length);
      return (
        this.state.searchGroupTerm.length >= 3 && this.sortedGroups.length > 0
      );
    },
    groupList() {
      return store.getGroupList(this.ownUUID);
    },
    sortedGroups() {
      const groups = [];
      for (const group of this.sortedGroupChatting) {
        if (group?.groupName) {
          groups.push({ ...group });
        }
      }
      return groups.filter(this.filterGroup).sort((a, b) => {
        if (a.connected && !b.connected) {return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a || {}).groupName || a.uuid || "").localeCompare(
          (b || {}).groupName || b.uuid || ""
        );
    });
    },
}
}
</script>
<style scoped lang="scss">
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
</style>