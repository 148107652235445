const FileSizeOptions = {
    "1mb": "1mb",
    "10mb": "10mb",
    "20mb": "20mb",
    "50mb": "50mb",
    "100mb": "100mb",
    "250mb": "250mb",
    "500mb": "500mb",
    "1gb": "1gb",
    "Unlimited": "Unlimited"
};


// Helper function to get an array of options
function getFileSizeOptionValues(t) {
    return Object.keys(FileSizeOptions).map((key) => ({
      value: FileSizeOptions[key],
      text: t(`components.adminSettingsModal.fileSizeOptions.${key}`)
    }));
}

module.exports = { FileSizeOptions, getFileSizeOptionValues };