<template>
  <v-list :opened="opened" @update:opened="newOpened => opened = newOpened" max-width="320" @click.stop.prevent>
    <v-list-group
      v-bind:disabled="state.searchTerm !== '' ? true : false"
      active-class="listColor"
      @click="showHideSearch()"
      value="groupchatting"
      v-if="!mini && !isGuest"
      :expand-icon="state.showGroupSearch ? null : '$expand'"
    >
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" @click="handlerClickGroupChatting($event)">
          <template v-slot:prepend>
            <v-icon size="20"
              class="primary--text-sidepanel"
            >
              <font-awesome-icon
                :icon="['fal', 'user-friends']"
                class="primary--text-sidepanel iconsSize20"
              />
            </v-icon>
          </template>
          <v-badge
            @click.native="handleGroupChatting()"
            v-if="getconnectedGroupChattings > 0"
            right
            color="error"
            :content="getconnectedGroupChattings"
            :value="getconnectedGroupChattings"
            bordered
            overlap
            offset-x="25"
            offset-y="-5"
            class="groupChattingBadge"
          ></v-badge>
          <v-list-item-title
            class="pr-4 primary--text-sidepanel cursorPointer w100"
            v-if="!mini"
            @click.stop.prevent="showHideSearch"
          >
          <span v-if="!state.showGroupSearch" class="primary--text-sidepanel">
              {{ $t("generics.searchGroup") }} <br />
              <hr class="hrGroups"
            /></span>
            <DrawerContentGroupSearch :searchType="'group'" v-if="state.showGroupSearch"/>
            <template v-if="!state.showGroupSearch">
              <v-tooltip location="top" v-if="isGuest && !state.showGroupSearch && !mini">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">
                    {{ getTitleGroupChatting }}
                    {{
                      `${
                        getconnectedGroupChattings > 0
                          ? "(" + getconnectedGroupChattings + ")"
                          : ""
                      }`
                    }}
                  </span>
                </template>
                <span>{{ groupChattingTT }}</span>
              </v-tooltip>
            </template>
            <v-tooltip location="top" v-if="!state.showGroupSearch">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  density="compact"
                  class="btnAddGroup"
                  @click.stop.prevent="openGroupChattingDialog(null)"
                >
                  <font-awesome-icon
                    class="primary--text-sidepanel"
                    :icon="['fal', 'plus-circle']"
                    :style="{
                      fontSize: '20px',
                    }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.sidebar.groupChattingMiniTT") }}</span>
            </v-tooltip>
          </v-list-item-title>
          <CustomTT
            v-if="showCustomTT"
            :text="`${$t('components.hotkeys.controlKey')} + G`"
            position="bottom"
            :extraclass="'ctrr'"
          />
        </v-list-item>
      </template>
      <GroupList
        v-if="openedList && !state.showGroupSearch"
        :groups="sortedGroupChatting"
        :section="'guests'"
        typeData="groupchatting"
      />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="20" @click="openGroupChattingDialog(null)">
            <font-awesome-icon
              :icon="['fal', 'user-friends']"
              class="primary--text-sidepanel iconsSize20"
            />
            <v-badge
              @click.native="handleGroupChatting()"
              v-if="getconnectedGroupChattings > 0"
              right
              color="error"
              :content="getconnectedGroupChattings"
              :value="getconnectedGroupChattings"
              bordered
              overlap
              offset-x="-10"
              offset-y="-5"
              :class="!!getconnectedGroupChattings ? 'badgetWithData': ''"
              class="groupChattingBadge"
            ></v-badge>
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ groupChattingTT }}</span>
    </v-tooltip>
  </v-list>
</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import {userInCall} from "../../utils/calls"
import CustomTT from "../content/customTT.vue";
import GroupList from "./groupList.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { groupChattingInfoStore, getGroupChatByUserUIdEvent} from "../../effector/users/groupChattingInfo";
import { setSendGroupChatMessageModalEvent } from "../../effector/modals";
import DrawerContentGroupSearch from "./drawerContentGroupSearch.vue";
import { isMobile, isIpad } from "../../lib/mobileUtil";

export default {
  components: { CustomTT, GroupList, DrawerContentGroupSearch },
  props: ["setList", "active", "groupChattingFor", "mini" ,"openSidebar"],
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
      groupUsesEffect: groupChattingInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      moment,
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      openedList: false,
      opened: [],
      sortedGroupChatting: [],
      isIpad: isIpad(),
      // Effector
      ...effector,
    };
  },
  mounted() {
  },
  watch: {
  getGroupUsesEffect: {
    immediate: true,
    deep:true,
    handler(newData) {
      if (newData instanceof Promise) {
        newData
          .then(response => {      
            this.sortedGroupChatting = Object.values(response.groups || {});         
          })
          .catch(error => {
            console.error("Error fetching group chatting info:", error);
          });
      } else if (newData && newData.groups) {
        // Handle direct data object (not a promise)
        this.sortedGroupChatting = Object.values(newData.groups || {});
      }
    },
  },
    groupChattingList: {
      immediate: true,
      deep:true,
      handler: function (groupList) {
      },
    },
    mini() {
      if (this._debounceOpener) clearTimeout(this._debounceOpener);
      if (this.mini || this.opened?.length) {
        this.openedList = !this.mini || this.opened?.length;
      } else {
        this._debounceOpener = setTimeout(() => {
          this.openedList = !this.mini || this.opened?.length;
        }, 1200);
      }
    },
    "isActive": {
      handler(value) {
        if(!value){
          this.opened = [];
        }
      },
    },
    "opened": {
      immediate: true,
      handler() {
        if (this._debounceOpener) clearTimeout(this._debounceOpener);
        this.openedList = !this.mini || this.opened?.length;
      },
    },
    'state.searchGroupTerm':{
      handler(value) {
        if(value.length > 2){
          this.opened = [];
        }
       
      },
      immediate: true,
    }
  },
  mounted() {
    EventBus.$on("focusSearchFieldEvent", this.showHideSearch);
    EventBus.$on("groupListChanged", this.updateGroupList);
    this.handlerClickGroupChatting();
  },
  unmounted(){
    EventBus.$off("focusSearchFieldEvent", this.showHideSearch);
    EventBus.$off("groupListChanged", this.updateGroupList);
  },
  methods: {
    updateGroupList() {
      getGroupChatByUserUIdEvent();
      setTimeout(() => {
      this.opened = ['groupchatting'];
    }, 100);
},
    userInCall(uuid){
      return userInCall(uuid);
    },
    handlerClickGroupChatting(event) {
      getGroupChatByUserUIdEvent();
      this.setList("groupchatting");
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    openGroupChattingDialog(){
      if (!this.mini) {
        setSendGroupChatMessageModalEvent({
          show: true,
          groupData: null, 
          messageUIDs:[]
        });
      } else {
        this.openSidebar();
      }
    },
    handleGroupChatting() {
      getGroupChatByUserUIdEvent();
    },
    showHideSearch() {
      if (!this.state.showGroupSearch) {
        this.state.showGroupSearch = !this.state.showGroupSearch;
      }

      if (this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
        }
  }
},
  computed: {
    // isOwnerAdmin(){
    //   return store.getUserIsAdmin(this.ownUUID);
    // },
    getGroupUsesEffect() {
      return this.groupUsesEffect;
    },
    groupChattingAlias() {
      return store.state.namespaceSettings.groupChattingAlias;
    },
    isActive() {
      return this.active == "groupchatting";
    },
    groupChattingTT() {
      return this.groupChattingAlias
        ? this.$t("components.sidebar.groupChattingTTAlias", [
            this.groupChattingAlias,
          ])
        : this.$t("components.sidebar.groupChattingMiniTT");
    },
    getTitleGroupChatting() {
      const groupChattingName = this.$t(
        "components.drawerContentExpansion.groupchat"
      );
      if (this.groupChattingFor === this.state.ownUUID) {
        return groupChattingName
      } else {
        return (
          groupChattingName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.groupChattingFor) +
          " " +
          this.getNameForUuid(this.groupChattingFor)
        );
      }
    },
    groupList() {
      return store.getGroupList(this.ownUUID);
    },
    getconnectedGroupChattings() {
      let connectedGroupChattings = [];
      return connectedGroupChattings;
    },
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },

  },
};
</script>
<style scoped lang="scss">
.ctrr {
  top: 58px;
  left: 15px;
}
.groupChattingBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
.btnAddGroup {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-list-group__header__append-icon .v-icon {
  margin-left: 36px !important;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
.hrGroups{
  width: 87%;
}
</style>
