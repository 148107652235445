import { combine } from 'effector';
import { messageDomain } from './domain';

import {
  setMessageUUIDsEvent,
  setMessagesInfoEvent,
  setMessagesInfoBatchedEvent,
  deleteMessageEntryAndUUIDEvent,
  showUserIsTypingEvent,
  // resetUnseenMessagesToMeCounterEvent,
  // dispatchGetMessageHistoryEvent
} from './events';

import { ownUserUUIDState } from '../users';

function orderDesc(a, b) {
    const aDate = a.creationServerTs || a.date;
    const bDate = b.creationServerTs || b.date;
    return aDate > bDate ? -1 : (aDate < bDate ? 1 : (a.uuid || '').localeCompare(b.uuid || ''));
}

export const myMessagesStore = messageDomain.createStore([], { name: 'myMessagesStore' }); // List of event UUIDs that I care about (owner of, invited by, etc)
export const typingInfoStore = messageDomain.createStore(false, { name: 'typingInfoStore' });

// myMessagesStore.on(setMessageUUIDsEvent, (state, payload) => { return payload; });
typingInfoStore.on(showUserIsTypingEvent, (state, payload) => {
  //console.log('SOMEHOW HEREEEEE ', payload);
  return payload;
});

myMessagesStore.on(setMessageUUIDsEvent, (state, payload) => {
  const newArray = [...state];
  let changed = false;
  payload.forEach((i) => {
    const extant = newArray.find(e => e.uuid === i.uuid);
    if (!extant) {
      newArray.push(i);
      changed = true;
    } else if (extant.updatedServerTs < i.updatedServerTs) {
      extant.updatedServerTs = i.updatedServerTs;
      changed = true;
    }
  });
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

// TODO: rpc call to remove from this array (deletions)
// TODO: clean cache of stuff we no longer care about

export const cachedMessages = messageDomain.createStore({}, { name: 'cachedMessages' });
cachedMessages.on(setMessagesInfoEvent, (state, payload) => {
  // console.log('cachedTimelineEvents setTimelineEventInfoEvent', state, payload);
  return { ...state, [payload[0]]: payload[1] };
});

cachedMessages.on(setMessagesInfoBatchedEvent, (state, payload) => {
  // console.log('cachedTimelineEvents setTimelineEventInfoBatchedEvent', state, payload);
  return { ...state, ...payload };
});

myMessagesStore.on(deleteMessageEntryAndUUIDEvent, (state, payload) => {
  return state.filter(m => m.uuid !== payload);
});

cachedMessages.on(deleteMessageEntryAndUUIDEvent, (state, payload) => {
  const newState = { ...state };
  // delete newState[payload];
  // console.log(newState, "newState")
  return newState;
});

// export const messageHistory = cachedMessages.on(dispatchGetMessageHistoryEvent, (state, payload) => {
//   const filteredMessages = {};
//   for (const messageUUID in state) {
//     if (state.hasOwnProperty(messageUUID)) {
//       const message = state[messageUUID];
//       if (message.masterMessageUUID === payload) {
//         filteredMessages[messageUUID] = message;
//       }
//     }
//   }
//   console.log('AAAAAAAAAAAAAAAA', filteredMessages, payload);
//   return filteredMessages;
// });

const myMessages = combine(myMessagesStore, cachedMessages, (uuids, cachedMessages) => {
  const messages = [];
  for (const uuidObj of uuids) {
    const uuid = uuidObj.uuid;
    messages.push(cachedMessages[uuid] ? cachedMessages[uuid] : uuid);
  }
  return messages;
});

export const unknownMessages = myMessages.map(s => s.filter(e => typeof e === 'string'));
export const messages = myMessages.map(s => s.filter(e => {
  return typeof e === 'object' && e.header !== 'group-message';
}));


export const groupMessagesToMe = myMessages.map(s => s.filter(e => {
  return typeof e === 'object' && e.header == 'group-message';
}));

export const messagesToMe = messages.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  const temporal = s.filter(e => {
    return e && e.users && e.users[ownUUID] && (e.type === 'message' || e.type === 'plannerEvent') && !e.users[ownUUID].deleted;
  });
  return temporal.sort(orderDesc);
});

export const messagesSent = messages.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  const temporal = s.filter(e => {
    return e && (e.creatorUUID === ownUUID)  && (e.type === 'message' || e.type === 'plannerEvent');
  });
  return temporal.sort(orderDesc);
});

export const messagesReceivedAndSent = myMessages.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  const temporal = s.filter(e => {
    return typeof e === 'object' && e && (e.type === 'message' || e.type === 'plannerEvent') && ((e.creatorUUID === ownUUID && !e.removedByCreator) || (e.users && e.users[ownUUID] && !e.users[ownUUID].deleted))
  });
  return temporal.sort(orderDesc);
});

export const systemMessagesReceived = messages.map((s) => {
  const now = Date.now();
  const ownUUID = ownUserUUIDState.getState();
  const temporal = s.filter(e => {
    return e && e.type === 'systemMessage' && (!e.expiryTs || (e.expiryTs && e.expiryTs >= now)) && ((e.creatorUUID === ownUUID && !e.removedByCreator) || (e.users && e.users[ownUUID] && (!e.users[ownUUID].deleted || !e.users[ownUUID].read)))
  });
  return temporal.sort(orderDesc);
});

// export const messagesToMeFiltered = combine(messagesToMe, (messages) => {
//   const temporalMessages = [];
//   // console.log('AAAAAAA', messages);
//   for (let i = 0; i < messages.length; i++) {
//     const message = messages[i];
//     console.log('MENSAJE', message);

//   }
//   return messagesToMe.getState();
// });

export const unseenMessagesToMe = messages.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  const unseen = s.filter(e => {
    return e && e.users && e.users[ownUUID] && (e.type === 'message' || e.type === 'plannerEvent') && !e.users[ownUUID].viewed && !e.users[ownUUID].deleted;
  });
  return unseen.length
});

export const unreadMessagesToMe = myMessages.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  const unread = s.filter(e => {
    return typeof e === 'object' && e && e.users && e.users[ownUUID]
        && (e.type === 'message' || e.type === 'plannerEvent') && !e.users[ownUUID].read && !e.users[ownUUID].deleted;
  });  
  return unread.length;
});

export const outdatedMessages = combine(myMessagesStore, cachedMessages, (uuidAndTimings, cachedEvents) => {
  const outdatesUUIDs = [];
  for (const uuidObj of uuidAndTimings) {
    const entry = cachedEvents[uuidObj.messageUUID];
    if (entry && uuidObj.updatedServerTs > entry.updatedServerTs) {
      // console.log('outdatedTimelineEvents', entry, uuidObj);
      outdatesUUIDs.push(uuidObj.messageUUID);
    }
  }
  return outdatesUUIDs;
});

// myMessagesStore.on(resetUnseenMessagesToMeCounterEvent, (state, payload) => {
//   return state.filter(m => m.uuid !== payload);
// });

// MESSAGE USER STATS STORE

// messageUUID: {
//   messageUUID: number
//   body: ''
//   header: ''
//   date: timestamp
//   users: object Array {
//    userUUID:{
    //   userUUID: userUUID,
    //   read: false,
    //   deleted: false
    // viewed:false
    // }
//   }
//   reply: false
//   creatorUUID: number
//   masterMessageUUID: Number
//   type: string
// }
