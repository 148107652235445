<template>
  <div class="w-100">
    <v-row
      class="w-100 mx-0 my-0 justify-space-between d-flex"
      v-if="!actualDate"
    >
      <v-col class="px-0 d-flex align-center" align="left">
        <v-card-title class="d-flex align-center w100" :class="isMobile ? 'headlineMobile' : 'headline'">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            height="30"
            width="30"
            contain
          ></v-img>
          <span
            :class="isMobile ? 'pl-2 spanModalCallMobile' : fullwith ? 'pl-4 spanModalCallFull': 'pl-4 spanModalCall'"
            class="font-weight-medium pr-2"
            v-html="titleModal"
          ></span>
          <v-btn
            v-if="actionButton"
            :small="isMobile"
            density="comfortable"
            variant="text"
            class="ml-auto"
            icon
              v-tooltip.top="$t('components.drawerContentCard.groupmessages')"
            :color="'white'"
            v-on="props"
            @click="applyAction"
            id="v-tour-messages"
          >
            <font-awesome-icon
              v-bind="props"
              color="white"
              :icon="['fal', actionButton]"
              :style="{
                fontSize: '20px',
                position: 'relative',
              }"
            />
          </v-btn>
          <v-tooltip location="top" v-if="showInfoButton" content-class="videoButtonTT">
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                color="#2a3133"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  position: 'relative',
                }"
              />
            </template>
            <span>
              {{
                showInfoButton == "Holidays"
                  ? $t("components.holidays.infoTT")
                  : showInfoButton == "Ill"
                    ? $t("components.holidays.infoIllTT")
                    : showInfoButton == "No Calls"
                      ? $t("status-tooltips.No Calls")
                      : ""
              }}
            </span>
          </v-tooltip>
          <slot></slot>
        </v-card-title>
      </v-col>

      <div :class="isMobile ? 'mr-4' : 'pa-4'" class="my-auto" v-if="closeModalFunction">
        <div>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                icon
                :size="isMobile ? 'x-small' : ''"
                color="black"
                ref="closeButton"
                @click="closeModalFunction"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-row>

    <v-card-title :class="`${isMobile ? 'headlineMobile' : 'headline styleHeader'}`" primary-title v-else>
      <!-- <v-row class="mx-0"> -->
      <v-row class="w-100 mx-0 my-0 justify-space-between d-flex align-center">
        <v-col cols="8" class="d-flex px-1 py-1">
          <v-img
            :max-height="30"
            :max-width="30"
            :src="'assets/icon.png'"
            class="imageLogo"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall"
            >{{ titleModal }}
          </span>
        </v-col>
        <v-col cols="4" class="px-1 py-1 text-right">
          <span class="font15 pr-2">{{ actualDate }}</span>
          <v-tooltip location="top" v-if="closeModalFunction">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                icon
                variant="text"
                color="black"
                ref="closeButton"
                @click="closeModalFunction($event)"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
  </div>
</template>


<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
export default {
  components: {},
  props: [
    "titleModal",
    "closeModalFunction",
    "actualDate",
    "actionButton",
    "handleAction",
    "showInfoButton",
    "settingButton",
  ],
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
  }),
  mounted() {},
  methods: {
    applyAction() {
      if(this.handleAction){
        this.handleAction();
      }
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.headlineMobile {
  flex-wrap: unset !important;
  max-width: 100%;
}
.videoButtonTT {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.spanModalCall {
  font-size: 18px;
  word-break: break-word;
  max-width: 470px;
  line-height: 2;
  text-wrap: wrap;
}
.spanModalCallFull{
  font-size: 18px;
  word-break: break-word;
  width: 100%;
  line-height: 2;
  text-wrap: wrap;
}
.spanModalCallMobile{
  font-size: 18px;
  word-break: break-word;
  max-width: 290px;
  line-height: 2;
  text-wrap: wrap;
}
.w-100 {
  width: 100%;
}
.font15 {
  font-size: 15px;
}
/*.styleHeader{
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}*/
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
</style>