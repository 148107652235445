import { groupDomain } from "./domain";

import { deleteGroup } from '../../lib/wsMsg';
import {leaveGroup} from '../../lib/wsMsg';

export const deleteGroupChatEffect = groupDomain.createEffect({
    name: 'deleteGroupChatEffect',
    handler: deleteGroup
});

export const leaveGroupEffect = groupDomain.createEffect({
    name: 'leaveGroupEffect',
    handler: (payload) => {
        return leaveGroup(payload);}
})
