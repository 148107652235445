/* globals Audio */
import store, { mergeState, syncedUserState, EventBus } from '../store';
import vueInst from '../vue';
import moment from 'moment';
import telemetryInit from '../lib/telemetry';
import { rtcMessageFrom, getWebrtcConnections } from './rtcConn';
import { wsCall, wsSend } from './wsConnect';
import { v4 as uuidv4 } from 'uuid';
import Vue from '@vue/compat';
import { receivedAllUserStateEvent, receivedSpecificUserStateEvent, getGroupChatByUserUIdEvent } from '../effector/users';
import { fillPresenceWatchingEvent } from '../effector/presence';
import { fillAllConferencesEvent, fillMyConferencesEvent } from '../effector/conference';
import { setCompanyDocumentUUIDsEvent, setCompanyDocumentInfoEvent, deleteCompanyDocumentEntryAndUUIDEvent, myCompanyDocumentsDetails,updateCompanyDocumentEvent, } from '../effector/companyDocuments'
import { setTimelineEventInfoEvent, setTimelineEventUUIDsEvent, deleteTimelineEntryAndUUIDEvent } from '../effector/timeline';
import { setMessageUUIDsEvent, setMessagesInfoEvent, prepadeDataToSendMessageEffector, newMessageEvent } from '../effector/message';
import { setNoteUUIDsEvent, setNotesInfoEvent, deleteNoteEntryAndUUIDEvent } from '../effector/notes';
import { setGroupConferenceUUIDsEvent, setGroupConferenceInfoEvent, deleteGroupConferenceEntryAndUUIDEvent } from '../effector/groupConferences'
import { setConferenceHallUUIDsEvent, setConferenceHallInfoEvent, deleteConferenceHallEntryAndUUIDEvent, myConferenceHallDetails } from '../effector/conferenceHalls'
import { dispatchSuccessAlert, dispatchErrorAlert } from '../effector/alerts';
import { gotVersionFromSigEvent } from '../effector/version';
import { appendCallChatEvent } from "../effector/callChat"
import {appendListenerRaisedHandEvent} from "../effector/listenerRaisedHand"
import { parallel } from "../lib/asyncUtil";
import {
  historyMessagesModalStore,
  joinBridgeCallStore,
  resetJoinBridgeCallModalEvent,
  setNewMessageFromCallEvent,
  setJoinConferenceEvent
} from "../effector/modals";
import { gotNamespaceSettingsFromSigEvent } from '../effector/namespaceSettings';
import { getUsersInCall } from '../utils/basicFunctions';

import RpcManager from './rpcManager';
const rpc = new RpcManager();
rpc.on('request', (rpc) => { wsSend({ type: 'rpc', rpc }); });
rpc.on('answer', (rpc) => { wsSend({ type: 'rpc_ans', rpc }); });

const hasOwn = Object.prototype.hasOwnProperty;
export const callTable = {};

export function onMessage(event) {
  // console.log('WS Message', event);
  const msg = JSON.parse(event.data);
  if (msg.type === 'call') handleCall(msg.func, msg.args);
  else if (msg.type === 'rpc') rpc.handleRequest(msg.rpc);
  else if (msg.type === 'rpc_ans') rpc.handleAnswer(msg.rpc);
}

function handleCall(func, args) {
  if (!func || typeof func !== 'string' || !Array.isArray(args)) return console.warn('Invalid WebSocket Call'); // Sanity
  if (hasOwn.call(callTable, func) && typeof callTable[func] === 'function') callTable[func](...args);
  else console.warn('Unknown Call:', func);
}

// export let lastUserStateSent;
let initedVue = false;

callTable.hello = (userState, namespaceSettings) => {
  // console.log('Hello Call, sending userState');
  store.addRemoteFirstStreamlastUserStateSent = undefined;
  store.mergeUserStateFromSignalling(userState, false);
  gotNamespaceSettingsFromSigEvent(namespaceSettings);
  store.setNamespaceSettingsFromSig(namespaceSettings);
  // saveUserState();
  // sendUserState();

  document.title = 'vOffice ' + ((store.state.namespaceSettings.companyInfo || {}).virtualOfficePrefix || store.getVirtualOfficePrefix());

  if (!initedVue) {
    initedVue = true;
    telemetryInit();
    // initVue();
    vueInst.init();
  }
};

callTable.allUserState = (group) => {
  // console.log('allUserState Call', group);
  group = store.setGroup(group);
  if (group[store.state.ownUUID] && group[store.state.ownUUID].user) {
    const j = JSON.stringify(group[store.state.ownUUID].user);
    if (store.state.lastUserStateSent !== j) {
      store.mergeUserStateFromSignalling(group[store.state.ownUUID].user, false);
    }
  }
  receivedAllUserStateEvent(group);
};

callTable.setUserState = (uuid, state) => {
  // console.log('setUserState Call', uuid, JSON.stringify(state));
  state = store.setInGroup(uuid, state);
  if (uuid === store.state.ownUUID) {
    if (state.user) {
      const j = JSON.stringify(state.user);
      if (store.state.lastUserStateSent !== j) {
        store.mergeUserStateFromSignalling(state.user, true);
      }
    }
  }
  const group = store.state.group;
  receivedSpecificUserStateEvent([uuid, state, group]);
};

callTable.deleteUserState = (uuid) => {
  store.setInGroup(uuid, undefined);
  const group = store.state.group;
  receivedSpecificUserStateEvent([uuid, undefined, group]);
};

callTable.userPersist = (persistedState) => {
  const newState = mergeState(store.state.persisted, persistedState, ['mediaDeviceSetup']);
  // console.log('signalling userPersist', state, newState);
  store.state['persisted'] = newState;
};

callTable.relayFrom = (uuid, msg) => {
  try {
    const thatGuy = store.state.group[uuid];
    if (!thatGuy && msg.type !== 'bridge-chat') return console.log('Rogue relay:', uuid, msg);
    if (thatGuy) store.setLastInteractionWithUuid(uuid);
    if (store.state.nerd) console.debug('RelayFrom', uuid, (thatGuy && thatGuy.user && thatGuy.user.name), msg);
    switch (msg.type) {
      case 'bridge-signal':
        if (store.state.nerd) console.debug('got bridge-signal', uuid, msg);
        if (msg.action) {
          switch (msg.action) {
            case 'start_bridge_call':
              // console.log('start_bridge_call', msg.sender, msg.info)
              if (
                // Confirm call is authentic
                msg.info && msg.info.callUUID &&
                store.state.user && msg.info.callUUID === store.state.user.inBridgeCall &&
                store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[msg.info.callUUID] &&
                store.state.group && store.state.group[msg.sender] && store.state.group[msg.sender].user &&
                (
                  // Confirm vPay call back information
                  (store.state.group[msg.sender].user.requestedPayment && msg.info.callUUID === store.state.group[msg.sender].user.requestedPayment.callUUID) ||
                  // Confirm guest direct call information
                  store.state.group[msg.sender].directCallUUID === msg.info.callUUID ||
                  // Confirm user direct call information
                  (store.state.group[msg.sender].permissions !== 10 && msg.info.directCallInvite)
                )
              ) {
                wsCall("sendToUUID", msg.sender, {
                  type: "bridge-signal",
                  action: "acept_bridge_call",
                  sender: store.state.ownUUID,
                  info: msg.info,
                });
                if (msg.info.conferenceHall) {
                  // update conferenceAttendees
                  const j = JSON.stringify(msg.info.conferenceAttendees);
                  for (let i = 0; i < msg.info.conferenceAttendees.length; i++) {
                    const member = msg.info.conferenceAttendees[i];
                    const isOnline = store.state.group[member.uuid]?.user?.bridgeCallInfo?.callUUID === msg.info.callUUID;
                    const curr = JSON.stringify(store.state.group[member.uuid]?.user?.bridgeCallInfo?.conferenceAttendees);
                    if (isOnline /* && member.uuid !== store.state.ownUUID */ && j !== curr) {
                      wsCall("sendToUUID", member.uuid, {
                        type: "bridge-signal",
                        action: "updateConferenceAttendees",
                        sender: store.state.ownUUID,
                        info: {
                          callUUID: msg.info.callUUID,
                          confAttendees: msg.info.conferenceAttendees,
                        },
                      });
                    }
                  }
                  // update document effector
                  doUpdateDocumentStore(msg.info.confId, uuid)
                }
                store.removeAcceptedCallNotification(msg.sender);
                return;
              }
              store.setIncomingBridgeCallFor(msg.sender, msg.info)
              break;
            case 'cancel_bridge_call':
              store.setIncomingBridgeCallFor(msg.sender, undefined);
              break;
            case 'reject_bridge_call':
              if (store.state.nerd) console.debug('reject bridge call')
              store.removeCallingUser(msg.sender, msg.info)
              if (joinBridgeCallStore.getState().show) {
                resetJoinBridgeCallModalEvent()
                store.setmodalPopUp({ uuid: msg.sender, type: 'rejectCall', msg: 'reject call' });
              }
              EventBus.$emit("reject_bridge_call", msg.info);
              break;
            case 'acept_bridge_call': {
              const myCallUUID = store.state.user.inBridgeCall || store.state.user.inBridgeCallListener || "";
              console.log('bridge signal relay from', uuid, (thatGuy && thatGuy.user && thatGuy.user.name), 'accept bridge call', { callUUID: msg.info?.callUUID, myCallUUID });
              if (msg.info?.callUUID && msg.info.callUUID !== myCallUUID) {
                if (myCallUUID && !store.state.user.aiUser) {
                  const users = getUsersInCall(myCallUUID);
                  if (users.length) {
                    users.forEach(userUUID => {
                      wsCall("sendToUUID", userUUID, {
                        type: "bridge-signal",
                        action: "acept_mix_call",
                        sender: store.state.ownUUID,
                        info: msg.info,
                      });
                    });
                  }
                  store.removeRemoteBridgeStreams(myCallUUID);
                  setTimeout(() => {
                    store.addRemoteBridgeStream(msg.info.callUUID, msg.info);
                  }, 2000);
                } else if (!myCallUUID) {
                  store.addRemoteBridgeStream(msg.info.callUUID, msg.info);
                  store.setIncomingBridgeCallFor(msg.sender, undefined);
                  store.removeAcceptedCallNotification(msg.sender);
                }
                store.removeCallingUser(msg.sender, msg.info);
                if (joinBridgeCallStore.getState().show) {
                  resetJoinBridgeCallModalEvent();
                }
              }
            } break;
            case 'acept_mix_call': {
              const myCallUUID = store.state.user.inBridgeCall || store.state.user.inBridgeCallListener || "";
              console.log('bridge signal relay from', uuid, (thatGuy && thatGuy.user && thatGuy.user.name), 'accept mix call', { callUUID: msg.info?.callUUID, myCallUUID });
              if (msg.info?.callUUID && msg.info.callUUID !== myCallUUID) {
                if (myCallUUID) {
                  store.removeRemoteBridgeStreams(myCallUUID);
                }
                store.removeCallingUser(msg.sender, msg.info);
                if (joinBridgeCallStore.getState().show) {
                  resetJoinBridgeCallModalEvent();
                }
                setTimeout(() => {
                  store.addRemoteBridgeStream(msg.info.callUUID, msg.info);
                }, 2000);
              }
            } break;
            case 'updateConferenceAttendees':
              store.updateConferenceAttendees(msg.info.callUUID, msg.info.confAttendees);
              break;
            case 'change_normal_mode':
              store.changeIsNormalModeBridgeStream(msg.info.callUUID, msg.info.isNormalMode);
              break;
            case 'podium_listener': {
              const callUUID = store.state.user.inBridgeCall || store.state.user.inBridgeCallListener || "";
              if (callUUID === msg.callUUID) {
                const currInfo = (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[msg.callUUID]) || {};
                if (store.state.user.inBridgeCall) {
                    store.setInBridgeCall(false);
                    syncedUserState(() => {
                      setTimeout(() => {
                        if (store.state.user.inBridgeCall === false) {
                          store.setRemoteBridgeStream(msg.callUUID, { ...currInfo, ...msg.infoCall, calling: [] });
                          store.setInBridgeCallListener(msg.callUUID);
                        }
                      }, 300);
                    });
                } else if (store.state.user.inBridgeCallListener) {
                  bridgeCallRaiseHand(false)
                  store.setInBridgeCallListener(false)
                  store.setInBridgeCall(msg.callUUID)
                }
              }
            } break;
            case 'hangup_listener':
              store.removeRemoteBridgeStreams(msg.callUUID)
              break;
            default:
              console.warn('Unknown bridge-signal message:', uuid, msg);
              break;
          }
        }
        break;
      case 'bridge-chat':
        if (msg.action) {
          switch (msg.action) {
            case 'chat-message':
              appendCallChatEvent(msg.message)
              break;
            case 'raise-hand':
              appendListenerRaisedHandEvent(msg.result);
              break;
            default:
              console.warn('Unknown bridge-chat message:', uuid, msg);
              break;
          }
        }
        break;
      case 'positionInWaitingRoom':
        store.setPositionInWaitingRoom(msg.position)
        break;
      case 'ping':
        // alert(thatGuy.user.name + ' has pinged you! Dismiss to acknowledge.');
        store.setPingPongs(uuid, { status: 'ping' });
        break;
      case 'pong':
        // alert(thatGuy.user.name + ' has acknowledged your ping!');
        store.setPingPongs(uuid, { status: 'pong', result: msg.result });
        break;
      case 'rtc':
        rtcMessageFrom(uuid, msg.payload);
        break;
      case 'message': {
        const firstLine = `${msg.result}`.split(/[\r\n]/)[0];
        store.setMessageFor(uuid, { info: msg.result, notificationType: 'message', subject: firstLine, playSound: true, header: msg.header });
      } break;
      case 'notification':
        store.setMessageFor(uuid, { ...msg, info: msg.result, notificationType: msg.notificationType, subject: msg.result, playSound: msg.playSound });
        break;
      case 'rejectCall':
        // alert(thatGuy.user.name + ' has pinged you! Dismiss to acknowledge.');
        store.setmodalPopUp({ uuid: msg.sender, type: msg.type, msg: msg.message });
        break;
      case 'popUp':
        // alert(thatGuy.user.name + ' has pinged you! Dismiss to acknowledge.');
        store.setmodalPopUp({ uuid: msg.sender, type: msg.type, msg: msg.message, paramsMsg: msg.paramsMsg, title: msg.title, params: msg.params });
        break;
      case 'joinConference':
        setJoinConferenceEvent(msg.sender)
        break;
      case 'answerJoinConference':
        store.setmodalPopUp({ uuid: msg.sender, type: msg.type, msg: msg.message });
        break;
      case 'rtcSendMessageToAll':
        setNewMessageFromCallEvent(msg.message)
        break;
      case 'paypalPayment': {
        const { showSuccessPayment, showErrorPayment, showCancelPayment, attachTo, total } = msg;
        const tmpObj = {
          showSuccessPayment: showSuccessPayment || false,
          showErrorPayment: showErrorPayment || false,
          showCancelPayment: showCancelPayment || false,
          attachTo: attachTo || false,
          total: total || false
        }
        store.setPaymentState(tmpObj)
      } break;
      default:
        console.warn('Unknown relay-from message:', uuid, msg);
        break;
    }
  } catch (err) {
    console.warn('Failed to sendToUUID:', uuid, msg, err.message);
  }
};
export function doUpdateDocumentStore(confId, userUUID) {
  const foundStore = myCompanyDocumentsDetails.getState().find((e) => e.confId === confId);
   const extant = myConferenceHallDetails.getState().find(
    (e) => e.confId === confId
  );
  if (foundStore && foundStore.confId && extant) {
    let usersConference = extant.confUUIDS
    usersConference.push(userUUID)
    foundStore.docMembersUUIDS = usersConference;
    foundStore.moderators = extant.confModerators;
    updateCompanyDocumentEvent(foundStore);
  }
}

export function sendToUUID(uuid, msg) {
  wsCall('sendToUUID', uuid, msg);
  store.setLastInteractionWithUuid(uuid);
}

export function rtcMsg(uuid, payload, notRogue = false) {
  const webrtcConnections = getWebrtcConnections();
  if (!webrtcConnections[uuid] && !notRogue) {
    console.warn('rtcMsg Rogue Msg:', uuid, payload);
    return;
  }
  // store.setLastInteractionWithUuid(uuid);
  // console.log('rtc Send', store.getNameForUuid(uuid), payload.channel);
  // wsCall('sendToUUID', uuid, { type: 'rtc', payload });
  sendToUUID(uuid, { type: 'rtc', payload });
}

export function pongMsg(uuid, result) {
  // console.log('PONG', store.getNameForUuid(uuid), result);
  // wsCall('sendToUUID', uuid, { type: 'pong', result });
  sendToUUID(uuid, { type: 'pong', result });
}

export function sendOfflineMsg(uuid, result, header) {
  rpc.rpc('sendOfflineMessage', uuid, { type: 'message', result, header });
  sendNotificationToUUID(uuid, { type: 'message' });
  store.setLastInteractionWithUuid(uuid);
}

export function getSectionCalendar(section) {
  return rpc.rpc('getSectionCalendar', section);
}

export function deleteSectionCalendarEvent(section, uuid) {
  return rpc.rpc('deleteSectionCalendarEvent', section, uuid);
}

export function setSectionCalendar(section, events) {
  return rpc.rpc('setSectionCalendar', section, events);
}

export function sendMsg(uuid, result, header) {
  sendToUUID(uuid, { type: 'message', result, header });
  sendNotificationToUUID(uuid, { type: 'message' });
}

callTable.gitCommit = (gitCommit) => {
  gotVersionFromSigEvent(gitCommit);
  store.setGitCommit(gitCommit);
};

export function setNotificationToken(token, voipToken) {
  return syncedUserState(() => wsCall('setNotificationToken', token, voipToken), true);
}

export function closeConnection(code) {
  console.log('closeConnection wsMsg', code);
  return rpc.rpc('closeConnection', code);
}

export function sendNotificationToUUID(uuid, options) {
  wsCall('sendNotificationToPerson', uuid, options);
}

export async function downloadExcel() {
  return rpc.rpc('downloadExcel');
}

export function importUsers(rawExcel) {
  wsCall('importUsers', rawExcel);
}

export async function importUsersParsed(rawExcel, options = {}) {
  return rpc.rpc('importUsersParsed', rawExcel, options);
}

export async function getPayPalRegistrationSubscriptionEnv() {
  return rpc.rpc('getPayPalRegistrationSubscriptionEnv');
}

export async function getPayPalSettings() {
  return rpc.rpc('getPayPalSettings');
}

export async function setPayPalSettings(settings) {
  return rpc.rpc('setPayPalSettings', settings);
}

export async function generatePurchaseToken() {
  return rpc.rpc('generatePurchaseToken');
}

export async function excelToJson(rawExcel) {
  return rpc.rpc('excelToJson', rawExcel);
}
export async function checkRmoStoreByClientId(id, zip) {
  return rpc.rpc('checkRmoStoreByClientId', id, zip);
}
export async function registerRmoSubdomain(id, zip) {
  return rpc.rpc('registerRmoSubdomain', id, zip);
}
export async function registerRmoCustomer(inputData) {
  return rpc.rpc('registerRmoCustomer', inputData);
}
export async function migrateCreator(uuid) {
  return rpc.rpc('migrateCreator', uuid, true /* vue3 */);
}
export async function migrateUser(name, email, title) {
  return rpc.rpc('migrateUser', name, email, title, true /* vue3 */);
}

// == Tickets Start ===
export function assignTicket(uuid, fromUuid, ticketUuid) {
  // console.log('assignTicket:', uuid, ticketUuid);
  wsCall('assignTicket', uuid, ticketUuid);
  wsCall('assignedTicketByMe', fromUuid, ticketUuid);
}

export async function getFilteringData(uuid) {
  const data = {};
  let intickets = [];
  let outTickets = [];
  let record = {};
  const assignees = [];
  const authors = [];

  outTickets = store.state.group[uuid].user.assignedTicketsByMe || [];
  intickets = store.state.group[uuid].user.assignedTickets || [];
  const tickets = outTickets.concat(intickets);

  if (tickets.length) {
    for (let i = 0; i < tickets.length; i++) {
      const ticket = await getTicketInfo(tickets[i]);
      if (ticket.t_assigner === uuid) { // outbox so get assignees
        record.id = ticket.t_assignee;
        record.name = store.state.group[ticket.t_assignee].user.name;
        if (assignees.find(x => x.id === ticket.t_assignee) === undefined) {
          assignees.push(record);
          record = {};
        }
      } else if (ticket.t_assignee === uuid) { // inbox assigned to me
        record.id = ticket.t_assigner;
        record.name = store.state.group[ticket.t_assigner].user.name;
        if (authors.find(x => x.id === ticket.t_assigner) === undefined) {
          authors.push(record);
          record = {};
        }
      }
    }
  }

  record.id = 'all';
  record.name = 'All';
  authors.push(record);
  assignees.push(record);
  record = {};
  data.authors = authors;
  data.assignees = assignees;
  return JSON.stringify(data);
}

export async function calculateTicketCounters(uuid) {
  let intickets = [];
  let outTickets = [];
  let ticketsInCount = 0;
  let ticketsOutCount = 0;

  outTickets = store.state.group[uuid].user.assignedTicketsByMe || [];
  intickets = store.state.group[uuid].user.assignedTickets || [];
  const tickets = outTickets.concat(intickets);

  if (tickets.length) {
    for (let i = 0; i < tickets.length; i++) {
      const ticket = await getTicketInfo(tickets[i]);
      if (ticket !== null) {
        if (ticket.t_viewed === false) {
          if (ticket.t_assignee === uuid) {
            ticketsInCount++;
          } else if (ticket.t_assigner === uuid) {
            if (ticket.t_ready_percent === '100') {
              ticketsOutCount++;
            }
          }
        }
      }
    }
    store.state.ticketsInCount = ticketsInCount;
    store.state.ticketsOutCount = ticketsOutCount;
    store.state.ticketsCount = ticketsInCount + ticketsOutCount;
  }
}

export function unassignTicket(uuid, ticketUuid) {
  // console.log('unassignTicket:', uuid, ticketUuid);
  wsCall('unassignTicket', uuid, ticketUuid);
}

export async function getTicketInfo(ticketUuid) {
  // console.log('getTicketInfo:', ticketUuid);
  // wsCall('getTicketInfo', ticketUuid);
  return rpc.rpc('getTicketInfo', ticketUuid);
}

export function setTicketInfo(ticketUuid, info, isEdit = false) {
  // console.log('setTicketInfo:', ticketUuid, info);
  return rpc.rpc('setTicketInfo', ticketUuid, info, isEdit);
}

export function closeTicket(uuid, ticketUuid) {
  // console.log('setTicketInfo:', ticketUuid, info);
  wsCall('closeTicket', uuid, ticketUuid);
}

export function createTicketNote(ticketUuid, noteText) {
  return rpc.rpc('createTicketNote', ticketUuid, noteText);
}

export function editTicketNote(noteUuid, noteText) {
  return rpc.rpc('editTicketNote', noteUuid, noteText);
}

export function getTicketNotes(ticketUuid) {
  return rpc.rpc('getTicketNotes', ticketUuid);
}

callTable.ticketInfo = (ticketUuid, info, isGetResponse) => {
  if (isGetResponse) {
    for (const idx in store.state.userTicketsArray) {
      if (store.state.userTicketsArray[idx] && store.state.userTicketsArray[idx].t_Uuid === ticketUuid) {
        if (info.t_done) {
          store.state.userTicketsArray.splice(idx, 1);
          break;
        } else {
          store.setUserTicketsArrayIdx(idx, { ticketUuid, ...info });
          // store.setUserActiveTicket({ ticketUuid, ...info });
          return;
        }
      }
    }
    if (info.t_done) {
      if (store.state.activeTicket.t_Uuid === info.t_Uuid) {
        store.state.activeTicket = {};
      }
      store.state.userTicketsArchivedArray.push({ ticketUuid, ...info });
    } else {
      store.state.userTicketsArray.push({ ticketUuid, ...info });
    }
  }
};
// == Tickets End ===

export function setNamespaceAlias(operation, alias) {
  return rpc.rpc('setNamespaceAlias', operation, alias);
}

export function setNamespaceSetting(key, value) {
  // console.log('setNamespaceSetting:', key, value);
  return rpc.rpc('setNamespaceSetting', key, value);
}

export function setOrganisationSetting(unit, name, key, value) {
  return rpc.rpc('setOrganisationSetting', unit, name, key, value);
}

callTable.setNamespaceSettings = (settings) => {
  gotNamespaceSettingsFromSigEvent(settings);
  store.setNamespaceSettingsFromSig(settings);
};

export async function isEmailRegistered(email) {
  return rpc.rpc('isEmailRegistered', email);
}

export async function sendMailConferenceCall(uuids, options) {
  return rpc.rpc('sendMailConferenceCall', uuids, options);
}

export async function sendMailDailyReport(options) {
  return rpc.rpc('sendMailDailyReport', options);
}

export async function sendMailDirectCall(options) {
  return rpc.rpc('sendMailDirectCall', options);
}

export async function sendInviteMailCustom(uuid, invite) {
  const inviteObject = { ...invite, language: store.state.user.language || 'en' };
  const mailResult = rpc.rpc('sendInviteMailCustom', uuid, inviteObject);
  mailResult.catch(async (_err) => {
    dispatchErrorAlert(Vue.prototype.$t('components.inviteVisitor.badEmail'));
    if (((store.state.group[uuid] || {}).user || {}).visitorData) {
      return await setRemoteUser(uuid, { visitorData: { ...store.state.group[uuid].user.visitorData, hasEmailError: true } });
    }
  });
}

export async function sendInviteMail(uuid, email) {
  return rpc.rpc('sendInviteMail', uuid, email);
}

export async function sendInviteWhatsApp(uuid, options = {}) {
  return rpc.rpc('sendInviteWhatsApp', uuid, options);
}

// == Group Chatting Start ==
export async function addGroupChatting(groupInfo) {
  return await rpc.rpc('addGroupChatting', groupInfo);
}

export async function getGroupMessagesByGroupUId(groupUUID) {
  const result = await rpc.rpc('getGroupMessagesByGroupUId', groupUUID);
  return result;
}

export async function getGroupChattingsByUserUId(userUUID) {
  return rpc.rpc('getGroupChattingsByUserUId', userUUID);
}


export async function setRemoteUser(uuid, patch, options) {
  return rpc.rpc('setRemoteUser', uuid, patch, options);
}

export async function addUserInviters(uuid) {
  return rpc.rpc('addUserInviters', uuid);
}

export async function updateDirectJoinConferenceUUID(uuid, conferenceId, action) {
  const data = await rpc.rpc('updateDirectJoinConferenceUUID', uuid, conferenceId, action);
  if (((store.state.group[uuid] || {}).user || {}).visitorData) {
    return await setRemoteUser(uuid, { visitorData: { ...store.state.group[uuid].user.visitorData, directJoinConferenceUUID: data } });
  }
}
export async function removeUserInviters(uuid) {
  return rpc.rpc('removeUserInviters', uuid);
}

export async function createNewUser(userState, newUUID, options) {
  newUUID = newUUID || uuidv4();
  await setRemoteUser(newUUID, userState, options);
  return newUUID;
}

export async function purgeMyVisitors() {
  let isfirstRun = false;
  const one_day = 1000 * 60 * 60 * 24;

  const myVisitors = ((store.state.persisted || {}).userVisitors || {});
  if (Object.keys(myVisitors).length === 0) {
    return;
  }
  if (store.state.persisted.lastPurgeDate === null) {
    isfirstRun = true;
  }

  if (!isfirstRun) {
    const lastPurged = new Date(store.state.persisted.lastPurgeDate);
    const today = new Date();
    const elapsed = today.getTime() - lastPurged.getTime();
    const daysPassed = elapsed / one_day;
    if (daysPassed < 1) {
      return; // too soon
    }
  }
  isfirstRun = false;
  const newDate = new Date();
  setTimeout(() => {
    store.setlastPurgeDate(newDate);
  }, 1000);

  const deleted = await rpc.rpc('purgeMyVisitors', myVisitors);

  for (let index = 0; index < deleted.length; index++) {
    const uuid = deleted[index];
    store.setUserVisitor(uuid, undefined);
  }
  return deleted.length;
}

export async function deleteUserByUUID(uuid) {
  return rpc.rpc('deleteUserByUUID', uuid);
}

export async function status2FA(detailed = false) {
  return rpc.rpc('status2FA', detailed);
}

export async function setup2FA(method) {
  return rpc.rpc('setup2FA', method);
}

export async function test2FA(code) {
  return rpc.rpc('test2FA', code);
}

export async function setup2FAlifetimeHours(lifetimeHours) {
  return rpc.rpc('setup2FAlifetimeHours', lifetimeHours);
}

export async function enable2FA(code) {
  return rpc.rpc('enable2FA', code);
}

export async function disable2FA(code) {
  return rpc.rpc('disable2FA', code);
}

export async function getDirectCallInviteLink(disableShorten = false) {
  return rpc.rpc('getDirectCallInviteLink', disableShorten);
}

export async function getUserInviteLink(uuid) {
  return rpc.rpc('getUserInviteLink', uuid);
}

// === TIMELINE RPC START ===
export async function getTimelineForDate(date) {
  return rpc.rpc('getTimelineForDate', date);
}

export async function getTimelineEntryByUUID(entryUUID) {
  return rpc.rpc('getTimelineEntryByUUID', entryUUID);
}

export async function createTimelineEntry(entryInfo) {
  const result = rpc.rpc('createTimelineEntry', entryInfo);
  return result;
}

export async function updateTimelineEntry(entryUUID, entryInfo, msgTemplate) {
  const result = await rpc.rpc('updateTimelineEntry', entryUUID, entryInfo);
  if (result) {
    // console.log('Results  before', result, msgTemplate)
    if (msgTemplate && result.wasMeaningfulChange) {
      let usersToSend = entryInfo.users.filter(e => ((store.state.group[e] || {}).user)); //&& !store.state.group[e].user.visitorData);
      if (usersToSend && usersToSend.length) {
        usersToSend = usersToSend.filter(uuid => uuid !== store.state.ownUUID)
        await parallel(1, [...usersToSend], async (user) => {
          const dataMsg = prepadeDataToSendMessageEffector(user, msgTemplate.body, msgTemplate.header, 'plannerEvent');
          newMessageEvent(dataMsg);
        })
      }
      // send notification for waitingRoomAssistants
      if(entryInfo.waitingRoomAssistants && entryInfo.waitingRoomAssistants.length){
        const bodyMsg = `${Vue.prototype.$t('components.conferenceForm.conferenceOf',[store.state.user.name])}: ${msgTemplate.body}`
        const header = msgTemplate.header;
        header.translateMessage.translateKey = `${Vue.prototype.$t('components.conferenceForm.conferenceOf',[store.state.user.name])}: ${header.translateMessage.translateKey}`
        await parallel(1, [entryInfo.waitingRoomAssistants], async (user) => {
          const dataMsg = prepadeDataToSendMessageEffector(user, bodyMsg, header, 'plannerEvent');
          newMessageEvent(dataMsg);
        })
      }
    }
    if (result.removedUsers && result.removedUsers.length) {
      // remove conf info from Guest
      let guestsRemoved = result.removedUsers.filter(e => ((store.state.group[e] || {}).user) && store.state.group[e].user.visitorData);
      if (guestsRemoved && guestsRemoved.length) {
        // remove conferenceUUID from guest
        for (let index = 0; index < guestsRemoved.length; index++) {
          const extant = store.state.group[guestsRemoved[index]].user;
          if (extant) {
            setRemoteUser(guestsRemoved[index], {
              visitorData: {
                ...store.state.group[guestsRemoved[index]].user.visitorData,
                conferenceUUID: null,
                // directJoinConferenceUUID: null,
                eventUUID: null,
                linkDateFrom: null,
                linkDateTo: null,
                linkTimeFrom: null,
                linkTimeTo: null
              }
            });
          }
        }
      }

      // messages
      let usersRemoved = result.removedUsers.filter(e => ((store.state.group[e] || {}).user) && !store.state.group[e].user.visitorData);
      if (usersRemoved && usersRemoved.length) {
        usersRemoved = usersRemoved.filter(uuid => uuid !== store.state.ownUUID)
        await parallel(1, [...usersRemoved], async (user) => {
          msgTemplate = (Vue.prototype.$t('components.conferenceForm.conferenceHasBeenCanceled'));
          const header = {
            translateMessage: {
              translateKey: 'components.conferenceForm.conferenceHasBeenCanceled',
            }
          }
          const dataMsg = prepadeDataToSendMessageEffector(user, msgTemplate, header, 'plannerEvent');
          newMessageEvent(dataMsg);
        })
      }
    }
    if (result.newUsers && result.newUsers.length) {
      // messages
      // console.log(entryUUID, entryInfo, msgTemplate, "entryUUID, entryInfo, msgTemplate")
      let realUsers = result.newUsers.filter(e => ((store.state.group[e] || {}).user) && !store.state.group[e].user.visitorData);
      if (realUsers && realUsers.length) {
        realUsers = realUsers.filter(uuid => uuid !== store.state.ownUUID)
        let notificationMssg = "";
        let header = "";
        const timezone =
        store.state.namespaceSettings.timezone || "Europe/Berlin";
         const confMoment = moment(entryInfo.start).tz(timezone);
        const dateParam =
        `${confMoment.format("DD.MM.YY, HH:mm")}` +
        " " +
        Vue.prototype.$t("components.meetingLineModal.hours") +
        " " +
        `(${timezone})`;
      notificationMssg = Vue.prototype.$t(
        "components.addConferenceModal.headerConferenceInvite",
        [entryInfo.title, store.state.group[store.state.ownUUID].user.name, dateParam]
      );
      header = {
        meetingId: entryInfo.uuid,
        typeMsg: "newConference",
        translateMessage: {
          translateKey:
            "components.addConferenceModal.headerConferenceInvite",
          params: [
            entryInfo.title,
            store.state.group[store.state.ownUUID].user.name,
            dateParam,
          ],
        },
      };
        await parallel(1, [...realUsers], async (user) => {
          // msgTemplate = (Vue.prototype.$t('components.conferenceForm.youHaveBeenInvitedFrom', [store.state.user.name]));
          // const header = {
          //   translateMessage: {
          //     translateKey: 'components.conferenceForm.youHaveBeenInvitedFrom',
          //     params: [store.state.user.name]
          //   }
          // }
          // const dataMsg = prepadeDataToSendMessageEffector(user, msgTemplate, header, 'plannerEvent');
          const dataMsg = prepadeDataToSendMessageEffector(
            user,
            notificationMssg +
              " " +
              Vue.prototype.$t("components.meetingLineModal.hours"),
            header,
            "plannerEvent",
            true
          );
          newMessageEvent(dataMsg);
        })
      }
    }
  }
  return result.newEntry;
}

export async function updateTimelineItemDuration(entryUUID, entryInfo, msgTemplate = null) {
  // console.log('updateTimelineItemDuration')
  const result = await rpc.rpc('updateTimelineItemDuration', entryUUID, entryInfo);
  if (msgTemplate && entryInfo.users && entryInfo.users.length) {
    const usersToSend = entryInfo.users.filter(e => ((store.state.group[e] || {}).user) && !store.state.group[e].user.visitorData && e !== store.state.ownUUID);
    if(result.creatorUUID !== store.state.ownUUID){
      usersToSend.push(result.creatorUUID);
    }
    await parallel(1, [...usersToSend], async (user) => {
      const dataMsg = prepadeDataToSendMessageEffector(user, msgTemplate, 'planner event', 'plannerEvent');
      newMessageEvent(dataMsg);
    })
  }
  return result;
}

export async function deleteTimelineEntry(entryUUID) {
  const result = await rpc.rpc('deleteTimelineEntry', entryUUID);
  if (result && result.users && result.users.length && !result.isTempConf) {
    const usersToSend = result.users.filter(e => ((store.state.group[e] || {}).user) && !store.state.group[e].user.visitorData && e !== store.state.ownUUID);
    if(result.creatorUUID !== store.state.ownUUID){
      usersToSend.push(result.creatorUUID);
    }
    await parallel(1, [...usersToSend], async (user) => {
      const msgTemplate = (Vue.prototype.$t('components.conferenceForm.conferenceEnded'));
      const header = {
        translateMessage: {
          translateKey: 'components.conferenceForm.conferenceEnded'
        }
      }
      const dataMsg = prepadeDataToSendMessageEffector(user, msgTemplate, header, 'plannerEvent');
      newMessageEvent(dataMsg);
    })

  }
  return result;
}

export async function rsvpTimelineEntry(entryUUID, acceptBool, message = null) {
  return rpc.rpc('rsvpTimelineEntry', entryUUID, acceptBool, message);
}

export async function viewedTimelineEntry(entryUUID, acceptBool) {
  // console.log('WSmSG  rsvp ', entryUUID, acceptBool);
  return rpc.rpc('viewedTimelineEntry', entryUUID, acceptBool);
}

export async function updateTimelineEntryColor(entryUUID, color) {
  // console.log('WSmSG  rsvp ', entryUUID, acceptBool);
  return rpc.rpc('updateTimelineEntryColor', entryUUID, color);
}
export async function pdfTimelineEntry(entryUUID, pdfDocuments) {
  // console.log('WSmSG  rsvp ', entryUUID, acceptBool);
  return rpc.rpc('pdfTimelineEntry', entryUUID, pdfDocuments);
}

export async function UpdateGroupIdTimelineEntry(entryUUID, groupId) {
  return rpc.rpc('updateGroupIdTimelineEntry', entryUUID, groupId);
}

export async function getCalendarExportURL(resetCalendarSecret = false) {
  return rpc.rpc('getCalendarExportURL', resetCalendarSecret);
}
export async function getBlockedTimes() {
  const bTimes = await rpc.rpc('getBlockedTimes');
  return bTimes;
}

rpc.addHandler('deleteTimelineEntryAndUUID', (entryUUID) => {
  deleteTimelineEntryAndUUIDEvent(entryUUID);
});

export async function getTimelineEntryCache(entryUUID) {
   const entries = await rpc.rpc('getTimelineEntries', entryUUID);
   return entries;
}


// For realtime updates to entries (i.e. someone edited it)
rpc.addHandler('setTimelineEntryInfo', (entryUUID, entryInfo) => {
  //  console.log('set timeline Entry Info')
  setTimelineEventInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('setTimelineEntries', (date, entryUUIDs) => {
  //  console.log('setTimelineEntries', date, entryUUIDs);
  if (date && entryUUIDs) {
    setTimelineEventUUIDsEvent([date, entryUUIDs]);
  }
});
// === TIMELINE RPC END ===


// ==== GroupChat RPC START ===
rpc.addHandler('setGroupChatUpdates', () => {
  getGroupChatByUserUIdEvent();
});
// ==== GroupChat RPC END ===

// ==== MESSAGING RPC START ===

export async function getUserMessages() {
  return rpc.rpc('getUserMessages');
}

rpc.addHandler('setMessageEntries', (entryUUIDs) => {
  if (entryUUIDs) {
    setMessageUUIDsEvent(entryUUIDs);
  }
});

//  StATS!!!
rpc.addHandler('setMessageStats', (entryUUIDs) => {
  if (entryUUIDs) {
    //  console.log('DO SOMETHING !!!!! ', entryUUIDs);
    // setMessageUUIDsEvent(entryUUIDs);
  }
});

rpc.addHandler('setMessageEntryInfo', (entryUUID, entryInfo) => {
  // setMessageEventInfo([entryUUID, entryInfo]);
  setMessagesInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('playNotificationToUser', () => {
  try {
    if (!store.state.persisted.notificationsMuted) {
      const audioPath = '/media/notificationSound.mp3';
      playNotificationToUser(audioPath, true, 'wsMsg(rpc) playNotificationToUser');
    }
  } catch (error) {
    console.warn('Failed to play notification audio', error);
  }
  if (!historyMessagesModalStore.getState()) { // ticket #3052 dont show notify if we have the modal messages open
    dispatchSuccessAlert(Vue.prototype.$t('components.notificationDropDown.newMessages'));
  }
});

export async function getMessageEntryByUUID(entryUUID) {
  return rpc.rpc('getMessageEntryByUUID', entryUUID);
}

export async function createMessageEntry(entryInfo) {
  return rpc.rpc('createMessageEntry', entryInfo);
}

export async function createReplyMessageEntry(entryInfo) {
  return rpc.rpc('createReplyMessageEntry', entryInfo);
}

export async function markAsDeletedByUser(entryUUID, deleted = true) {
  return rpc.rpc('markAsDeletedByUser', entryUUID, deleted);
}
export async function updateMessagesUsersParams(param, value) {
  return rpc.rpc('updateMessagesUsersParams', param, value);
}

export async function markAsRepliedMasterMessage(entryUUID, replied = true) {
  return rpc.rpc('markAsRepliedMasterMessage', entryUUID, replied);
}

export async function updateSpecificMessagesParam(entryUUID, param, paramBool) {
  return rpc.rpc('updateSpecificMessagesParam', entryUUID, param, paramBool);
}

export async function updateSpecificMessagesParamAllUsers(entryUUID, param, paramBool) {
  return rpc.rpc('updateSpecificMessagesParamAllUsers', entryUUID, param, paramBool);
}

export async function updateMessagesParams(param, paramBool) {
  return rpc.rpc('updateMessagesParams', param, paramBool);
}

export async function updateConversationParams(userUUID, param, paramBool) {
  return rpc.rpc('updateConversationParams', userUUID, param, paramBool);
}

export function playNotificationToUser(audioPath, playRingingDevice = true, context = '') {
  // Get real devices id's
  const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
  const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
  // Pre-Set outputs
  const audioOutput = new Audio(audioPath);
  const ringingOutput = new Audio(audioPath);
  // Sync audioDevice
  let promise = Promise.resolve();
  if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
    promise = audioOutput.setSinkId(audioDeviceId);
  }
  promise
    .then(() => audioOutput.play())
    .catch(err => console.warn(context, 'Failed to play notification audio on audioOutput', err));
  // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
  if (playRingingDevice && audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
    promise = ringingOutput.setSinkId(ringingDeviceId);
    promise
      .then(() => ringingOutput.play())
      .catch(err => console.warn(context, 'Failed to play notification audio on ringingOutput', err));
  }
  /* // Alternative new code
  // Get real devices id's
  const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
  const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
  function setSinkIdAndPlay(audioElement, deviceId) {
    let promise = Promise.resolve();
    if (deviceId && 'sinkId' in audioElement && 'setSinkId' in audioElement) {
      promise = audioElement.setSinkId(deviceId)
        .then(() => console.debug('Success, output device attached', { audioPath, context, deviceId }))
        .catch(err => {
          console.warn('Error, unable to attach output device', { audioPath, context, deviceId }, err);
          if (deviceId === ringingDeviceId) throw err;
        });
    }
    promise = promise
      .then(() => audioElement.play())
      .catch(err => console.warn('Failed to play notification audio output device', { audioPath, context, deviceId }, err));
    return promise;
  }
  // Set outputs
  const audioOutput = new Audio(audioPath);
  const ringingOutput = new Audio(audioPath);
  let ringingPromise = Promise.resolve();
  let audioDevicePromise = Promise.resolve();
  // Play at ringing device only if we have and ringingDeviceId for it and it's different from audioOutputId
  if (playRingingDevice && audioDeviceId && ringingDeviceId && ringingDeviceId !== audioDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput) {
    ringingPromise = setSinkIdAndPlay(ringingOutput, ringingDeviceId);
  }
  // Sync audioDevice
  audioDevicePromise = setSinkIdAndPlay(audioOutput, audioDeviceId);
  return Promise.allSettled([ringingPromise, audioDevicePromise]);
  */
}


// === MESSAGING RPC END ===

/**
 * Promise holder for conference purge
 * @type {Promise<void>}
 */
let conferencePurgePromise;

/**
 * Executes conference purge if needed
 */
export async function executeConferencePurge() {
  let purgeNeeded = false;
  if (!store.state.user.lastPurgedConferences || store.state.user.lastPurgedConferences === '') {
    const daySet = moment().format('YYYY-MM-DD');
    purgeNeeded = true;
    setTimeout(() => {
      store.setLastConfPurged(daySet)
    }, 1000);
  } else {
    const lastTime = moment(store.state.user.lastPurgedConferences);
    const now = moment().format('YYYY-MM-DD');
    const diff = lastTime.diff(now, 'days');
    if (diff < 0) {
      purgeNeeded = true;
      setTimeout(() => {
        store.setLastConfPurged(now)
      }, 1000);
    }
  }
  if (purgeNeeded) {
    const result = await rpc.rpc('executeConferencePurge');
    if (result && result.ctd) {
      await parallel(1, [...result.ctd], async (confId) => {
        await rpc.rpc('deleteGroupConferenceEntry', confId);
      })
    }
    if (result && result.htd) {
      await parallel(1, [...result.htd], async (confId) => {
        await rpc.rpc('deleteConferenceHallEntry', confId);
      })
    }
    if (result && result.etd) {
      await parallel(1, [...result.etd], async (uuid) => {
        await rpc.rpc('deleteTimelineEntry', uuid);
      })
    }
    if (result && result.dtd) {
      await parallel(1, [...result.dtd], async (uuid) => {
        await rpc.rpc('deleteCompanyDocumentEntry', uuid);
      })
    }
  }
}

// === CONFERENCE HALLS RPC BEGIN ===
export async function createConferenceHallEntry(entry) {
  return rpc.rpc('createConferenceHallEntry', entry);
}

export async function getMyConferencesHallDetailEntries() {
  return rpc.rpc('getMyConferencesHallDetailEntries');
}

export async function deleteConferenceHallEntry(entryUUID) {
  return rpc.rpc('deleteConferenceHallEntry', entryUUID);
}

rpc.addHandler('setConferenceHallEntryUser', (entryUUIDs) => {
  if (entryUUIDs) {
    setConferenceHallUUIDsEvent(entryUUIDs);
  }
});

rpc.addHandler('setConferenceHallEntryInfo', (entryUUID, entryInfo) => {
  setConferenceHallInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('deleteConferenceHallEntryAndUUID', (entryUUID) => {
  deleteConferenceHallEntryAndUUIDEvent(entryUUID);
});

export async function getConferenceHallEntryByUUID(entryUUID) {
  return rpc.rpc('getConferenceHallEntryByUUID', entryUUID);
}

export async function getUserConferenceHalls() {
  // Executes conference purge if needed and waits
  if (!conferencePurgePromise) conferencePurgePromise = executeConferencePurge();
  await conferencePurgePromise;

  return rpc.rpc('getUserConferenceHalls');
}

export async function updateConferenceHall(entry) {
  return rpc.rpc('updateConferenceHall', entry);
}
// === CONFERENCES HALL RPC END ===

// === GROUP CONFERENCES RPC BEGIN ===
export async function createGroupConferenceEntry(entry) {
  // console.log(entry);
  return rpc.rpc('createGroupConferenceEntry', entry);
}

export async function updateGroupConference(entry) {
  return rpc.rpc('updateGroupConference', entry);
}

export async function getMyConferencesDetailEntries() {
  return rpc.rpc('getMyConferencesDetailEntries');
}

export async function getUserGroupConferences() {
  // Executes conference purge if needed and waits
  if (!conferencePurgePromise) conferencePurgePromise = executeConferencePurge();
  await conferencePurgePromise;

  return rpc.rpc('getUserGroupConferences');
}

// company documents start

export async function getUserCompanyDocuments() {
  const result = rpc.rpc('getUserCompanyDocuments');
  return result;
}
export async function createCompanyDocumentEntry(docInfo) {
  return rpc.rpc('createCompanyDocumentEntry', docInfo);
}
export async function getCompanyDocumentEntryByUUID(entryUUID) {
  return rpc.rpc('getCompanyDocumentEntryByUUID', entryUUID);
}

export async function getCompanyDocumentsForCall(id, type) {
  return rpc.rpc('getCompanyDocumentsForCall', id, type);
}

export async function deleteCompanyDocumentEntry(entryUUID) {
  return rpc.rpc('deleteCompanyDocumentEntry', entryUUID);
}
export async function getMyCompanyDocumentEntries() {
  return rpc.rpc('getMyCompanyDocumentEntries');
}
export async function updateCompanyDocument(entry) {
  return rpc.rpc('updateCompanyDocument', entry);
}
export async function dumpDocumentcache() {
  return rpc.rpc('dumpDocumentcache');
}

rpc.addHandler('setCompanyDocumentEntryUser', (entryUUIDs) => {
  if (entryUUIDs) {
    setCompanyDocumentUUIDsEvent(entryUUIDs);
  }
});

rpc.addHandler('setCompanyDocumentEntryInfo', (entryUUID, entryInfo) => {
  setCompanyDocumentInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('deleteCompanyDocumentEntryAndUUID', (entryUUID) => {
  deleteCompanyDocumentEntryAndUUIDEvent(entryUUID);
});

// company documents end

rpc.addHandler('setGroupConferenceEntryUser', (entryUUIDs) => {
  if (entryUUIDs) {
    setGroupConferenceUUIDsEvent(entryUUIDs);
  }
});

rpc.addHandler('setGroupConferenceEntryInfo', (entryUUID, entryInfo) => {
  setGroupConferenceInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('deleteGroupConferenceEntryAndUUID', (entryUUID) => {
  deleteGroupConferenceEntryAndUUIDEvent(entryUUID);
});

export async function deleteGroupConferenceEntry(entryUUID) {
  return rpc.rpc('deleteGroupConferenceEntry', entryUUID);
}

export async function getGroupConferenceEntryByUUID(entryUUID) {
  return rpc.rpc('getGroupConferenceEntryByUUID', entryUUID);
}

// === GROUP CONFERENCES RPC END ===

// === NOTES RPC ===
export async function createNoteEntry(entryInfo) {
  return rpc.rpc('createNoteEntry', entryInfo);
}

export async function getUserNotes() {
  return rpc.rpc('getUserNotes');
}

rpc.addHandler('setNoteEntries', (entryUUIDs) => {
  if (entryUUIDs) {
    setNoteUUIDsEvent(entryUUIDs);
  }
});

rpc.addHandler('setNoteEntryInfo', (entryUUID, entryInfo) => {
  // setMessageEventInfo([entryUUID, entryInfo]);
  setNotesInfoEvent([entryUUID, entryInfo]);
});

rpc.addHandler('deleteNoteEntryAndUUID', (entryUUID) => {
  deleteNoteEntryAndUUIDEvent(entryUUID);
});

export async function deleteNoteEntry(entryUUID) {
  return rpc.rpc('deleteNoteEntry', entryUUID);
}

export async function getNoteEntryByUUID(entryUUID) {
  return rpc.rpc('getNoteEntryByUUID', entryUUID);
}

export async function updateNotesUsersParams(entryUUID, param, value) {
  return rpc.rpc('updateNotesUsersParams', entryUUID, param, value);
}

export async function updateNoteViews(entryUUID) {
  return rpc.rpc('updateNoteViews', entryUUID);
}

// === NOTES RPC END===

// === CONFERENCES RPC BEGIN ===
export async function getAllConferences() {
  return rpc.rpc('getAllConferences');
}

export async function clearAllConferences() {
  return rpc.rpc('clearAllConferences');
}

rpc.addHandler('setAllConferences', (data) => {
  if (data) {
    fillAllConferencesEvent(data);
  }
});

export async function getMyConferences(uuid) {
  return rpc.rpc('getMyConferences', uuid);
}

rpc.addHandler('setMyConferences', (data) => {
  if (data) {
    fillMyConferencesEvent(data);
  }
});

export async function createConferenceEntry(entryInfo) {
  return rpc.rpc('createConferenceEntry', entryInfo);
}

export async function editConferenceMembers(confID, uuidToRemove) {
  return rpc.rpc('editConferenceMembers', confID, uuidToRemove);
}

/*
export async function deleteConferenceUserByUUID(uuid, conferenceId) {
  return rpc.rpc('deleteConferenceUserByUUID', uuid, conferenceId);
}
*/

export async function deleteGroupFromUser(entryInfo) {
  let concernedUsers = [];
  const state = store.state.user.userGroups;

  const extant = state.find(e => e.id === entryInfo);
  if (extant && extant.id) {
    concernedUsers = extant.members;
  }
  // remove group;
  if (extant && extant.id) {
    store.deleteGroup(entryInfo);
  }
  // remove members
  if (concernedUsers && concernedUsers.length > 0) {
    for (const element of concernedUsers) {
      await rpc.rpc('deleteUserByUUID', element.uuid);
      store.setUserVisitor(element.uuid, undefined);
    }
  }

  return store.state.user.userGroups;
}

export async function deleteGroup(groupUUID) {
  return rpc.rpc('deleteGroup', groupUUID);
}

export async function deleteConference(entryUUID) {
  return rpc.rpc('deleteConference', entryUUID);
}

export async function leaveGroup(payload){
  return rpc.rpc('leaveGroup', payload[0], payload[1], payload[2]);
}

rpc.addHandler('setAllConferences', (data) => {
  if (data) {
    fillMyConferencesEvent(data);
  }
});

// === CONFERENCES RPC END ===

// === PRESENCE RPC BEGIN ===
export async function getPresenceWatching(uuid) {
  return rpc.rpc('getPresenceWatching', uuid);
}

rpc.addHandler('setPresenceWatching', (data) => {
  if (data) {
    const obj = { watching: data };
    fillPresenceWatchingEvent(obj);
  }
});

export async function createPresenceWatchingEntry(uuid) {
  return rpc.rpc('createPresenceWatchingEntry', uuid);
}

export async function messageWatchedUsers(uuids) {
  // console.log('sending from wsmsg ', uuids)
  for (let index = 0; index < uuids.length; index++) {
    const element = uuids[index];
    const isConnected = rpc.rpc('isUserConnected', element);
    if (!isConnected) {
      // console.log('Not online ' , element, webrtcConnections);
      continue;
    }
    const userToSendMsg = {};
    userToSendMsg[element] = {
      userUUID: element,
      read: false,
      replied: false,
      deleted: false,
      viewed: false,
    };
    const header = {
      translateMessage: {
        translateKey: 'generics.isOnline',
        params: [store.state.user.name]
      }
    };
    const dataMsg = {
      body: Vue.prototype.$t('generics.isOnline', [store.state.user.name]),
      header: header,
      date: Date.now(),
      users: userToSendMsg,
      isReply: false,
      creatorUUID: store.state.ownUUID,
      masterMessageUUID: null,
      parentMessageUUID: null,
      type: 'message',
    };
    // console.log('Messaging ' , element);
    newMessageEvent(dataMsg);
    // console.log('removing watched ', element)
    return rpc.rpc('removePresenceWatchingEntry', element);
    // remove watch and watching
    // return rpc.rpc('clearPresenceWatchingEntry', element);
  }
}

export async function removeUserPresenceWatching(uuid, dataMsg) {
  const isConnected = rpc.rpc('isUserConnected', uuid);
  if (isConnected) {
    newMessageEvent(dataMsg);
    return rpc.rpc('removePresenceWatchingEntry', uuid);
  }
}

export async function clearPresenceWatching() {
  return rpc.rpc('clearPresenceWatching');
}

// === PRESENCE RPC END ===

export async function transferVisitor(uuid, visitorUUID) {
  return rpc.rpc('transferVisitor', uuid, visitorUUID);
}

// == PayPal Payment Request
export async function requestVisitorPayPalPayment(paymentInfo) {
  return rpc.rpc('requestVisitorPayPalPayment', paymentInfo);
}

// == PayPal Payment Log
export async function logVisitorPayPalPayment(details) {
  return rpc.rpc('logVisitorPayPalPayment', details);
}

export async function getPayments() {
  return rpc.rpc('getPayments');
}

export async function getPaymentsForGuest(uuid) {
  return rpc.rpc('getPaymentsForGuest', uuid);
}

export async function voteQualityCall(quality) {
  return rpc.rpc('voteQualityCall', quality);
}

export async function setRepresentedUsers(uuid, representedUUID, adding = true) {
  return rpc.rpc('setRepresentedUsers', uuid, representedUUID, adding);
}

export async function setImpersonatingUserUUID(uuid) {
  return rpc.rpc('setImpersonatingUserUUID', uuid);
}

export async function setConferenceForRepresentive(params) {
  return rpc.rpc('setConferenceForRepresentive', params.uuid, params.group);
}

export async function removeConferenceForRepresentive(params) {
  return rpc.rpc('removeConferenceForRepresentive', params.uuid, params.index);
}

export async function setVisitorForRepresentive(params) {
  return rpc.rpc('setVisitorForRepresentive', params.uuid, params.visitorData);
}

export async function reportUserRejectedOnCallDisplay() {
  return rpc.rpc('reportUserRejectedOnCallDisplay');
}

export async function getChangelog(type, language) {
  return rpc.rpc('getChangelog', type, language);
}

export async function loginSpeechLive(uuid) {
  return rpc.rpc('loginSpeechLive', uuid);
}

export async function testRemoteStorage(remoteStorageData) {
  return rpc.rpc('testRemoteStorage', remoteStorageData);
}

export async function bridgeCallCreateRoom(roomGuidParam = '') {
  return rpc.rpc('bridgeCallCreateRoom', roomGuidParam);
}

export async function bridgeCallDoStaticRoom(op = '', param = {}) {
  return rpc.rpc('bridgeCallDoStaticRoom', op, param);
}

export async function bridgeCallGetAccessToken(roomId = '', role = '', uuid = '') {
  return rpc.rpc('bridgeCallGetAccessToken', roomId, role, uuid);
}

export async function bridgeCallGetAllChatMessages() {
  return rpc.rpc('bridgeCallGetAllChatMessages');
}

export async function bridgeCallGetAllListeners() {
  return rpc.rpc('bridgeCallGetAllListeners');
}

export async function bridgeCallGetPodiumUrl() {
  return rpc.rpc('bridgeCallGetPodiumUrl');
}

export async function bridgeCallRaiseHand(raised) {
  return rpc.rpc('bridgeCallRaiseHand', raised);
}

export async function bridgeCallSendChatMessage(text) {
  return rpc.rpc('bridgeCallSendChatMessage', text);
}

export async function bridgeCallSetPodiumUrl(url) {
  return rpc.rpc('bridgeCallSetPodiumUrl', url);
}

export async function bridgeCallScreenShareState(active) {
  return rpc.rpc('bridgeCallScreenShareState', active);
}

export async function bridgeCallStartStreaming(rtmp) {
  return rpc.rpc('bridgeCallStartStreaming', rtmp);
}

export async function createGitLabIssue(title, description, problem, browser, system, network, location) {
  return rpc.rpc('createGitLabIssue', title, description, problem, browser, system, network, location);
}

export async function deleteWallpaper(filename) {
  return rpc.rpc('deleteWallpaper', filename);
}

export async function listWallpapers() {
  return rpc.rpc('listWallpapers');
}

export async function uploadWallpaper(base64) {
  return rpc.rpc('uploadWallpaper', base64);
}

export async function deleteGroupImage(filename) {
  return rpc.rpc('deleteGroupImage', filename);
}

export async function uploadGroupImage(groupId, base64) {
  return rpc.rpc('uploadGroupImage', groupId, base64);
}

export async function deleteConferenceImage(filename) {
  return rpc.rpc('deleteConferenceImage', filename);
}

export async function uploadConferenceImage(conferenceId, base64) {
  return rpc.rpc('uploadConferenceImage', conferenceId, base64);
}

export async function validateEuropeanVat(countryCode, vatNumber) {
  return rpc.rpc('validateEuropeanVat', countryCode, vatNumber);
}

export async function readDailyTimeCountings(uuid) {
  return rpc.rpc('readDailyTimeCountings', uuid);
}

export async function delCustomDailyTimeCounting(uuid, date, _id) {
  return rpc.rpc('delCustomDailyTimeCounting', uuid, date, _id);
}

export async function setCustomDailyTimeCounting(uuid, date, customChanges) {
  return rpc.rpc('setCustomDailyTimeCounting', uuid, date, customChanges);
}

try {
  document.wsCall = wsCall;
  document.sendToUUID = sendToUUID;
  document.setNamespaceSetting = setNamespaceSetting;
  document.createNewUser = createNewUser;
  document.createTimelineEntry = createTimelineEntry;
  document.updateTimelineEntry = updateTimelineEntry;
  document.deleteTimelineEntry = deleteTimelineEntry;
  document.deleteCompanyDocumentEntry = deleteCompanyDocumentEntry;
  document.dumpDocumentcache = dumpDocumentcache;
  document.deleteGroupConferenceEntry = deleteGroupConferenceEntry;
  document.createMessageEntry = createMessageEntry;
  document.createNoteEntry = createNoteEntry;
  document.getGroupConferenceEntryByUUID = getGroupConferenceEntryByUUID;
  document.createPresenceWatchingEntry = createPresenceWatchingEntry;
  document.createConferenceEntry = createConferenceEntry;
  document.getChangelog = getChangelog;
  document.closeConnection = closeConnection;
  // document.getUserInviteLink = getUserInviteLink;
  // document.getTimelineEntryByUUID = getTimelineEntryByUUID;
  // document.getPayments = getPayments;
  // document.getBlockedTimes = getBlockedTimes;
  // document.getCompanyDocumentsForCall = getCompanyDocumentsForCall;
  // document.getTimelineEntryCache = getTimelineEntryCache;
  // document.executeConferencePurge = executeConferencePurge;
  // document.getUserGroupConferences = getUserGroupConferences;
  // document.getMyCompanyDocumentEntries = getMyCompanyDocumentEntries;
  // document.readDailyTimeCountings = readDailyTimeCountings;
} catch (err) { }
