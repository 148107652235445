<template>
  <div class="primaryBackground" :class="`${isMobile ? 'groupListMobile' :'groupListNormal'}`">
    <div v-for="(item, index) in slicedGroups" :key="item.uuid" class="group" :id="'tixfor' + index">
      <GroupListItem :isLastItem = "isLastItem(index)"  flat :group="item" :admin="getAdminUserInfo(item)" :section="section" :typeData="typeData" />
    </div>
    <infinite-loading @infinite="infiniteHandler" :distance="10" >
      <template #error>
        <div v-html="'&nbsp;'"></div>
      </template>
      <template #complete>
        <div v-html="'&nbsp;'"></div>
       </template>
    </infinite-loading>
</div>
</template>

<script>
import GroupListItem from './groupListItem.vue';
import store from '../../store';
import { isMobile } from "../../lib/mobileUtil";
import InfiniteLoading from "v3-infinite-loading";
export default {
  components: { GroupListItem,"infinite-loading": InfiniteLoading, },
  props: ['groups', 'section', 'typeData'],
  data() {
    return {
      state: store.state,
      sliceIndex: 20,
      isMobile: isMobile(),
    };
  },
  methods:{
    isLastItem(index) {
      return index === this.groups.length - 1;
    },
    getAdminUserInfo(group) {
      const admin = group.members.find((user) => user.uuid === group.admin_uuid);
      return admin;
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.groups.length) {
        this.sliceIndex += 10;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
  computed:{
    slicedGroups() {
      return this.groups.slice(0, this.sliceIndex);
    },
  }
};
</script>

<style scoped lang="scss">
.vueScrollSidebar {
  .__rail-is-vertical {
    overflow: hidden !important;
    display: none !important;
  }
}
.scroller {
  height: 100%;
}
.vueScrollGroups{
  max-height: calc(100vh - 485px)!important;
    @media screen and (max-height: 650px) {
      max-height: unset !important;
    }
  & .__view{
    width: unset !important;
  }
}
.vueScrollOne{
  height: 140px !important;
  @media screen and (max-height: 650px) {
    height: 140px!important;
  }
}
.vueScrollTwo{
  // max-height: calc(100vh - 720px)!important;
  height: 280px !important;
  @media screen and (max-height: 650px) {
    height: 280px!important;
  }
}
.groupListNormal{
  // max-height: calc(100% - 464px);
  max-height: calc(100vh - 63vh);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }

}
.groupListCoffee{
  max-height: calc(100vh - 73vh);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }
}
.groupList{
  // max-height: calc(100vh - 22vh);
  // min-height: calc(100vh - 38vh);
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }
}
.groupListMobile{
  max-height: calc(100% - 364px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

<style lang="scss">
.vueScrollGroups, .vueScrollOne{
  .__view{
    width: unset !important;
  }
}
</style>