import { groupChattingInfoStore } from './state';
import { receivedGroupChatStateEvent, getGroupChatByUserUIdEvent } from './events';
import { updateGroupChatEvent,getGroupMessagesByGroupUIdEvent } from '../../../effector/modals/sendGroupChatMessageModal/events';

import { ownUserUUIDState, ownUserState } from '../state';
import { getGroupChattingsByUserUId, getGroupMessagesByGroupUId } from '../../../lib/wsMsg';
import { groupMessagesToMe } from "../../../effector/message";
import { stat } from 'fs';

// Check all visitors in groupChatting room and populate the state 
groupChattingInfoStore.on(receivedGroupChatStateEvent, (state, payload) => {
  const uuidUser = ownUserUUIDState.getState();
  return state;
});


const updateGroupMessages = async (state) => {
  const uuidUser = ownUserUUIDState.getState();
  const updatedGroups = await getGroupChattingsByUserUId(uuidUser);
  updateGroupChatEvent(updatedGroups);
  groupChattingInfoStore.setState({
    ...state,
    groups: Object.values(updatedGroups||{}),
  });
};

groupChattingInfoStore.on(getGroupChatByUserUIdEvent, async (state, payload) => {
  updateGroupMessages(state);
  return { ...state }; 
});


groupChattingInfoStore.on(groupMessagesToMe, (state, payload) => {
  updateGroupMessages(state);
  return { ...state }; 
});