<template>
  <v-card
    :class="`py-2 px-4 footersBackground footerModal`"
  >
    <v-row class="pl-2 btns w-100 mx-0 my-0">
      <v-btn
        v-if="showDelete"
        rounded="5px"
        color="primary"
        style="border-radius: 4px; min-width: 45px;"
        icon
        tile
        variant="outlined"
        density="comfortable"
        class="ml-3 mr-2 greyBtn text-primary"
      >
        <DeleteBasket
          indexData=""
          :delFunction="deleteFunction" 
          fontSize="17" 
          :prevent="false"
        ></DeleteBasket>
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        rounded="5px"
        color="primary"
        style="border-radius: 4px; min-width: 45px;"
        icon
        tile
        variant = "outlined"
        density="comfortable"
        class="ml-3 mr-2 greyBtn text-primary"
        @click="closeModalFunction('ok')"
      >
        <font-awesome-icon
          :icon="['fal', 'check']"
          :style="{ fontSize: '20px' }"
        />
      </v-btn>
      <v-btn
        rounded="5px"
        color="primary"
        style="border-radius: 4px; min-width: 45px;"
        icon
        tile
        variant = "outlined"
        density="comfortable"
        class="ml-3 mr-2 greyBtn text-primary"
        v-if="showCancel"
        @click="closeModalFunction('cancel')"
      >
        <font-awesome-icon
          :icon="['fal', 'times']"
          :style="{ fontSize: '20px' }"
        />
      </v-btn>
    </v-row>
  </v-card>
</template>


<script>
import store from "../../../store";
import DeleteBasket from "../../ui/deleteBasket.vue";

export default {
  components: {DeleteBasket},
  props: [
    "closeModalFunction",
    "showCancel",
    "showDelete",
    "deleteFunction", 
    "leaveFunction"
  ],
  data: () => ({
    state: store.state,
  }),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  padding-left: 0 !important;
  .btns {
    justify-content: flex-end;
    align-items: center;
  }
}
.guestOrBeaconfooterModal {
    width: 100%;
    border-radius: 0px !important;
    // color: white;
    .btns {
      justify-content: space-between !important;
      align-items: center;
      .infoLabel {
        color: black !important;
        max-width: 50%;
      }
    }
  }
</style>
<style>
.buttonIconPrimaryFooter {
  box-shadow: none !important;
  border-radius: 4px !important;
  /* background-color: white !important; */
  min-width: 45px !important;
}
.buttonIconPrimaryFooterDisable {
  background-color: rgba(255, 255, 255, 0.40) !important;
  /* color: #11889d !important; */
}
.buttonPrimaryFooter{
  box-shadow:none!important;
  color: #11889d!important;
}
</style>