import { 
    deleteGroupChatEvent,
    leaveGroupEvent
} from './events';

import {
    deleteGroupChatEffect,
    leaveGroupEffect
} from './effects';

deleteGroupChatEvent.watch((payload) => {
    deleteGroupChatEffect(payload);
});

leaveGroupEvent.watch((payload) =>{
    leaveGroupEffect(payload);
})
