<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <v-row>
    <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
      <v-card flat class="d-flex flex-column fill-height">
        <v-card-text class="flex-grow-1">
          <template v-for="(message, name, index) in filteredMessages" :key="index">
            <div  style="gap: 8px;" :class="['d-flex position-relative flex-row', message.creatorUUID === ownUUID ? 'flex-row-reverse user-own-message' : '']">
              <div v-if="!isUserOwnMessage(message)">
                <v-tooltip location="top" disabled>
                  <template v-slot:activator="{ props }">
                    <div class="d-flex align-center p-2">
                      <v-avatar
                        tile
                        v-bind="props"
                        size="45"
                        :class="`borderRadius4 mx-2 my-0 ` "
                        :style="getBorderByStatusFull(state.group[message.creatorUUID], '2px')"
                      >
                        <DefaultAvatar
                          :size="45"
                          :userData="personInfo(message.creatorUUID)"
                          v-if="getAvatarForUuid(message.creatorUUID) === 'img/default_profile_picture.png'"
                          :setUserInModal="setUserInModal"
                        ></DefaultAvatar>
                        <v-img
                          v-else
                          class="mx-2 borderRadius4 cursorPointer"
                          @error="onImgError"
                          :src="getAvatarForUuid(message.creatorUUID)"
                          max-height="45"
                          max-width="45"
                          contain
                          lazy-src="assets/default_profile_picture.png"
                        ></v-img>
                      </v-avatar>
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            density="compact"
                            variant="text"
                            @click.stop.prevent="callUser(message.creatorUUID)"
                            icon
                            :disabled="disableCallBtn(message.creatorUUID) == true"
                            v-bind="props"
                          >
                            <font-awesome-icon
                              :icon="['fal', 'phone']"
                              :color="setBorderByStatus(personInfo(message.creatorUUID))"
                              :style="{ fontSize: '16px' }"
                            ></font-awesome-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("components.userProfile.callUser") }}</span>
                      </v-tooltip>
                      
                    </div>
                  </template>
                </v-tooltip>
                <div class="msg-username">
                  <span class="text-center mt-1">{{userName(message.creatorUUID)}}</span>
                </div>
              </div>
              <div class="deletion-container" v-if="isUserOwnMessage(message)">
                <DeleteBasket 
                  v-if="state.namespaceSettings.isDeleteForAllUsersEnabled"
                  :indexData="message" 
                  :delFunction="removeMessageForAllUsers" 
                  fontSize="16" 
                  prevent="true" 
                />

                <DeleteBasket 
                  v-else
                  :indexData="message" 
                  :delFunction="removeIndividualMessageEffect" 
                  fontSize="16" 
                  prevent="true" 
                />
              </div>
              <v-card
                :color="message.creatorUUID === ownUUID ? isDark ? 'primary' : '#fff' : '#bbc9cc4d'"
                style="
                  height: auto;
                  word-break: break-word;
                  max-width: 85%;
                "
                class="pa-3 mb-2 messageBorders"
                :class="isDark? 'msgCardDark': 'msgCard'"
                label
              >
                <div style="white-space: pre-line;">
                  <span v-html="$sanitize(checkForUrls(getMessageBody(message)))"></span>
                </div>
                <v-row class="py-3">
                  <v-col cols="8" v-if="showBtnConferences(message)" class="pb-0">
                      <v-btn v-if="getStatusConference(message) === 'pending'" class="mr-1" color="primary" @click="acceptConference(message)">{{$t('components.meetingLineModal.accept')}}</v-btn>
                      <v-btn v-if="getStatusConference(message) === 'pending'" color="primary" @click="cancelConference(message)">{{$t('components.meetingLineModal.reject')}}</v-btn>
                      <p v-if="getStatusConference(message) === 'accepted' || getStatusConference(message) === 'rejected'">{{getStatusConference(message) === 'accepted' ? $t('components.conferenceForm.acceptedConference') : $t('components.conferenceForm.rejectedConference')}}</p>
                  </v-col>
                  <v-col :cols="showBtnConferences(message)? '4': '12'" class="text-right align-self-end">
                      <span class="text-caption">{{ dateFormatted(message.creationServerTs || message.date) }}</span>
                      <div v-if="message.attachmentId" class="d-flex align-center pt-2">
                        <span class="text-caption">{{ shortenFileNameIfRequired(message.fileName) }}</span>
                        <a :href="buildAttachmentUrl(message.attachmentId)" target="_blank" rel="noopener noreferrer" download>
                          <v-icon color="grey-darken-1" size="24" style="margin-left: 15px;">mdi-download</v-icon>
                        </a>
                      </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { timelineEvents, dispatchRsvpTimelineEntry } from "../../effector/timeline";
import {checkForUrls, getBorderByStatusFull} from "../../utils/basicFunctions"
import DefaultAvatar from "../image/defaultAvatar.vue";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import DeleteBasket from "../ui/deleteBasket.vue";
import {
  resetSendGroupChatMessageModalEvent,
} from "../../effector/modals";
import {
  messagesToMe,
  dispatchDeleteMessageEvent,
  dispatchCreatorDeleteForAllEvent
} from "../../effector/message";
export default {
  components: {
    DefaultAvatar,
    DeleteBasket
  },
  props: ["messages", "members"],
  data() {
    const effector = {
      cacheTimeline: timelineEvents,
      messagesToMe: messagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment,
      // Effector
      ...effector,
    };
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    filteredMessages() {
    const result = {};
    
    for (const key in this.messages) {
      const message = this.messages[key];
      if (!message.users || !message.users[this.ownUUID] || !message.users[this.ownUUID].deleted) {
        result[key] = message;
      }
    }
    
    return result;
  }
  },
  methods: {
    removeIndividualMessageEffect(message) {
      dispatchDeleteMessageEvent(message.messageUUID);
    },
    removeMessageForAllUsers(message) {
    if (message.creatorUUID === this.ownUUID) {
      dispatchCreatorDeleteForAllEvent(message.messageUUID);
    } else {
      console.log("Only the creator can delete for everyone");
    }
  },
    personInfo(uuid) {
      return this.state.group[uuid] || null;
      //return this.members.find((member) => member.uuid === uuid) || null;
    },
    isUserOwnMessage(message) {
      return(message.creatorUUID === this.ownUUID);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    userName(uuid) {
      const person = this.personInfo(uuid);
      if (!person) return "Anonymous";
      return person.user.name || "Anonymous";
    },
    checkForUrls(message){
      return checkForUrls(message, false)
    },
    getMessageBody(message){
      if(this.isATranslateMessage(message)){
        const keyTranslate = message.header.translateMessage.translateKey;
        let params = message.header.translateMessage.params || null;
        let translate = params ? this.$t(keyTranslate,params) : this.$t(keyTranslate);
        return translate
      }else{
        return message.body
      }
    },
    showBtnConferences(message){
      if(message.type === 'plannerEvent' && message.header && message.header.typeMsg === 'newConference' && message.creatorUUID !== this.state.ownUUID){
        return true
      }else{
        return false
      }
    },
    isATranslateMessage(message){
     if(message.header && message.header.translateMessage  && message.header.translateMessage.translateKey){
        return true
      }else{
        return false
      }
    },
    getStatusConference(message) {
      let conferenceId = null;
       if(message.type === 'plannerEvent' && message.header && message.header.typeMsg === 'newConference' && message.header.meetingId){
          conferenceId = message.header.meetingId
      }
      if(!conferenceId) return;
      const dataConference = this.cacheTimeline.find(
        (e) => e.uuid === conferenceId
      );
      if(dataConference && dataConference.rsvp && dataConference.rsvp[this.ownUUID] && dataConference.rsvp[this.ownUUID].status){
        return dataConference.rsvp[this.ownUUID].status;
      }else {
        return 'error'
      }
    },
    acceptConference(message){
      dispatchRsvpTimelineEntry([message.header.meetingId, true, this.$t('components.meetingLineModal.accept')]);
    },
    cancelConference(message){
      dispatchRsvpTimelineEntry([message.header.meetingId, false, this.$t('components.conferenceForm.defaultMessageToDecline')]);
    },
    getCreatorName(uuid) {
      return store.getNameForUuid(uuid);
    },
    getCreatorTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    dateFormatted(date) {
      return this.moment(new Date(date)).format("DD.MM.YY, HH:mm");
    },
    buildAttachmentUrl(attachmentId) {
      return `${window.location.protocol}//${window.location.host}/api/fileDownload/attachments/${attachmentId}`;
    },
    shortenFileNameIfRequired(fileName) {
      if (!fileName || fileName.length <= 60) {
        return fileName;
      }
      const prefix = fileName.slice(0, 30);
      const suffix = fileName.slice(-30);
      return `${prefix}...${suffix}`;
    },
    callUser(uuid) {
      setTimeout(function () {
        callPerson(uuid, this.$router);
      }, 200);
      this.closeModal();
    },
    disableCallBtn(uuid) {
      let person = this.personInfo(uuid);
      return !!disableCallBtn(person);
    },
    getBorderByStatusFull(person, customCss) {
      return getBorderByStatusFull(person, customCss);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    closeModal() {
      resetSendGroupChatMessageModalEvent();
    },
  },
};
</script>


<style scoped lang="scss">
.borderRadius4 {
  border-radius: 4px !important;
}

.msgCardDark a {
  color: white!important;
}
.avatar-position {
  position: absolute;
  top: -10px;
  left: -10px;
}
.messageBorders{
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-radius: 4px !important;
}

.msg-username {
  padding: 8px;
}

.user-own-message{
  align-items: center;
}
</style>

