<template>
  <div>
    <v-text-field type="text" v-model="searchQuery" @click.stop @keydown.enter.stop.prevent 
      :bg-color="isDark ? '#1e1e1e' : '#fff'" autofocus hide-details variant="outlined" autocomplete="off"
      density="compact" :placeholder="$t('generics.searchMessages')">
      <template v-slot:append-inner>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn class="ml-2" icon density="compact" variant="text" v-on:click="clearSearch" v-bind="props">
              <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '16px' }" />
            </v-btn>
          </template>
          <span>{{ $t("generics.clearSearch") }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <v-list
      two-line
      v-if="
        messagesArray.length !== 0 ||
        Object.keys(state.FileTransferModal.files).length !== 0
      "
      :min-height="310"
      :class="{'py-0': isLateralPanel}"
      :height="isLateralPanel? '100%' :'370px' "
    > 
      <div
        v-if="isMobile"
        class="closeButton"
        @click="closeMenu()"
      >
        <v-btn icon>
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
      </div>
      
      <div class="vueScroll">
        <div id="messagesDropDown">
          <div v-if="Object.keys(state.FileTransferModal.files).length !== 0">
            <div v-for="(entry, key) in state.FileTransferModal.files" v-bind:key="key">
              <FileItemList :entry="entry" />
            </div>
          </div>
          <div v-for="(message, index) in filteredMessages" :key="index">
            <v-list-item :style="{ background: isLateralPanel ? '#464b4e' : '' }" @click="
              closeMenu();
            openNotification(message);
            " :key="index" v-if="
              (message && message.type !== 'message') ||
              (message.type === 'message' &&
                state.group[getDataUserMessage(message)])
            ">
            <template v-slot:default="{ }">
              <v-avatar v-if="!isLateralPanel" tile size="60" rounded="0" class="borderRadius4 cursorPointer"
                :style="getBorderByStatusFull(state.group[getDataUserMessage(message)], '2px')">
                <DefaultAvatarGrid :size="120" :userData="state.group[getDataUserMessage(message)]" v-if="
                  getAvatarForUuid(getDataUserMessage(message)) ==
                  'img/default_profile_picture.png'
                " :isLateralPanel="isLateralPanel"></DefaultAvatarGrid>
                <v-img v-if="
                  getAvatarForUuid(getDataUserMessage(message)) !=
                  'img/default_profile_picture.png'
                " class="mx-2" max-height="60" max-width="60" contain
                  :src="getAvatarForUuid(getDataUserMessage(message))"></v-img>
              </v-avatar>
              <v-avatar class="lateralPanelAvatar" v-if="isLateralPanel"
                :style="getBorderByStatusFull(state.group[getDataUserMessage(message)], '2px')">
                <v-img contain class="text-white align-end" height="80"
                  :src="getAvatarForUuid(getDataUserMessage(message))">
                </v-img>
              </v-avatar>
              <div class="mw150"
                :class="{ 'pl-2': !isLateralPanel, 'messagesContentLaterlPanel ma-4': isLateralPanel }">
                <v-list-item-title
                  v-text="message.header == 'group-message' ? getGroupNameForUuid(this.getGroupUuidFromMessageUuid(message.messageUUID)) : getNameForUuid(getDataUserMessage(message))"
                  :class="!isLateralPanel ? unreadNotification(message) : ''"
                  :style="{ color: isLateralPanel ? '#fff !important' : '', height: isLateralPanel ? '' : '' }"></v-list-item-title>
                <v-list-item-subtitle class="text--primary mt-2"
                  :class="isLateralPanel ? unreadNotification(message) : ''" v-text="getTextBody(message)"
                  :style="{ color: isLateralPanel ? '#fff !important' : '' }"></v-list-item-subtitle>
              </div>
              <div class="ml-0 actionPosition">
                <div class="text-caption mb-7" :class="isLateralPanel ? unreadNotification(message) : ''"
                  :style="{ color: isLateralPanel ? '#fff !important' : '' }"
                  v-text="formatNoticationDate(message.date)"></div>
                <div :class="isLateralPanel ? 'mt-2' : ''" class="d-flex justify-end" style="position: relative">
                    <DeleteBasket v-if="message.header !== 'group-message'" :indexData="message" :delFunction="deleteNotification" extraClasses="mr-4" fontSize="16"
                    prevent="true" />
                  <v-tooltip top v-if="
                    !isWaitingRoomUser &&
                    (message.type == 'message' ||
                      message.type == 'plannerEvent')
                  ">
                    <template v-slot:activator="{ props }">
                      <font-awesome-icon v-bind="props" class="mr-4" @click.stop.prevent="
                        closeMenu();
                      setShowModalSenMsg(
                        getDataUserMessage(message),
                        'message'
                      );
                      " :icon="['fal', 'paper-plane']" :style="{
                        color: isLateralPanel ? '#fff !important' : '',
                      }" />
                    </template>
                    <span>{{
                      $t("components.userProfile.sendAMessage")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="
                    message.type === 'call' &&
                    !isWaitingRoomUser &&
                    !isSentMessage(message)
                  ">
                    <template v-slot:activator="{ props }">
                      <font-awesome-icon v-bind="props" class="mr-4" :icon="['fal', 'phone']" @click.stop="
                        closeMenu();
                      disableCallBtn(message.creatorUUID)
                        ? false
                        : callUser(message.creatorUUID);
                      " :color="!disableCallBtn(message.creatorUUID)
                        ? setBorderByStatus(
                          state.group[message.creatorUUID]
                        )
                        : 'grey'
                        "></font-awesome-icon>
                    </template>
                    <span>{{
                      disableCallBtn(message.creatorUUID)
                        ? "Dieser Benutzer ist zur Zeit offline"
                        : $t("components.userProfile.callUser")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip location="top" v-if="message.type === 'file'">
                    <template>
                      <font-awesome-icon class="mr-4" style="pointer-events: none" :icon="['fal', 'download']"
                        color="grey-lighten-1" :style="{
                          color: isLateralPanel ? '#fff !important' : '',
                        }"></font-awesome-icon>
                    </template>
                    <span>{{
                      $t("components.downloadsDropdown.fileNotAvailable")
                    }}</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            </v-list-item>
          </div>
        </div>
      </div>
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" style="float: right; margin: 5px 5px 0px 0px" class="btnRed" icon variant="text"
            color="primary">
            <DeleteBasket indexData="" :delFunction="removeAllListedItemsEffect" extraClasses="" fontSize="20"
              prevent="false" />
          </v-btn>
        </template>
        <span>{{ $t("components.downloadsDropdown.removeAll") }}</span>
      </v-tooltip>
    </v-list>
  </div>

</template>
<script>
import { useStore } from "effector-vue/composition";
import striptags from "striptags";
import moment from "../../../sharedsrc/moment";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import {
  dispatchDeleteAllMessageEvent,
  dispatchDeleteAllSentMessageEvent,
  dispatchDeleteConversationEvent,
  messagesReceivedAndSent,
  groupMessagesToMe,
} from "../../effector/message";
import { setHistoryMessagesModalEvent, setSendGroupChatMessageModalEvent } from "../../effector/modals";
import { isMobile } from "../../lib/mobileUtil";
import store from "../../store";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
import { amInABridgeCall, callPerson, disableCallBtn } from "../../utils/calls";
import { hasPrivilege, isWaitingRoomUser } from "../../utils/privileges";
import { getStatusColorByUser } from "../../utils/status";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import FileItemList from "./fileItemList.vue";
import { groupChattingInfoStore } from "../../effector/users/groupChattingInfo";
export default {
  props: ["notificationType", "closeMenu", "menuOpen", "isLateralPanel"],
  components: { DefaultAvatarGrid, FileItemList, DeleteBasket },
  data() {
    const effector = {
      messagesToMe: messagesReceivedAndSent,
      groupMessagesSentToMe: groupMessagesToMe,
      groupUsesEffect: groupChattingInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      state: store.state,
      persisted: store.state.persisted,
      moment: moment,
      user: store.state.user,
      ownUUID: store.state.ownUUID,
      searchQuery: "",
      // Effector
      ...effector,
    };
  },
  watch: {
    menuOpen: {
      // immediate: true,
      deep: true,
      handler: function (value) {
        if (value && document.getElementById("messagesDropDown")) {
          setTimeout(() => {
            document
              .getElementById("messagesDropDown")
              .parentElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
          }, 250);
        }
      },
    },
  },
  methods: {
    getBorderByStatusFull(person, customCss) {
      return getBorderByStatusFull(person, customCss);
    },
    deleteNotification(message) {
      switch (message.type) {
        case "call":
        case "file":
          this.removeMessage(
            message.creatorUUID,
            message.messageUUID,
            undefined
          );
          break;
        case "message":
        case "plannerEvent":
          this.deleteFullConversation(this.getDataUserMessage(message));
          break;
        default:
          break;
      }
    },
    deleteFullConversation(userUUID) {
      dispatchDeleteConversationEvent(userUUID);
      this.closeMenu();
    },
    getTextBody(msg) {
      if (msg.header === 'group-message') {
        msg = {
          ...msg,
          body: `${store.getNameForUuid(msg.creatorUUID)}: ${msg.body}`
        };
      }

      switch (msg.type) {
        case "call":
          return this.$t("components.notificationsModal.missedCall");
        case "file":
          return `${this.$t("generics.file")}: ${msg.fileKey}`;
        case "message":
        case "plannerEvent":
          return this.getMessageBody(msg) || this.$t("generics.newMessage");
        default:
          return this.$t("generics.newMessage");
      }
    },
    setGroupInModal(groupUuid) {
      const group = this.groupUsesEffect.groups?.find(sgc => sgc.uuid === groupUuid) || {};
      const admin = group.members?.find((user) => user?.uuid === group.admin_uuid);
      store.state.ownModalGroup = admin?.uuid === this.state.ownUUID;
      store.state.groupOnModal = group;
      setSendGroupChatMessageModalEvent({
        show: true,
        groupData: group,
      });
    },
    getMessageBody(message) {
      if (this.isATranslateMessage(message)) {
        const keyTranslate = message.header.translateMessage.translateKey;
        let params = message.header.translateMessage.params || null;
        let translate = params
          ? this.$t(keyTranslate, params)
          : this.$t(keyTranslate);
        return translate;
      } else {
        return this.fixHtml(message.body);
      }
    },
    isATranslateMessage(message) {
      if (
        message.header &&
        message.header.translateMessage &&
        message.header.translateMessage.translateKey
      ) {
        return true;
      } else {
        return false;
      }
    },
    fixHtml(body) {
      const idx = `${body}`.indexOf("<br");
      return striptags(idx !== -1 ? body.substring(0, idx) : body);
    },
    openNotification(message) {
      if (message.header === "group-message") {
        const groupUuid = this.getGroupUuidFromMessageUuid(message.messageUUID);
        if (groupUuid) {
          this.setGroupInModal(groupUuid);
        }
      } else if (message.type === "call") {
        this.setShowModalNotify(message.creatorUUID, {
          id: message.messageUUID,
          type: "call",
          notification: message.info,
          date: message.date,
        });
      } else if (message.type == "message" || message.type === "plannerEvent") {
        store.state.searchQuery = this.searchQuery;
        const userUUID = this.getDataUserMessage(message);
        setHistoryMessagesModalEvent(userUUID);
      }
    },
    removeAllListedItemsEffect() {
      dispatchDeleteAllMessageEvent();
      dispatchDeleteAllSentMessageEvent();
      this.removeAllFiles();
    },
    removeAllFiles: function (...args) {
      if (this.messagesArray.length > 0) {
        this.messagesArray.forEach((message) => {
          if (message.type == "call" || message.type == "file") {
            this.removeMessage(
              message.creatorUUID,
              message.messageUUID,
              undefined
            );
          }
        });
      } else if (Object.keys(this.state.FileTransferModal.files).length !== 0) {
        for (const key in this.state.FileTransferModal.files) {
          if (
            Object.hasOwnProperty.call(this.state.FileTransferModal.files, key)
          ) {
            const element = this.state.FileTransferModal.files[key];
            if ((element.incoming && element.complete) || (element.outgoing && element.complete)) {
              const uuid = element.uuid;
              const fileName = element.fileName;
              const msgUUID = element.msgUUID;
              store.delFileTransferFor(uuid, fileName);
              this.removeMessage(uuid, msgUUID, undefined);
            }
          }
        }
      }
    },
    formatNoticationDate(date) {
      if (this.isLateralPanel) {
        return this.moment(date).format("DD.MM.YY, H:mm");
      } else {
        return (
          this.moment(date).format("DD.MM.YYYY, H:mm") +
          " " +
          this.$t("components.meetingLineModal.hours")
        );
      }
    },
    setShowModalSenMsg(uuid, type = "") {
      this.closeMenu();
      setHistoryMessagesModalEvent(uuid);
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    getUserIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
    removeMessage: function (...args) {
      store.removeMessage(...args);
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getGroupNameForUuid: function (groupUuid) {
      return (this.groupUsesEffect.groups?.find(sgc => sgc.uuid === groupUuid) || {}).groupName;
    },
    getGroupUuidFromMessageUuid: function (messageUuid) {
      return (this.groupUsesEffect.groups?.find(sgc => sgc.messages.some(id => id == messageUuid)) || {}).uuid;
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function (...args) {
      store.setShowModalNotify(...args);
    },
    settotalNotification: function (...args) {
      store.settotalNotification(...args);
    },
    disableCallBtn(uuid) {
      let person = this.state.group[uuid];
      return disableCallBtn(person);
    },
    isSentMessage(message) {
      if (message && message.creatorUUID == this.ownUUID) {
        return true;
      } else {
        return false;
      }
    },
    getDataUserMessage(message) {
      if (message.header === "group-message") {
        return message.creatorUUID;
      } 
      if (this.isSentMessage(message)) {
        return Object.keys(message.users)[0];
      } else {
        return message.creatorUUID;
      }
    },
    unreadNotification(message) {
      const ownUUID = this.state.ownUUID;
      if (
        (message.type === "message" || message.type === "plannerEvent") &&
        message.users[ownUUID] &&
        !message.users[ownUUID].read
      ) {
        if (this.isLateralPanel) {
          return "font-weight-bold"
        } else {
          return "font-weight-bold textBlue";
        }
      } else {
        return "";
      }
    },
    clearSearch() {
      this.searchQuery = "";
    },
  },
  computed: {
    getGroupUsesEffect() {
      return this.groupUsesEffect;
    },
    hasPrivilege() {
      return hasPrivilege(this.ownUUID);
    },
    amInABridgeCall() {
      return amInABridgeCall(this.ownUUID);
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    getEmptyText: function () {
      if (this.notificationType == "missedCalls") {
        return this.$t("components.notificationDropDown.noMissedCalls");
      } else if (this.notificationType == "incomingTickets") {
        return this.$t("components.notificationDropDown.noNewTasks");
      } else if (this.notificationType === "messages") {
        return this.$t("components.notificationDropDown.noNewMessages");
      } else if (this.notificationType === "uploads") {
        return this.$t("components.notificationDropDown.noNewFiles");
      } else {
        return this.$t("components.notificationDropDown.noNewNotifications");
      }
    },
    messagesArray: function () {
      if (this.notificationType === "message") {

        const messageUsers = [];
        const messagesAndCalls = [];
        const messageGroups = [];

        for (let message of this.messagesToMe) {
          if (message.creatorUUID == this.state.ownUUID && message.type == 'plannerEvent') continue; //exclude messages sent from conferences

          if (message.header === "group-message") {

            const groupUuid = this.getGroupUuidFromMessageUuid(message.messageUUID);
            if (groupUuid) {
              if (messageGroups.includes(groupUuid)) continue;
              messageGroups.push(groupUuid);
            } else {
              continue;
            }
            messagesAndCalls.push(message);

          } else {
            // Direct messages
            const usersStr = this.isSentMessage(message)
              ? Object.keys(message.users || {}).join()
              : message.creatorUUID;
            if (usersStr) {
              if (messageUsers.includes(usersStr)) continue;
              messageUsers.push(usersStr);
            }
            messagesAndCalls.push(message);
          }
        }

        for (let userUUID in store.state.persisted.userMessages.messages) {
          const userMessages =
            store.state.persisted.userMessages.messages[userUUID];
          if (!userMessages) continue;
          for (let msgUUID in userMessages) {
            const message = userMessages[msgUUID];
            if (message.info.notificationType === "file") {
              const fileKey = userUUID + "_" + message.info.fileName;
              const user = {};
              user[this.ownUUID] = {
                read: message.read,
                userUUID: this.ownUUID,
              };
              const tmpData = {
                body: "New file",
                creatorUUID: userUUID,
                date: message.date,
                header: "New file",
                messageUUID: message.uuidMessage,
                type: "file",
                fileKey: fileKey,
                users: user,
              };
              if (!store.state.FileTransferModal.files[fileKey]) {
                messagesAndCalls.push(tmpData);
              } else {
                store.state.FileTransferModal.files[fileKey].msgUUID = msgUUID;
              }
            }
          }
        }
        const temporalArray = messagesAndCalls;
        temporalArray.forEach((element, index) => {
          if (this.state.group[element.creatorUUID] == undefined) {
            messagesAndCalls.splice(index, 1);
          } else {
            const receivedUserMsgs = Object.keys(element.users);
            for (let i = 0; i < receivedUserMsgs.length; i++) {
              const userUUID = receivedUserMsgs[i];
              if (this.state.group[userUUID] === undefined) {
                messagesAndCalls.splice(index, 1);
              }
            }
          }
        });

        return messagesAndCalls.sort(function (a, b) {
          const aDate = new Date(a.date);
          const bDate = new Date(b.date);
          return aDate > bDate
            ? -1
            : aDate < bDate
              ? 1
              : (a.uuid || "").localeCompare(b.uuid || "");
        });
      }
    },
    filteredMessages() {

      if (this.notificationType !== "message") {
        return this.messagesArray
      }

      const query = (this.searchQuery || "").toLowerCase().trim()


      if (!query) {
        return this.messagesArray
      }

      const matchedConversationUUIDs = new Set()

      for (const fullMsg of this.messagesToMe) {
        const body = (fullMsg.body || "").toLowerCase()
        if (body.includes(query)) {
          matchedConversationUUIDs.add(this.getDataUserMessage(fullMsg))
        }
      }

      const output = this.messagesArray.filter((lastMsg) => {
        return matchedConversationUUIDs.has(this.getDataUserMessage(lastMsg))
      })

      return output;
    },
  },
};
</script>
<style lang="scss">
.v-theme--dark.bg-error {
  background-color: rgb(255, 0, 11, 1) !important;
  // color: white !important;
}
</style>
<style scoped lang="scss">
.v-theme--light {
  .textBlue {
    color: #2a3133;
  }
}

.v-theme--dark {
  .text--primary {
    color: white;
    opacity: 1;
  }

  .v-list-item-title,
  .v-list-item-subtitle {
    color: white;
    opacity: 1;
    font-weight: 300;
  }
}

.mw150 {
  max-width: 150px;
}

.actionPosition {
  position: absolute;
  top: 3;
  right: 5px;
}

.closeButton {
  right: 0;
  margin-right: 5px;
  text-align: end;
}

.borderRadius4 {
  border-radius: 4px !important;
}

.messagesContentLaterlPanel {
  position: relative;
  top: -6px;
}

.lateralPanelAvatar.v-avatar {
  border-radius: 5px !important;
}

.lockMessageIcon {
  position: absolute;
  right: 28px;
  top: -6px;
}

.btnRed {
  border-radius: 4px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.vueScroll {
  overflow: auto;

  .__view {
    width: unset !important;
  }
}

.divNotNotis {
  height: 40px;
  padding: 9px;

  .notNotifications {
    color: var(--form-text);
  }
}
</style>