<template>
    <v-list max-width="320">
      <GroupList :groups="sortedGroups" :section="'guests'" typeData="groupchatting"/>
    </v-list>
  </template>
  
  <script>
  import store from "../../store";
  import GroupList from "./groupList.vue";
  
  export default {
    props: [
      "groups",
      "sortedGroups",
      "active",
      "isMobile",
      "setCurrentContentVisile",
    ],
    components: {
      GroupList
    },
    data: () => ({
      state: store.state
    }),
  };
  </script>
  
  <style scoped lang="scss">
  .v-list {
    padding: 0;
  }
  .v-list-item__icon {
    margin-right: 8px !important;
  }
  .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0;
  }
  .v-list-item .v-list-item__title {
    line-height: 2.5 !important;
  }
  .v-theme--dark .listColor {
    color: white !important;
  }
  .v-theme--light .listColor {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  </style>
  
  