import {addGroupChatDomain, sendGroupChatMessageDomain } from './domain';

export const setSendGroupChatMessageModalEvent = sendGroupChatMessageDomain.createEvent('setSendGroupChatMessageModal');
export const setFinishGroupChatEvent = sendGroupChatMessageDomain.createEvent('setFinishGroupChatEvent');

export const resetSendGroupChatMessageModalEvent = sendGroupChatMessageDomain.createEvent('resetSendGroupChatMessageModal');
export const addGroupChatEvent = addGroupChatDomain.createEvent('addGroupChatEvent');
export const updateGroupChatEvent = addGroupChatDomain.createEvent('updateGroupChatEvent');
export const getGroupMessagesByGroupUIdEvent = addGroupChatDomain.createEvent('getGroupMessagesByGroupUIdEvent');

