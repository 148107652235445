<template>
  <v-card flat class="notification-box pa-2 rounded-sm bg-primary" style="height: 232px;">
    <!-- Card title with the name and delete all button -->
    <v-card-title class="pb-0 font-weight-regular d-flex justify-space-between align-center">
      <div>
        {{ $t('components.socialMedia.notifications') }}
      </div>
      <div class="mr-4">
        <!-- Button to delete all notifications -->
        <v-btn variant="text" icon @click="deleteAllNotifications" class="">
          <v-icon>mdi-delete-empty</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text style="max-height: 155px;">
      <!-- Notifications list with a scrollable area -->
      <v-list style="max-height: 150px; overflow-y: auto;">
        <v-list-item-group v-if="notificationData.length">
          <v-list-item v-for="(notification, index) in notificationData" :key="index" class="py-0 px-0 listHeightNotification">
            <v-list-item-content @click="openPreview(notification)" class="pointer w100">
              <!-- Notification text (action description) -->
              <v-list-item-title>
                {{ formatDate(notification.createdAt) }} {{ getNotificationText(notification) }}
              </v-list-item-title>
              <!-- Notification timestamp -->
              <!-- <v-list-item-subtitle>
                {{ formatDate(notification.createdAt) }}
              </v-list-item-subtitle> -->
            </v-list-item-content>

            <!-- Trash button to delete individual notification -->
            <v-list-item-action>
              <v-btn class="detelebtnNotification" variant="text" icon @click="deleteNotification(notification.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>

        <!-- Message for when there are no notifications -->
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-title>{{ $t('components.socialMedia.noNotifications') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../../store";
import moment from "../../../../../sharedsrc/moment";
import { isMobile } from "../../../../lib/mobileUtil";

export default {
  props: [],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      notificationData: [], // Array to store notifications
      notificationInterval: null, // Store the interval ID for refreshing notifications
    };
  },
  watch: {},
  mounted() {
    this.getNotifications(); // Fetch notifications when the component is mounted
    this.startNotificationInterval(); // Start an interval to refresh notifications every minute
  },
  unmounted() {
    this.clearNotificationInterval(); // Clear the interval when the component is unmounted
  },
  methods: {
    openPreview(notification) {
      const dataToSend = {
        show: true,
        postId: notification.postId
      }
      EventBus.$emit('previewPost', dataToSend)
    },
    // Method to fetch notifications from the API
    getNotifications() {
      fetch(`/api/socialmedia/notifications?userId=${this.ownUUID}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
        .then(async (response) => {
          const result = await response.json();
          if (!result.error) {
            this.notificationData = result.notifications.reverse(); // Store fetched notifications in data
          } else {
            console.error('Error fetching notifications', result.error); // Handle error in fetching
          }
        })
        .catch((error) => {
          console.error('API call error', error); // Handle network or other API errors
        });
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    // Get notification text based on its action
    getNotificationText(notification) {
      const userName = `${this.getTitelByUuid(notification.createdBy)} ${this.getNameByUuid(notification.createdBy)}`
      if (notification.postId && notification.action === "LIKE") {
        return this.$t('components.socialMedia.notificationsText.likePost', [userName || '']);
      } else if (notification.postId && notification.action === "COMMENTED") {
        return this.$t('components.socialMedia.notificationsText.commentPost', [userName || '']);
      } else if (notification.commentId && notification.action === "LIKE") {
        return this.$t('components.socialMedia.notificationsText.likeComment', [userName || '']);
      } else if (notification.commentId && notification.action === "COMMENTED") {
        return this.$t('components.socialMedia.notificationsText.commentComment', [userName || '']);
      } else {
        return '';
      }
    },

    // Format the notification date to a more readable format
    formatDate(createdAt) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedDate = moment(createdAt).tz(userTimeZone).format('DD.MM.YYYY HH:mm');
      return formattedDate
    },

    // Start a recurring fetch for notifications every minute
    startNotificationInterval() {
      this.notificationInterval = setInterval(() => {
        this.getNotifications(); // Re-fetch notifications every 5 minutes
      }, 300000);
    },

    // Clear the recurring fetch interval when the component is unmounted
    clearNotificationInterval() {
      if (this.notificationInterval) {
        clearInterval(this.notificationInterval); // Stops the interval
      }
    },

    // Delete an individual notification by its ID
    deleteNotification(notificationId) {
      fetch('/api/socialmedia/delete-notification', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id: notificationId }) // Send the notificationId in the request body
      })
        .then(async (response) => {
          const result = await response.json();
          if (result.success) {
            // Remove the deleted notification from the list
            this.notificationData = this.notificationData.filter(
              (notification) => notification._id !== notificationId
            );
          } else {
            console.error('Error deleting notification', result.error); // Handle error in deleting
          }
        })
        .catch((error) => {
          console.error('API call error', error); // Handle network or other API errors
        });
    },

    // Delete all notifications, one by one
    deleteAllNotifications() {
      // Assuming we have an array of notification IDs (notificationIds)
      const notificationIds = this.notificationData.map(notification => notification.id); // Collect IDs from the notificationData
      // Function to delete a single notification
      const deleteNotificationById = (id) => {
        return fetch('/api/socialmedia/delete-notification', {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id }) // Send each ID in the request body
        })
          .then(async (response) => {
            const result = await response.json();
            // if (result.success) {
            //   console.log(`Notification with ID ${id} deleted successfully.`);
            // } else {
            //   console.error(`Error deleting notification with ID ${id}: ${result.error}`);
            // }
          })
          .catch((error) => {
            console.error('API call error for notification ID ' + id, error);
          });
      };

      // Loop through each notification ID and delete it one by one
      Promise.all(notificationIds.map(id => deleteNotificationById(id)))
        .then(() => {
          // Clear notifications after deletion
          this.notificationData = []; // Clear all notifications from the UI
        })
        .catch((error) => {
          console.error('Error deleting all notifications', error); // Handle any error in deleting
        });
    }
  },
  computed: {
    // Check for dark mode setting
    isDarkMode() {
      return this.state.persisted.isDark;
    },
  },
};
</script>

<style scoped lang="scss">
/* Styling to make list items transparent */
.v-list {
  background-color: transparent !important;
  /* Remove background color */
  color: white;
  /* Ensure text is white */
}

.v-list-item {
  background-color: transparent !important;
  /* Remove background color */
}

.v-list-item-group {
  background-color: transparent !important;
  /* Remove background color */
}
.detelebtnNotification{
  height: 25px!important;
}
.listHeightNotification{
  min-height: 25px!important;
}
</style>
