<template>
    <v-tooltip location="top">
        <template v-slot:activator="{ props }">
            <div
            style="cursor:pointer; height: 26px; width: 26px; margin-left: 10px"
            v-bind="props"
            :class="left-group"
            :style="{ fontSize: `${parseInt(fontSize) + 'px'}` }"
            @click="handleClick($event)"
            >
            <v-img src="/img/icon-logout-navbar.svg" style="widows: 20px;"/>
        </div>
</template>
<span>{{
    text
  }}</span>
</v-tooltip>
</template>

<script>
    export default {
  props: [
    "indexData",
    "leftFunction",
    "extraFunction",
    "extraFunctionParams",
    "extraClasses",
    "fontSize",
    "prevent",
    "color",
    "organigramTT"
  ],
  components: {},
  data() {
    return {
      deleteActive: false,
      isDeleting: false,
    };
  },
  methods: {
    async handleClick(e) {
      if (this.prevent) {
        e.stopPropagation();
        e.preventDefault();
      }
      
        if (this.indexData !== "") {
          if (Array.isArray(this.indexData)) {
            await this.leftFunction(...this.indexData);
          } else {
            await this.leftFunction(this.indexData);
          }
        } else {
          await this.leftFunction();
        }
        if (this.extraFunction) {
          if (
            this.extraFunctionParams &&
            Array.isArray(this.extraFunctionParams)
          ) {
            this.extraFunction(...this.extraFunctionParams);
          } else {
            this.extraFunction(this.extraFunctionParams);
          }
        }
    },
  },
  computed: {
    text() {
      return this.organigramTT || this.$t("components.groups.confirmLeaving")
    }
  },
};
</script>
