
<template>
    <v-card class="mx-auto vCardSearch" width="100%" flat>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-list-item-title v-bind="props" class="itemTitleSearch primary--text-sidepanel">
              <v-text-field
                density="compact"
                :color="'white'"
                :label="''"
                class="pb-2 primary--text-sidepanel mainInputSearch mx-0 py-0 inputSearch"
                hide-details="true"
                autofocus
                @blur="handlerBlur"
                @keydown.space="handleSpaceBar"
                :model-value="state.searchGroupTerm" @update:model-value="state.searchGroupTerm = $event"
                ref="searchInput"
              ></v-text-field>
            </v-list-item-title>
          </template>
          <span>{{$t("generics.searchGroup")}}</span>
        </v-tooltip>
      <v-btn
        v-if="showResetButton"
        icon
        density="comfortable"
         variant="text"
        class="closeButton closeButtonSearch"
        @click.stop.prevent="resetSearch()"
      >
        <font-awesome-icon
          class="primary--text-sidepanel"
          :icon="['fal', 'times']"
          :style="{ fontSize: '16px' }"
        />
      </v-btn>
    </v-card>
  </template>
  <script>
  import store, { EventBus } from "../../store";
  
  export default {
    data() {
      return {
        state: store.state,
      };
    },
    mounted() {
      EventBus.$on("sendOpenCloseSearchField", this.resetSearch);
    },
    unmounted(){
      EventBus.$off("sendOpenCloseSearchField", this.resetSearch);
    },
    computed: {
      isDarkMode() {
        return this.state.persisted.isDark;
      },
      showResetButton() {
        if (this.state.searchGroupTerm.length > 0) {
          return true;
        }
        return false;
      },
    },
    methods: {
      handleSpaceBar(event) {
        event.stopPropagation();
      },
      handlerBlur() {
        if (
          this.state.searchGroupTerm === "") {
          this.state.showGroupSearch = false;
        }
      },
      resetSearch() {
        this.state.searchGroupTerm = "";
        this.state.showGroupSearch = false;
      },
    },
  };
  </script>
  
  <style lang="scss">
  .mainInputSearch {
    width: calc(100% - 7px);
    margin-top: 8px !important;
    margin-left: 2px !important;
  }
  .mainInputSearch {
    .v-text-field--solo .v-input__control {
      min-height: 30px !important;
    }
  }
  .titleGroup {
    .v-icon {
      font-size: 14px !important;
    }
  }
  
  .v-list {
    padding: 0;
  }
  .v-list-item__icon {
    margin-right: 8px !important;
  }
  
  .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0;
  }
  .vCardSearch {
    & .itemTitleSearch {
      width: calc(100% - 36px) !important;
    }
  }
  </style>
  <style scoped lang="scss">
  .vCardSearch {
    display: flex;
    background: transparent !important;
    & .closeButtonSearch {
      position: relative;
      top: 10px;
      right: -7px;
    }
  }
  </style>