import { sendGroupChatMessageDomain } from './domain';

import { setSendGroupChatMessageModalEvent,setFinishGroupChatEvent, resetSendGroupChatMessageModalEvent, addGroupChatEvent,updateGroupChatEvent, getGroupMessagesByGroupUIdEvent } from './events';
import {addGroupChatting, getGroupMessagesByGroupUId } from '../../../lib/wsMsg';

export const sendGroupChatMessageModalStore = sendGroupChatMessageDomain.createStore({ show: false, groupData:{}, messageUIDs: [] }, 
  { name: 'sendGroupChatMessageModal' });

const applyAddGroupChat = async (payload) => {
    const addedGroup = await addGroupChatting(payload);
    sendGroupChatMessageModalStore.setState({
      ...sendGroupChatMessageModalStore.getState(),
      groupData: addedGroup ,
    });
};
sendGroupChatMessageModalStore.on(updateGroupChatEvent, (state, payload) => {
  const updatedGroups = payload;
  if(!updatedGroups) return state;
  const matchedGroup = updatedGroups.find(group => group.uuid === state.groupData.uuid);
  if(!matchedGroup) return state;
  return { ...state, groupData: matchedGroup };
});

sendGroupChatMessageModalStore.on(addGroupChatEvent, (state, payload) => {
  applyAddGroupChat(payload);
  return { ...state, messageUIDs: [] };
});

const updateGroupMessages = async (payload) => {
  const messages = await getGroupMessagesByGroupUId(payload);
  // Directly update the store with a new object
  sendGroupChatMessageModalStore.setState({
    ...sendGroupChatMessageModalStore.getState(),
    messageUIDs: Object.values(messages || []),
  });
};

sendGroupChatMessageModalStore.on(getGroupMessagesByGroupUIdEvent, (state, payload) => {
  updateGroupMessages(payload);
  return { ...state, messageUIDs: [] }; // Initial loading state
});

sendGroupChatMessageModalStore.on(setFinishGroupChatEvent, (state, payload) => {
  console.log('setFinishGroupChatEvent', payload);
});
sendGroupChatMessageModalStore.on(setSendGroupChatMessageModalEvent, (state, payload) => {
  return payload;
});
sendGroupChatMessageModalStore.reset(resetSendGroupChatMessageModalEvent);
