<template>
  <v-dialog  
  :model-value="showModalMessage"
  @update:model-value="$emit('update:showModalMessage', $event)"
  persistent
  max-width="720"
  @keydown.esc="closeModal"
  >
    <div>
      <v-card>
        <HeaderModal 
          :groupMembers = "groupMembers()" 
          :titleModal="titleModal"
          :handleAction="setShowSettingWindow"
          :closeModalFunction="closeModal"
          :groupData = "groupData" 
          :openChat = "openChat"
          :deleteMessagesFunction="deleteGroupMessages"
          :leaveFunction="leaveGroup"
          />

        <v-divider></v-divider>

        <!-- Empty Content Area -->
        <v-row
          v-if="openChat"
            flex
            :style="{ width: '100%', margin: 0 }"
            :class="{ heightMobileMessages: isMobile }"
          >
            <v-col class="col-12">
              <div>
                <div
                  class="contentMessagesIsReply"
                  ref="messageContainer"
                  style="height: 493px; overflow-y: auto; overflow-x: hidden;"
                >
                  <GroupChatHistoryEffector :members="totalMembers()" :messages="messageHistory" />
                </div>
                <div class="px-6">
                  <v-divider></v-divider>
                </div>
              </div>
            </v-col>
        </v-row>
        <v-row v-else class="px-5" style="min-height: 493px; display: flex; align-items: start; justify-content: center;">
          <v-col cols="12" class="py-5 pl-0 my-0"> 
            <p class="text-left mb-1 text-subtitle-2">
              {{ $t("components.groupChatModal.groupName") }}
            </p>
            <v-col :cols="isMobile ? 8 : 12" class="p-0">
              <v-text-field
                variant="outlined"
                density="compact"
                :model-value="groupName" @update:model-value="groupName = $event"
                class="my-0"
                style="height: 50px;"
                maxlength="20"
                type="text"
                :rules="[ 
                          v => (v && v.length >0 && v.length <20) || $t('components.groupChatModal.groupNameMinMaxLength'),
                          v => !/[<>]/.test(v) || $t('components.groupChatModal.groupNameValidation')]"
              ></v-text-field>
            </v-col>
            <p class="text-left mb-1 text-subtitle-2">
              {{ $t("components.groupChatModal.addMember") }}
            </p>
            <v-col :cols="isMobile ? 8 : 12" class="p-0">
              <v-autocomplete
                ref="autocompleteAddUsersGroup"
                v-model="selectedMembers"
                :search="searchQuery"
                clearable
                density="compact"
                hide-details
                chips
                :label="$t('components.groupChatModal.selectMembers')"
                :no-data-text="
                  !isGroupFull
                    ? $vuetify.noDataText
                    : $t('components.groupChatModal.limitUsers')"
                :items="fillDropDown"
                item-title="name"
                item-value="uuid"
                multiple
                variant="outlined"
                @update:search="val => searchQuery = val"
                @update:modelValue="handleSelectionChange"
              >
              <template v-slot:chip="{ props, item }">
                  <v-chip
                    v-bind="props"
                    closable
                    @click:close="removeMember(item.raw.uuid)"
                  >
                    {{ item.title }}
                  </v-chip>
              </template>
                <template v-slot:prepend-item>
                  <v-list-item @click="toggleSelectAll" class="d-flex align-center">
                    <template v-slot:prepend>
                      <v-checkbox
                        :model-value="allSelected"
                        hide-details
                        @update:model-value="toggleSelectAll"
                      ></v-checkbox>
                    </template>
                    <v-list-item-title>{{$t('generics.selectAll')}}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-col>
        </v-row>

        <v-card 
          v-if="openChat"
            :class="isDark ? 'footersBackgroundMessagesDark' : 'footersBackgroundMessages'"
            class="py-2 pl-4 pr-2 footerModal">
          <v-row class="pl-2 btns w-100 mx-0 my-0">
            <input type="file" multiple ref="fileInput" class="d-none" @change="handleFileUpload($event)" />
            <v-text-field :model-value="replyMessage" @update:model-value="replyMessage = $event"
              class="attachment-input" @keyup.enter="
                replyMessage.trim().length > 0 ? sendReply() : null
                " :bg-color="isDark ? '#1e1e1e' : '#fff'" autofocus hide-details variant="outlined"
              @keydown="handleKeydown"
              :maxlength="(!replyMessage.trim().length && 960) || (replyMessage.match(/(https?:|www\.)/i) ? 2048 : 480)"
              autocomplete="off" density="compact" :placeholder="$t('components.notificationsModal.reply')">
              <template v-slot:prepend-inner>
                <div class="attachments-wrapper">
                  <div v-for="file in selectedFiles" :key="file.id" class="attachment-container">
                    <v-icon :icon="getIconForMimeType(file.type)" color="grey-darken-2" size="40" />
                    <button v-if="file.status === 'pending'" class="remove-file-btn" @click="removeFile(file.id)"
                      aria-label="Remove attachment">
                      <font-awesome-icon :icon="['fal', 'times']" />
                    </button>

                    <button v-else-if="file.status === 'uploading'" class="remove-file-btn" aria-label="Uploading"
                      disabled>
                      <font-awesome-icon :icon="['fal', 'spinner']" spin style="color: blue"  />
                    </button>

                    <button v-else-if="file.status === 'success'" class="remove-file-btn" aria-label="Upload successful"
                      disabled>
                      <font-awesome-icon :icon="['fal', 'check']" style="color: green" />
                    </button>
                  </div>
                </div>
              </template>
              <template v-slot:append-inner>
                <v-btn class="ml-2" icon density="compact" variant="text" v-on:click="triggerFileInput"
                  :disabled="isUploading">
                  <font-awesome-icon :icon="['fal', 'file']" :style="{ fontSize: '20px' }" />
                </v-btn>
                <v-btn class="ml-2" @click="sendReply()" icon density="compact" variant="text"
                  :disabled="replyMessage.trim().length === 0 && selectedFiles.length === 0 || isUploading">
                  <template v-if="isUploading">
                    <font-awesome-icon :icon="['fal', 'spinner']" spin
                      :style="{ fontSize: '20px', color: 'primary' }" />
                  </template>
                  <template v-else>
                    <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" color="primary" />
                  </template>
                </v-btn>
              </template>
            </v-text-field>
            <v-btn rounded="5px" color="primary" style="border-radius: 4px; min-width: 45px;margin-top: 2px;" icon tile
              variant="outlined" density="comfortable" class="ml-3  mr-2 greyBtn" @click="closeModal()">
              <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
              <!--{{ $t("generics.cancel") }}-->
            </v-btn>
          </v-row>
        </v-card>

        <FooterModal v-else :closeModalFunction="closeModal" :showCancel="hasGroupData" :showDelete="shouldDisplayDeleteGroupButton" :deleteFunction="deleteGroup">
          <v-spacer></v-spacer>
        </FooterModal>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>

import HeaderModal from "../modal/modalComponents/groupHeaderModal.vue";
import FooterModal from "../modal/modalComponents/groupFooterModal.vue";
import { basicView, hasPrivilege, isAdmin } from "../../utils/privileges";
// import MessageHistoryEffector from "../content/messageHistoryEffector.vue";
import GroupChatHistoryEffector from "../content/groupChatHistoryEffector.vue";

import { isMobile } from "../../lib/mobileUtil";
import store, {EventBus} from "../../store";
import { useStore } from "effector-vue/composition";

import { sendGroupChatMessageModalStore, resetSendGroupChatMessageModalEvent, historyMessagesModalStore,addGroupChatEvent, getGroupMessagesByGroupUIdEvent } from "../../effector/modals";
import {
  groupMessagesToMe,
  newMessageEvent,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent,
  getMessageHistoryByGroup,
  dispatchDeleteMessageEvent,
} from "../../effector/message";

import { deleteGroupChatEvent, leaveGroupEvent } from "../../effector/group";
import {
  dispatchErrorAlert,
} from "../../effector/alerts";

export default {
  components: { HeaderModal, FooterModal, GroupChatHistoryEffector, 
    // MessageHistoryEffector 
  },
  data() {
    const effector = {
      showModal: historyMessagesModalStore,
      messages: groupMessagesToMe,
      showSendGroupChatMessageModalStore: sendGroupChatMessageModalStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      selectedMembers: [],
      openChat: false,
      state: store.state,
      isMobile: isMobile(),
      selectedFiles: [],
      isUploading: false,
      ownUUID: store.state.ownUUID,
      groupName: "",
      groupMemberUUIDs: [],
      rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
      messageHistory: [],
      replyMessage: "",
      searchQuery: "",
      // Effector
      ...effector,
    };
  },
  mounted: function () {
    if (!this.isWaitingRoomUser) {
      this.updateMessageParams(this.messageHistory);
    }
    this.scrollToBottom();
  },
  methods: {
    deleteGroupMessages(){
      for (const groupMessage of this.groupData.messages){
        dispatchDeleteMessageEvent(groupMessage)
      }
    },
    deleteGroup(){
      this.deleteGroupMessages();
      deleteGroupChatEvent(this.groupData.uuid);
      this.closeModal();
      EventBus.$emit("groupListChanged");
    },
    leaveGroup(newAdminUuid = null){
      leaveGroupEvent([this.groupData.uuid, this.ownUUID, newAdminUuid]);
      this.closeModal();
      EventBus.$emit("groupListChanged");
    },
    handleSelectionChange() {
      this.searchQuery = '';
    },
    removeMember(uuidToRemove) {
      this.selectedMembers = this.selectedMembers.filter(
        uuid => uuid !== uuidToRemove
      );
    },
    totalMembers(){
      return this.openChat ? this.groupData.members.map(member => member.user) : [];
    },
    groupMembers(){
      return this.openChat ? this.groupData.members.filter(member => !member.isRemoved).map(member => member) : null;
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedMembers = [];
      } else {
        this.selectedMembers = this.fillDropDown.map(
              (member) => member.uuid
            );
      }
    },
    setShowSettingWindow(isShow) {
      if(this.groupData?.groupName){
        this.groupName = this.groupData.groupName;
        this.selectedMembers = this.getSelectedDropDownMenu(this.groupData.members.filter(member => !member.isRemoved).map(member => member.user));
        this.openChat = !isShow;
      }
    },
    getSelectedDropDownMenu(users) {
      let result;
      const tmpUsersArray = [];
      // get team members
      let data;
      for (const key in users) {
        tmpUsersArray.push(users[key].uuid);
        result = tmpUsersArray;
      }
      if (!this.isGroupFull) {
        return result;
      } else {
        return [];
      }
    },
    closeModal(action) {
      if (action === "ok") {
        if (this.groupName) {
          if (this.selectedMembers?.length) {
            const selectedUsers = this.selectedMembers.map(
              (member) => member
            );
            if(this.hasGroupData){
              addGroupChatEvent({
                groupUUID: this.groupData.uuid,
                groupName: this.groupName,
                members: selectedUsers,
              });
            }else{
              addGroupChatEvent({
                groupName: this.groupName,
                members: selectedUsers,
                messages: [],
                adminUUID: store.state.ownUUID,
              });
            }
            EventBus.$emit("groupListChanged");
          }
        }
      } else if (action === "cancel"){
        this.openChat = true;
      }else{
        this.closeModalMsg();
      }
      this.messagetext = "";
    },
    initSettingInputs() {
      this.groupName = "";
      this.selectedMembers = [];
    },
    closeModalMsg() {
      this.initSettingInputs();
      resetSendGroupChatMessageModalEvent();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer?.$el || this.$refs.messageContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
          
        }
      });
    },
    addMember(event) {
      if (event) {
        const selected = event;
        let selectedUsers = [];
        if (selected) {
          if (selected.length > 0) {
            selected.forEach((element) => {
              selectedUsers.push(this.dataComboboxAllUsers[element].user.uuid);
              selectedUsers.push(element.user.uuid);
            });
          }
        }
        this.groupMemberUUIDs = selectedUsers;
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    handleSelection(event) {
      if (this.isGroupFull) {
        this.closeAutocomplete(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.addMember(event);
      }
      this.$nextTick(() => {
        if (this.$refs.autocompleteAddUsersGuests) {
          this.$refs.autocompleteAddUsersGuests.inputValue = ''; // Clear internal input
          setTimeout(() => {
            this.closeAutocomplete(true); // true close menu and give focus
          }, 100);
        }
      });
      // this.selectedDropDowItem = [];
    },
    closeAutocomplete(focusBack=false) {
      // Access the component reference and close the menu
      if (this.$refs.autocompleteAddUsersGroup?.blur) {
        this.$refs.autocompleteAddUsersGroup.blur();
      }
      // Return focus to input after closing autocomplete menu (if required)
      if (focusBack){
        setTimeout(() => {
          this.$refs.autocompleteAddUsersGroup?.focus();
        }, 50);
      }
    },
    async sendReply() {
      const userToSendMsg = {};
      this.groupMemberUUIDs.forEach((uuid) => {
        userToSendMsg[uuid] = {
          userUUID: uuid,
          read: false,
          replied: false,
          deleted: false,
          viewed: false,
        };
      });
      if (this.replyMessage && this.replyMessage.length > 0) {
        const dataMsg = {
          body: this.replyMessage,
          header: "group-message",
          groupUUID: this.groupData.uuid,
          date: Date.now(),
          users: userToSendMsg,
          isReply: false,
          creatorUUID: this.ownUUID,
          masterMessageUUID: null,
          parentMessageUUID: null,
          type: "message",
        };
        newMessageEvent(dataMsg);
      }

      if (this.selectedFiles.length > 0) {
        this.isUploading = true;
        try {
          for (const file of this.selectedFiles) {

            file.status = 'uploading';

            const attachment = {
              body: '',
              header: "group-message",
              groupUUID: this.groupData.uuid,
              date: Date.now(),
              users: userToSendMsg,
              isReply: false,
              creatorUUID: this.ownUUID,
              masterMessageUUID: null,
              parentMessageUUID: null,
              type: "message",
              attachmentId: null,
              fileName: file.name,
            };

            const formData = new FormData();
            formData.append('file', file.data);
            formData.append('uuid', this.ownUUID);
            const result = await fetch("/api/fileUpload/upload", {
              method: "POST",
              headers: {
                'x-secret': this.secret,
              },
              body: formData,
            }).then((res) => res.json())
            if (result.success) {
              attachment.attachmentId = result.attachmentId;
              newMessageEvent(attachment);
              file.status = 'success';
            } else {
              dispatchErrorAlert("Error while uploading, please try again.");
            }
          }
        } catch (error) {
          dispatchErrorAlert("Error while uploading, please try again.");
        } finally {
          this.isUploading = false;
        }
      }

      this.replyMessage = "";
      this.selectedFiles = [];
    },
    applyFilter() {
      if (!this.groupData?.groupName) return;
      getGroupMessagesByGroupUIdEvent(this.groupData.uuid);
    },
    updateMessageParams(data) {
      for (const messages in data) {
        if (Object.prototype.hasOwnProperty.call(data, messages)) {
          const message = data[messages];
          if (message.users[this.state.ownUUID]) {
            if (!message.users[this.state.ownUUID].viewed) {
              markAsViewedSpecificMessageEvent(message.messageUUID);
            }
            if (!message.users[this.state.ownUUID].read) {
              markAsReadSpecificMessageEvent(message.messageUUID);
            }
          }
        }
      }
    },
    getIconForMimeType(mimeType) {
      const mimeIcons = {
        'application/pdf': 'mdi-file-pdf',
        'application/vnd.ms-excel': 'mdi-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-excel',
        'application/msword': 'mdi-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'mdi-file-word',
        'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'mdi-file-powerpoint',
        'text/plain': 'mdi-file-document',
        'text/html': 'mdi-language-html5',
        'text/css': 'mdi-language-css3',
        'text/javascript': 'mdi-language-javascript',
        'application/json': 'mdi-code-json',
        'application/xml': 'mdi-file-xml',
        'image/png': 'mdi-file-image',
        'image/jpeg': 'mdi-file-image',
        'image/gif': 'mdi-file-image',
        'image/svg+xml': 'mdi-file-image-outline',
        'audio/mpeg': 'mdi-file-music',
        'audio/ogg': 'mdi-file-music',
        'video/mp4': 'mdi-file-video',
        'video/mpeg': 'mdi-file-video',
        'application/zip': 'mdi-folder-zip',
        'application/x-rar-compressed': 'mdi-folder-zip',
        'application/sql': 'mdi-database',
        'application/vnd.apple.numbers': 'mdi-file-table-box',
        'application/vnd.apple.pages': 'mdi-file-document-outline'
      };
      return mimeIcons[mimeType] || 'mdi-file';
    },
    removeFile(fileId) {
      this.selectedFiles = this.selectedFiles.filter(file => file.id !== fileId);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const fileLimit = this.state.namespaceSettings.fileUploadConfiguration.fileSizeLimit;
      const maxFileSize = this.convertToBytes(fileLimit);
      const uploadedFiles = Array.from(event.target.files);
      const newFiles = [];

      for (const file of uploadedFiles) {
        if (file.size > maxFileSize) {
          dispatchErrorAlert(this.$t("generics.fileTooBig"));
          continue;
        }
        newFiles.push({
          id: this.generateUUID(),
          name: file.name,
          size: file.size,
          type: file.type,
          data: file,
          status: 'pending',
        });
      }

      this.selectedFiles = [...this.selectedFiles, ...newFiles];

      event.target.value = null;
    },
    convertToBytes(fileSizeString) {
      const match = fileSizeString?.toLowerCase().match(/^(\d+)(mb|gb)$/);
      if (!match) {
        return 10 * 1024 * 1024;
      }
      const value = parseInt(match[1], 10);
      const unit = match[2];
      if (unit === 'mb') {
        return value * 1024 * 1024;
      } else if (unit === 'gb') {
        return value * 1024 * 1024 * 1024;
      }
    },
    generateUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.clearSearch();
      }
    },
    performSearch() {
      if (this.searchQuery.trim() !== "") {
        this.search(this.searchQuery);
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.showSearch = false;
      this.searchQuery = "";
      this.messageHistory = this.historyMessagesStore.getState() || [];
      this.updateMessageParams(this.messageHistory);
      this.scrollToBottom();
    },
    search(query) {
      const messageCollection = this.historyMessagesStore.getState() || [];
      this.messageHistory = messageCollection.filter(message =>
        message.body.toLowerCase().includes(query.toLowerCase())
      );
      this.scrollToBottom();
    },
    handleKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault();
        this.onCtrlF();
      }
    },
    onCtrlF() {
      this.toggleSearch();
    },
  },
  watch: {
    groupData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.openChat = !!this.groupData?.groupName;
        if(this.openChat){
          this.groupMemberUUIDs = this.groupData.members.filter(member => !member.isRemoved).map(member => member.user.uuid) || [];
          this.applyFilter();
        }
      }
    },
    showModalMessage: {
      immediate: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        if (newVal) {
          this.openChat = !!this.groupData?.groupName;
          if(this.openChat){
            this.groupMemberUUIDs = this.groupData.members.filter(member => !member.isRemoved).map(member => member.user.uuid) || [];
            this.applyFilter();
          }
        }else{
          this.initSettingInputs();
        }
      }
    },
    messageHistory: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
      immediate: true, // Ensures it runs on initial load too
    },
    messageUIDs:{
      immediate: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        if (newVal) {
          let allMessages = this.historyMessagesStore.getState() || [];
          const filteredMessages = allMessages.filter(msg => newVal.includes(msg.messageUUID));
          this.messageHistory = filteredMessages;
          this.updateMessageParams(this.messageHistory);
        }
      }
    },
    messages: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.applyFilter();
      },
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    hasGroupData(){
      return !!this.groupData?.groupName;
    },
    isDisabledUser() {

      const userUUID = store.state.ownUUID;

      if (!this.groupData || !Array.isArray(this.groupData.members)) {
        return true; // If no groupData or members, disable input
      }

      // Check if the user exists in the group
      const isUserInGroup = this.groupData.members.some(member => member.user?.uuid === userUUID);

      if (!isUserInGroup) {
        return true; // If user is not in the group, disable input
      }

      // Check if the user is removed
      const isRemoved = this.groupData.members.some(
        member => member.isRemoved && member.user?.uuid === userUUID
      );

      return isRemoved; // If user exists but is removed, return true
    },
    allSelected() {
      return this.selectedMembers.length === this.fillDropDown.length;
    },
    titleModal() {
      let title = this.$t('components.groupChatModal.newGroup');
      if(this.groupData){
          title = this.groupData?.groupName || title;
      }

      return title;
    },
    messageUIDs: {
      get: function () {
        return this.showSendGroupChatMessageModalStore.messageUIDs || [];
      },
      set: function (newValue) {},
    },
    groupData: {
      get: function () {
        return this.showSendGroupChatMessageModalStore.groupData || {};
      },
      set: function (newValue) {},
    },
    showModalMessage: {
      get: function () {
        return this.showSendGroupChatMessageModalStore.show;
      },
      set: function (newValue) {},
    },
    isGroupFull() {
      return this.groupMemberUUIDs.length >= 500;
    },
    fillDropDown() {
      let result;
      const users = this.dataComboboxAllUsers;
      const tmpUsersArray = [];
      // get team members
      let data;
      for (const key in users) {
        data = {
          name: (users[key].user || {}).name || "",
          uuid: key,
          avatar:
            (users[key].user || {}).avatar || "img/default_profile_picture.png",
        };
        tmpUsersArray.push(data);
        result = tmpUsersArray;
      }
      if (!this.isGroupFull) {
        return result;
      } else {
        return [];
      }
    },
    shouldDisplayDeleteGroupButton(){
    // Ensure chat is not open AND group is already created AND user is either group admin or global admin
      return !this.openChat && this.hasGroupData && (this.isGroupAdmin || this.isAdmin);
    },
    isGroupAdmin() {
      const members = this.groupMembers();
      return members ? members.some(member => member.user.uuid === this.ownUUID && member.isAdmin) : false;
    },
    isAdmin() {
      return isAdmin(this.ownUUID)
    },
    historyMessagesStore() {
      return getMessageHistoryByGroup(this.groupMemberUUIDs);
    },
    secret() {
      const uuid = this.ownUUID;
      const localSecretKey = "ownSecret_" + uuid;
      return localStorage.getItem(localSecretKey);
    },
    dataComboboxAllUsers() {
      const membersList = JSON.parse(JSON.stringify(this.state.group));
      return membersList;
    },
  }
};
</script>

<style scoped>
.dialog-drag {
  user-select: text !important;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>
