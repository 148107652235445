<template>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t('components.groups.selectNewAdmin')}}
        </v-card-title>
        <v-card-text>
          <p>{{ $t('components.groups.selectNewAdminBeforeLaeving') }}</p>
          <v-select
            v-model="selectedAdmin"
            :items="adminOptions"
            item-title="name"
            item-value="uuid"
            return-object
            :label="$t('components.groups.selectMember')"
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="cancel">
            {{ $t('generics.cancel')}}
          </v-btn>
          <v-btn 
            color="primary" 
            :disabled="!selectedAdmin" 
            @click="confirmSelection"
          >
            {{ $t('generics.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'AdminSelectionDialog',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      groupMembers: {
        type: Array,
        default: () => []
      },
      currentAdminUuid: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        dialog: false,
        selectedAdmin: null
      }
    },
    computed: {
        adminOptions() {
            if (!this.groupMembers || !Array.isArray(this.groupMembers)) {
                return [];
            }
  
        return this.groupMembers
            .filter(member => member.user && member.user.user && member.user.uuid !== this.currentAdminUuid)
            .map(member => ({
            name: member.user.user.name,
            uuid: member.user.uuid
            }));
}
    },
    watch: {
        show(newVal) {
            this.dialog = newVal;
            if (newVal && this.adminOptions.length > 0) {
            this.selectedAdmin = this.adminOptions[0]; 
            } else {
            this.selectedAdmin = null;
            }
        },
        dialog(newVal) {
            this.$emit('update:show', newVal);
        }
    },
    methods: {
      confirmSelection() {
        this.$emit('admin-selected', this.selectedAdmin);
        this.dialog = false;
      },
      cancel() {
        this.$emit('cancelled');
        this.dialog = false;
      }
    }
  }
  </script>