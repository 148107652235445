<template>
  <v-dialog persistent :model-value="!!showModal" @update:model-value="showModal = $event" max-width="720"
    @keydown.esc="closeModal">
    <!-- :fullscreen="isMobile" -->
    <div>
      <v-card>
        <HeaderModal :titleModal="$t('generics.messages')" :closeModalFunction="closeModal" />
        <v-divider></v-divider>
        <v-row flex :style="{ width: '100%', margin: 0 }">
          <v-col v-for="n in 1" :key="n" class="col-12">
            <div v-if="n == 1" class="d-flex">
              <v-avatar tile size="76" class="borderRadius4" style="margin: 0px 10px" :style="getBorderByStatusFull(
                state.group[showModal], '2px'
              )">
                <DefaultAvatar :size="76" :userData="historyMessagesInfo.user" v-if="
                  getAvatarForUuid(showModal) ===
                  'img/default_profile_picture.png'
                "></DefaultAvatar>
                <v-img v-if="
                  getAvatarForUuid(showModal) !==
                  'img/default_profile_picture.png'
                " max-height="76" max-width="76" contain :src="getAvatarForUuid(showModal)"
                  lazy-src="img/default_profile_picture.png"></v-img>
              </v-avatar>
              <v-row :style="{ width: '80%', margin: 0 }">
                <v-col cols="12" class="pb-0 pt-1 d-flex flex-column">
                  <p color="primary" class="userNameCard mb-0 pb-0 mr-auto">
                    {{ getNameForUuid(showModal) }}
                  </p>
                  <div class="pt-6">
                    <v-tooltip location="top" v-if="!isWaitingRoomUser">
                      <template v-slot:activator="{ props }">
                        <v-btn density="compact" variant="text" @click.stop.prevent="callUser(showModal)" icon
                          :disabled="disableCallBtn(showModal) == true" v-bind="props">
                          <font-awesome-icon :icon="['fal', 'phone']" :color="setBorderByStatus(state.group[showModal])"
                            :style="{ fontSize: '16px' }"></font-awesome-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("components.userProfile.callUser") }}</span>
                    </v-tooltip>
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn density="compact" variant="text" @click.stop.prevent="toggleSearch()" icon v-bind="props"
                          v-if="hasMessages">
                          <font-awesome-icon :icon="['fal', 'search']" :color="'#808080'"
                            :style="{ fontSize: '16px' }"></font-awesome-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("generics.toggleSearchMessages") }}</span>
                    </v-tooltip>
                    <v-btn icon density="compact" variant="text" v-if="hasMessages">
                      <DeleteBasket :indexData="showModal" :delFunction="deleteMessage" fontSize="16" prevent="true" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="showSearch" class="search-input">
              <v-text-field type="text" v-model="searchQuery" @keyup.enter="performSearch"
                :bg-color="isDark ? '#1e1e1e' : '#fff'" autofocus hide-details variant="outlined" autocomplete="off"
                density="compact" :placeholder="$t('generics.searchMessages')">
                <template v-slot:append-inner>
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn class="ml-2" icon density="compact" variant="text" v-on:click="clearSearch"
                        :disabled="isUploading" v-bind="props">
                        <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '16px' }" />
                      </v-btn>
                    </template>
                    <span>{{ $t("generics.close") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row flex :style="{ width: '100%', margin: 0 }" :class="{ heightMobileMessages: isMobile }">
          <v-col class="col-12">
            <div>
              <v-container :class="`contentMessagesIsReply ${isMobile ? 'contentMessagesIsReplyMobile' : ''
                }`" ref="messageContainer">
                <MessageHistoryEffector :messages="messageHistory" />
              </v-container>
              <div class="px-6">
                <v-divider></v-divider>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <FooterModal :closeModalFunction="closeModal" class="mt-4"> -->
        <v-card :class="isDark ? 'footersBackgroundMessagesDark' : 'footersBackgroundMessages'"
          class="py-2 pl-4 pr-2 footerModal">
          <v-row class="pl-2 btns w-100 mx-0 my-0">
            <input type="file" multiple ref="fileInput" class="d-none" @change="handleFileUpload($event)" />
            <v-text-field :model-value="replyMessage" @update:model-value="replyMessage = $event"
              class="attachment-input" @keyup.enter="
                replyMessage.trim().length > 0 ? sendReply() : null
                " :bg-color="isDark ? '#1e1e1e' : '#fff'" autofocus hide-details variant="outlined"
              @keydown="handleKeydown"
              :maxlength="(!replyMessage.trim().length && 960) || (replyMessage.match(/(https?:|www\.)/i) ? 2048 : 480)"
              autocomplete="off" density="compact" :placeholder="$t('components.notificationsModal.reply')">
              <template v-slot:prepend-inner>
                <div class="attachments-wrapper">
                  <div v-for="file in selectedFiles" :key="file.id" class="attachment-container">
                    <v-icon :icon="getIconForMimeType(file.type)" color="grey-darken-2" size="40" />
                    <button v-if="file.status === 'pending'" class="remove-file-btn" @click="removeFile(file.id)"
                      aria-label="Remove attachment">
                      <font-awesome-icon :icon="['fal', 'times']" />
                    </button>

                    <button v-else-if="file.status === 'uploading'" class="remove-file-btn" aria-label="Uploading"
                      disabled>
                      <font-awesome-icon :icon="['fal', 'spinner']" spin style="color: blue"  />
                    </button>

                    <button v-else-if="file.status === 'success'" class="remove-file-btn" aria-label="Upload successful"
                      disabled>
                      <font-awesome-icon :icon="['fal', 'check']" style="color: green" />
                    </button>
                  </div>
                </div>
              </template>
              <template v-slot:append-inner>
                <v-btn class="ml-2" icon density="compact" variant="text" v-on:click="triggerFileInput"
                  :disabled="isUploading">
                  <font-awesome-icon :icon="['fal', 'file']" :style="{ fontSize: '20px' }" />
                </v-btn>
                <v-btn class="ml-2" @click="sendReply()" icon density="compact" variant="text"
                  :disabled="replyMessage.trim().length === 0 && selectedFiles.length === 0 || isUploading">
                  <template v-if="isUploading">
                    <font-awesome-icon :icon="['fal', 'spinner']" spin
                      :style="{ fontSize: '20px', color: 'primary' }" />
                  </template>
                  <template v-else>
                    <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" color="primary" />
                  </template>
                </v-btn>
              </template>
            </v-text-field>
            <v-btn rounded="5px" color="primary" style="border-radius: 4px; min-width: 45px;margin-top: 2px;" icon tile
              variant="outlined" density="comfortable" class="ml-3  mr-2 greyBtn" @click="closeModal()">
              <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
              <!--{{ $t("generics.cancel") }}-->
            </v-btn>
          </v-row>
        </v-card>
        <!-- </FooterModal> -->
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import moment from "../../../sharedsrc/moment";
import { isWaitingRoomUser } from "../../utils/privileges";
import MessageHistoryEffector from "../content/messageHistoryEffector.vue";
import {
  getBorderByStatusFull,
} from "../../utils/basicFunctions";
import {
  historyMessagesModalStore,
  resetHistoryMessagesModalEvent,
} from "../../effector/modals";
import {
  messages,
  newMessageEvent,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent,
  getMessageHistoryByUserUUID,
  dispatchDeleteConversationEvent,
} from "../../effector/message";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import FooterModal from "./modalComponents/footerModal.vue";
import {
  dispatchErrorAlert,
} from "../../effector/alerts";
export default {
  components: {
    DefaultAvatar,
    MessageHistoryEffector,
    HeaderModal,
    DeleteBasket,
    FooterModal
  },
  data() {
    const effector = {
      showModal: historyMessagesModalStore,
      messages: messages,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      loading: false,
      moment,
      selectedFiles: [],
      isUploading: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      showTextareaReply: false,
      rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
      messageHistory: [],
      replyMessage: "",
      showSearch: store.state.searchQuery?.length > 0,
      hasMessages: false,
      searchQuery: store.state.searchQuery,
      isMobile: isMobile(),
      // Effector
      ...effector,
    }
  },
  watch: {
    messages: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.messageHistory = this.historyMessagesStore.getState() || [];
        this.updateMessageParams(this.messageHistory);
        this.hasMessages = this.messageHistory.length > 0;
      },
    },
    totalMessages: {
      handler: function (total) {
        if (total) {
          const percentage =
            (this.$refs["messageContainer"].$el.scrollTop /
              (this.$refs["messageContainer"].$el.scrollHeight -
                this.$refs["messageContainer"].$el.clientHeight)) *
            100;
          if (percentage > 70) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 300);
          }
        }
      },
    },
  },
  mounted: function () {
    if (!this.isWaitingRoomUser) {
      this.updateMessageParams(this.messageHistory);
    }
    if (this.searchQuery && this.searchQuery.trim().length > 0) {
      this.search(this.searchQuery);
      store.state.searchQuery = null;
    } else {
      this.scrollToBottom();
    }
  },
  methods: {
    getBorderByStatusFull(person, customCss) {
      return getBorderByStatusFull(person, customCss);
    },
    deleteMessage(userUUID) {
      dispatchDeleteConversationEvent(userUUID);
      this.hasMessages = false;
    },
    disableCallBtn(uuid) {
      let person = this.state.group[uuid];
      return !!disableCallBtn(person);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    scrollToBottom() {
      // scroll to bottom
      this.$refs["messageContainer"].$el.scrollTop =
        this.$refs["messageContainer"].$el.scrollHeight;
    },
    updateMessageParams(data) {
      for (const messages in data) {
        if (Object.prototype.hasOwnProperty.call(data, messages)) {
          const message = data[messages];
          if (message.users[this.state.ownUUID]) {
            if (!message.users[this.state.ownUUID].viewed) {
              markAsViewedSpecificMessageEvent(message.messageUUID);
            }
            if (!message.users[this.state.ownUUID].read) {
              markAsReadSpecificMessageEvent(message.messageUUID);
            }
          }
        }
      }
    },
    getPerson(uuid) {
      return this.state.group[uuid];
    },
    badgetClassObj(userUUID) {
      return getStatusColorByUser(
        this.state.group[userUUID].user,
        this.state.group[userUUID].connected
      );
    },
    closeModal() {
      resetHistoryMessagesModalEvent();
    },
    getIconForMimeType(mimeType) {
      const mimeIcons = {
        'application/pdf': 'mdi-file-pdf',
        'application/vnd.ms-excel': 'mdi-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-excel',
        'application/msword': 'mdi-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'mdi-file-word',
        'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'mdi-file-powerpoint',
        'text/plain': 'mdi-file-document',
        'text/html': 'mdi-language-html5',
        'text/css': 'mdi-language-css3',
        'text/javascript': 'mdi-language-javascript',
        'application/json': 'mdi-code-json',
        'application/xml': 'mdi-file-xml',
        'image/png': 'mdi-file-image',
        'image/jpeg': 'mdi-file-image',
        'image/gif': 'mdi-file-image',
        'image/svg+xml': 'mdi-file-image-outline',
        'audio/mpeg': 'mdi-file-music',
        'audio/ogg': 'mdi-file-music',
        'video/mp4': 'mdi-file-video',
        'video/mpeg': 'mdi-file-video',
        'application/zip': 'mdi-folder-zip',
        'application/x-rar-compressed': 'mdi-folder-zip',
        'application/sql': 'mdi-database',
        'application/vnd.apple.numbers': 'mdi-file-table-box',
        'application/vnd.apple.pages': 'mdi-file-document-outline'
      };
      return mimeIcons[mimeType] || 'mdi-file';
    },
    removeFile(fileId) {
      this.selectedFiles = this.selectedFiles.filter(file => file.id !== fileId);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const fileLimit = this.state.namespaceSettings.fileUploadConfiguration.fileSizeLimit;
      const maxFileSize = this.convertToBytes(fileLimit);
      const uploadedFiles = Array.from(event.target.files);
      const newFiles = [];

      for (const file of uploadedFiles) {
        if (file.size > maxFileSize) {
          dispatchErrorAlert(this.$t("generics.fileTooBig"));
          continue;
        }
        newFiles.push({
          id: this.generateUUID(),
          name: file.name,
          size: file.size,
          type: file.type,
          data: file,
          status: 'pending',
        });
      }

      this.selectedFiles = [...this.selectedFiles, ...newFiles];

      event.target.value = null;
    },
    convertToBytes(fileSizeString) {
      const match = fileSizeString?.toLowerCase().match(/^(\d+)(mb|gb)$/);
      if (!match) {
        return 10 * 1024 * 1024;
      }
      const value = parseInt(match[1], 10);
      const unit = match[2];
      if (unit === 'mb') {
        return value * 1024 * 1024;
      } else if (unit === 'gb') {
        return value * 1024 * 1024 * 1024;
      }
    },
    generateUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.clearSearch();
      }
    },
    performSearch() {
      if (this.searchQuery.trim() !== "") {
        this.search(this.searchQuery);
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.showSearch = false;
      this.searchQuery = "";
      this.messageHistory = this.historyMessagesStore.getState() || [];
      this.updateMessageParams(this.messageHistory);
      this.scrollToBottom();
    },
    search(query) {
      const messageCollection = this.historyMessagesStore.getState() || [];
      this.messageHistory = messageCollection.filter(message =>
        message.body.toLowerCase().includes(query.toLowerCase())
      );
      this.scrollToBottom();
    },
    handleKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault();
        this.onCtrlF();
      }
    },
    onCtrlF() {
      this.toggleSearch();
    },
    async sendReply() {
      const userToSend = this.showModal;
      const userToSendMsg = {};
      userToSendMsg[userToSend] = {
        userUUID: userToSend,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };

      if (this.replyMessage && this.replyMessage.length > 0) {
        const dataMsg = {
          body: this.replyMessage,
          header: "individual message",
          date: Date.now(),
          users: userToSendMsg,
          isReply: false,
          creatorUUID: this.ownUUID,
          masterMessageUUID: null,
          parentMessageUUID: null,
          type: "message",
        };
        newMessageEvent(dataMsg);
      }

      if (this.selectedFiles.length > 0) {
        this.isUploading = true;
        try {
          for (const file of this.selectedFiles) {

            file.status = 'uploading';

            const attachment = {
              body: '',
              header: "individual message",
              date: Date.now(),
              users: userToSendMsg,
              isReply: false,
              creatorUUID: this.ownUUID,
              masterMessageUUID: null,
              parentMessageUUID: null,
              type: "message",
              attachmentId: null,
              fileName: file.name,
            };

            const formData = new FormData();
            formData.append('file', file.data);
            formData.append('uuid', this.ownUUID);
            const result = await fetch("/api/fileUpload/upload", {
              method: "POST",
              headers: {
                'x-secret': this.secret,
              },
              body: formData,
            }).then((res) => res.json())
            if (result.success) {
              attachment.attachmentId = result.attachmentId;
              newMessageEvent(attachment);
              file.status = 'success';
            } else {
              dispatchErrorAlert("Error while uploading, please try again.");
            }
          }
        } catch (error) {
          dispatchErrorAlert("Error while uploading, please try again.");
        } finally {
          this.isUploading = false;
        }
      }

      this.replyMessage = "";
      this.selectedFiles = [];
    },
    callUser(uuid) {
      setTimeout(function () {
        callPerson(uuid, this.$router);
      }, 200);
      this.closeModal();
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    isSentMessage(message) {
      if (message && message.creatorUUID == this.ownUUID) {
        return true;
      } else {
        return false;
      }
    },
    getDataUserMessage(message) {
      if (this.isSentMessage(message)) {
        return Object.keys(message.users)[0];
      } else {
        return message.creatorUUID;
      }
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    totalMessages() {
      return this.messageHistory.length;
    },
    historyMessagesStore() {
      const userUUID = this.showModal;
      return getMessageHistoryByUserUUID(userUUID);
    },
    historyMessagesInfo() {
      return store.state.group[this.showModal];
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    secret() {
      const uuid = this.ownUUID;
      const localSecretKey = "ownSecret_" + uuid;
      return localStorage.getItem(localSecretKey);
    },
  },
};
</script>

<style scoped lang="scss">
.borderRadius4 {
  border-radius: 4px !important;
}

.pointer {
  cursor: pointer;
}

.heightMobileMessages {
  height: calc(100% - 243px);
}

.inputMessageWaitingRoom {
  .v-input__append-inner {
    margin-top: 1px !important;
  }

  .v-text-field input {
    color: black !important;
    margin-top: 4px;
    padding: 15px !important;
  }
}

.contentMessages {
  height: calc(100vh - 400px) !important;
  overflow: auto !important;
}

.contentMessagesIsReplyMobile {
  // height: calc(100% - 10px) !important;
  height: 150px !important;
}

.contentMessagesIsReply {
  height: 388px;
  overflow: auto !important;
}

.btnMssgClass {
  width: 60px;
}

.btnCallClass {
  width: 70px !important;
  height: 70px !important;
}

.btns {
  justify-content: flex-end;
}

.btnMssgClass {
  width: 60px;
}

.btnCallClass {
  width: 70px;
  height: 70px !important;
}

.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.btnRed {
  background: red;
  border-radius: 4px;
}

.btnWhite {
  background: #ffffffa6;
  border-radius: 4px;
}

.vueScroll {
  height: calc(100% - 64px) !important;

  .__view {
    width: unset !important;
  }
}

.bR4 {
  border-radius: 4px;
  width: 36px;
  height: 36px !important;
  min-width: 36px !important;
}

.messageTextWrapper {
  height: 200px;
  white-space: pre-line;
}
</style>
<style lang="scss">
.inputMessageWaitingRoom {
  .v-text-field input {
    margin-top: 4px !important;
  }
}

.attachments-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  align-items: center;
  padding-top: 15px;
}

.attachment-container {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.attachment-icon {
  font-size: 40px;
  color: #333;
}

.remove-file-btn {
  position: absolute;
  top: -8px;
  left: 22px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f00;
  font-size: 12px;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.remove-file-btn:hover {
  background-color: #f5f5f5;
  color: #c00;
}

.reply-text-field {
  height: 60px;
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  padding-top: 12px;
}

.search-input {
  margin-top: 10px;
  padding: 2px;
}
</style>