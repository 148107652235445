<template>
  <div v-click-outside="onClose" style="height:100%">
    <!-- Lateral Panel -->
    <CallLateralPanel
      :isConferenceCall="isConferenceCall"
      :waitingRoomFor="waitingRoomFor"
      :showLateralPanel="showLateralPanel"
      :dataType="dataType"
      :handlerShowLateralPanel="handlerShowLateralPanel"
      :removeParticipantPerson="removeParticipantPerson"
      :showTimeLine="showTimeLine"
    />
    <!-- end lateral panel -->

    <!-- SIDEBAR -->
    <div v-if="maximizeScreen" class="d-flex flex-column w55 sidebarCall" :class="{w40: isMobile}">
      <v-tooltip right v-if="!isMobile">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            @click.stop.prevent="goToDefaultPage()"
            v-bind="props"
            class="mx-auto mt-1 mb-1"
          >
            <font-awesome-icon
              :icon="['fal', 'home']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("literals.Home") }}</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            ref="buttonMessages"
            icon
            density="comfortable"
            variant="text"
            @click="
              showLateralPanel && dataType == 'messages'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'messages')
            "
            v-bind="props"
            class="mx-auto mt-1 mb-1"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'messages',
            }"
          >
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
            <v-badge
              v-if="totalMessagesNotification > 0"
              right
              color="error"
              :content="totalMessagesNotification"
              :model-value="!!totalMessagesNotification"
              bordered
              overlap
              offset-x="-10"
              offset-y="-5"
              class="waitingRoomBadge"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.drawerContentCard.messages") }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="!isMobile"
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            @click="
              showLateralPanel && dataType == 'favorites'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'favorites')
            "
            class="mx-auto mt-1 mb-1"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'favorites',
            }"
          >
          <v-icon  class="primary--text-sidepanel"> mdi-star-outline</v-icon>
            <!-- <v-img
              contain
              max-height="28x"
              max-width="23px"
              src="img/icons/star-plus.svg"
              lazy-src="img/icons/star-plus.svg"
              width="16px"
              height="16px"
              style="margin-left: 3px"
            ></v-img> -->
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.favoritesMiniTT") }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            ref="buttonUsers"
            @click="usersIconClick"
            class="mx-auto mt-1 mb-1"
            :class="{ darkGreyIcon: showLateralPanel && (dataType === 'users' || (isMobile && dataType ==='favorites')) }"
          >
            <font-awesome-icon
              :icon="['fal', 'user-plus']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.userMiniTT") }}</span>
      </v-tooltip>
      <template v-if="getAmIModerator && hasPrivilege(ownUUID) && !isLandscape">
         <!-- && !getIsConferenceHall -->
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              icon
              density="comfortable"
              variant="text"
              @click="openModalSettings()"
            >
              <font-awesome-icon
              :icon="['fal', 'bolt']"
              :style="{ fontSize: '20px' }"
              class="color-white"
            />
            </v-btn>
          </template>
          <span>{{ $t("components.callsContent.addParticipants") }}</span>
        </v-tooltip>
      </template>
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon
            density="comfortable"
            variant="text"
            @click="handlerOpenVisitor(!!getconnectedWaitingrooms)"
            v-if="showWaitingRoom && hasPrivilege(ownUUID) && !isLandscape"
            class="mx-auto mt-1 mb-1"
            :class="{ darkGreyIcon: showLateralPanel && dataType === 'myWR' }"
          >
            <v-img
              contain
              max-height="28x"
              max-width="25px"
              src="img/icons/guest-plus-white.svg"
              lazy-src="img/icons/guest-plus-white.svg"
              width="23px"
              height="23px"
              style="margin-left: 5px"
            ></v-img>
            <v-badge
              v-if="getconnectedWaitingrooms > 0"
              right
              color="error"
              :content="getconnectedWaitingrooms"
              :model-value="!!getconnectedWaitingrooms"
              bordered
              overlap
              offset-x="7"
              offset-y="7"
              class="waitingRoomBadge"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.waitingRoomMiniTT") }}</span>
      </v-tooltip>
      <template v-if="showWaitingRoom && assistantsUser && !isMobile">
        <v-tooltip right v-for="uuid in assistantsUser" :key="uuid">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              @click="handlerOpenVisitor(!!getconnectedAssistantWaitingrooms[uuid])"
              icon
              density="comfortable"
              variant="text"
              :class="{
                darkGreyIcon: showLateralPanel && dataType === 'wrFor',
              }"
            >
              <v-img
                contain
                max-height="28x"
                max-width="25px"
                src="img/icons/guest-plus-white.svg"
                lazy-src="img/icons/guest-plus-white.svg"
                width="23px"
                height="23px"
                style="margin-left: 5px"
              ></v-img>
              <v-badge
                v-if="getconnectedAssistantWaitingrooms[uuid] > 0"
                right
                color="error"
                :content="getconnectedAssistantWaitingrooms[uuid]"
                :model-value="!!getconnectedAssistantWaitingrooms[uuid]"
                bordered
                overlap
                offset-x="7"
                offset-y="7"
                class="waitingRoomBadge"
              ></v-badge>
            </v-btn>
          </template>
          <span
            >{{
              $t("components.sidebarCall.waitingRoomFor", [
                getTitelForUuid(uuid),
                getNameForUuid(uuid),
              ])
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-if="hasPrivilege(ownUUID) && !isMobile">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/newconference')"
            >
              <font-awesome-icon
                :icon="['fal', 'users']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.groups.konferenzen") }}</span>
        </v-tooltip>
      </template>
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="!isMobile && !isIpad && marriageProjectList.length && !inBridgeConference"
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            @click="
              showLateralPanel && dataType == 'marriageProject'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'marriageProject')
            "
            class="mx-auto mt-1 mb-1"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'marriageProject',
            }"
          >
            <font-awesome-icon
              :icon="['fal', 'list']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.adminSettingsModal.marriageProject") }}</span>
      </v-tooltip>
      <template v-if="showOrganigram && hasPrivilege(ownUUID) && !isMobile">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/organisation')"
            >
              <font-awesome-icon
                :icon="['fal', 'sitemap']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.organisation") }}</span>
        </v-tooltip>
      </template>
      <template v-if="state.namespaceSettings.showCoffeeBreak && hasPrivilege(ownUUID) && !isMobile">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/coffee-break')"
            >
              <font-awesome-icon
                :icon="['fal', 'mug']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
              <v-badge
                v-if="getConnectedCoffeeBreak > 0"
                right
                color="error"
                :content="getConnectedCoffeeBreak"
                :model-value="!!getConnectedCoffeeBreak"
                bordered
                overlap
                offset-x="7"
                offset-y="7"
                class="waitingRoomBadge"
              ></v-badge>
            </v-btn>
          </template>
          <span>{{ $t("status.Coffee break") }}</span>
        </v-tooltip>
      </template>
      <v-tooltip right v-if="!isMobile">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="mx-auto mt-1 mb-1"
            icon
            density="comfortable"
            variant="text"
            @click="gotoPage('/new-room')"
          >
            <font-awesome-icon
              :icon="['fal', 'comment']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.sambaRooms.callRooms") }}</span>
      </v-tooltip>
      <!-- START SUPPORT ICONS -->
      <v-tooltip right v-if="!isLandscape">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-on="on"
            class="mx-auto mt-1 mb-1"
            @click="openBugModal()"
          >
            <font-awesome-icon
              v-bind="props"
              :icon="['fal', 'headset']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("generics.support") }}</span>
      </v-tooltip>
      <!-- START SOCIALMEDIA ICONS -->
      <v-tooltip right v-if="!isLandscape">
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="state.namespaceSettings.showSocialMedia && hasPrivilege(ownUUID)"
            icon
            density="comfortable"
            variant="text"
            class="mx-auto mt-1 mb-1"
            @click="gotoPage('/socialmedia')"
          >
            <font-awesome-icon
              v-bind="props"
              :icon="['fal', 'hashtag']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.socialMedia") }}</span>
      </v-tooltip>
      <!-- Support Calendar -->
      <template v-if="state.user.supportUser &&  !isMobile">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-1 mb-1"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/support-calendar')"
            >
              <font-awesome-icon
                :icon="['fal', 'calendar']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.supportCalendar.supportCalendarTitle") }}</span>
        </v-tooltip>
      </template>
      <!-- end Support Calendar -->
      <!-- PARTICIPANTS MENU ICONS -->
      <v-tooltip right v-if="!isLandscape">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            @click="
              showLateralPanel && dataType == 'participants'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'participants')
            "
            class="mx-auto mt-1 mb-1"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'participants',
            }"
          >
          <v-icon  class="primary--text-sidepanel flip-horizontal">mdi mdi-account-multiple </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.participantsMiniTT") }}</span>
      </v-tooltip>
      <!-- Call Ai Participant v-if="!showAskAiUser" -->
      <v-tooltip right v-if="!isLandscape">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            @click="
              showLateralPanel && dataType == 'aiParticipants'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'aiParticipants')
            "
            class="mx-auto mt-1 mb-1"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'aiParticipants',
            }"
          >
            <img
                style="width: 20px;  height: 20px;"
                  class="customIcons"
                  src="/img/aiPersonIcon.svg"
              />
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.callAi") }}</span>
      </v-tooltip>
      <!--*************ICONS VISIBLE ONLY ON PHONE*******************-->
      <v-btn
        v-if="isMobile"
        icon
        density="comfortable"
        variant="text"
        class="mx-auto mt-1 mb-1"
        @click="toggleMuteAudioFunction"
      >
        <font-awesome-icon
          v-if="!frameMuted"
          :icon="['far', 'volume-up']"
          :style="{ fontSize: '16px', color: 'white' }"
        />
        <font-awesome-icon
          v-else
          :icon="['far', 'volume-slash']"
          :style="{ fontSize: '16px', color: 'red' }"
        />
      </v-btn>
      <div v-if="isMobile && showAskAiUser" v-click-outside="closeAiMenuClick" id="askAiUser" >
        <div style="position: relative;">
          <v-menu
            :close-on-click="true"
            :close-on-content-click="true"
            min-width="150"
            v-model="showAiMenu"
            location="end"
            :activator="processingAiAudio ? '#activatorBtnAiMenuStop' : '#activatorBtnAiMenuStart'">
              <v-list theme="dark">
                <v-list-item class="pointer">
                  <v-list-item-title @click="(!processingAiAudio || aiUserRecording) ? toggleAskAiUser($event) : stopTalkingAi">{{!aiUserRecording ? $t("generics.start") : $t("generics.stop")}}</v-list-item-title>
                </v-list-item>
                <v-list-item class="pointer">
                  <v-list-item-title @click="openConversationAi">{{$t("components.callsContent.openConversation")}}</v-list-item-title>
                </v-list-item>
                <v-list-item class="pointer" v-if="!aiUserRecording">
                  <v-list-item-title @click="stopTalkingAi">{{$t("components.callsContent.stopTalking")}}</v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
          <v-btn
            v-if="!processingAiAudio"
            icon
            density="comfortable"
            variant="text"
            class="mx-auto mt-1 mb-1"
            color="white"
            id="activatorBtnAiMenuStart"
          >
            <img
              :style="`width: 20px; height: 20px;`"
              v-if="aiUserRecording"
              class="customIcons"
              src="/img/aiStopIcon.svg"
            />
            <img
              :style="`width: 20px; height: 20px;`"
              v-else
              class="customIcons"
              src="/img/aiStartIcon.svg"
            />
          </v-btn>
          <v-btn
            v-else
            icon
            density="comfortable"
            variant="text"
            class="mx-auto mt-1 mb-1"
            color="white"
            id="activatorBtnAiMenuStop"
          >
            <img
              :style="`width: 20px; height: 20px;`"
              class="customIcons"
              src="/img/aiStopProcesingIcon.svg"
            />
          </v-btn>
        </div>
      </div>
      <div v-if="isLandscape" v-click-outside="closeLandscapeMenu" >
        <div style="position: relative;">
          <v-menu
            :close-on-click="true"
            :close-on-content-click="true"
            min-width="150"
            v-model="showLandscapeMenu"
            location="end"
            activator="#activatorBtnLandscapeMenu">
              <v-list theme="dark" >
                <v-list-item v-if="getAmIModerator && hasPrivilege(ownUUID)">
                  <v-list-item-title @click="openModalSettings()">{{$t("components.callsContent.addParticipants")}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="showWaitingRoom && hasPrivilege(ownUUID)">
                  <v-list-item-title @click="handlerOpenVisitor()">{{$t("components.sidebar.waitingRoomMiniTT")}}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="openBugModal()">{{$t("generics.support")}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="state.namespaceSettings.showSocialMedia && hasPrivilege(ownUUID)">
                  <v-list-item-title @click="gotoPage('/socialmedia')">{{$t("components.sidebar.socialMedia")}}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="showLateralPanel && dataType == 'participants'
                    ? handlerShowLateralPanel(false, null)
                    : handlerShowLateralPanel(true, 'participants')">{{$t("components.sidebar.participantsMiniTT")}}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="showLateralPanel && dataType == 'aiParticipants'
                      ? handlerShowLateralPanel(false, null)
                      : handlerShowLateralPanel(true, 'aiParticipants')
                  ">{{$t("components.sidebar.callAi")}}</v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
          <v-btn
            icon
            density="comfortable"
            variant="text"
            class="mx-auto mt-1 mb-1"
            color="white"
            id="activatorBtnLandscapeMenu"
          >
            <font-awesome-icon
              :icon="['fal', 'ellipsis-h']"
              :style="{ fontSize: '16px' }"
            />
          </v-btn>
        </div>
      </div>
      <v-btn
        v-if="isMobile"
        icon
        density="comfortable"
        variant="text"
        class="mx-auto mt-1 mb-1"
        @click="toggleLeaveSession"
      >
        <font-awesome-icon
          :icon="['fas', 'phone']"
          :style="{ fontSize: '16px', color: 'red', transform: 'rotate(225deg)' }"
        />
      </v-btn>
      <!--*************END ICONS VISIBLE ONLY ON PHONE***************-->
      <!-- START askMe ICONS -->
      <!-- Requested to remove 07/04/25 -->
      <!-- <div class="askmeContainerCall" v-if="state.namespaceSettings.showGPT4BOT">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              icon
              density="comfortable"
              variant="text"
              v-on="on"
              class="mx-auto mt-1 mb-1 w100"
              @click="openGpt4bot()"
            >
              <font-awesome-icon
                v-bind="props"
                :icon="['fal', 'comments']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.sidebar.askYourQuestion") }}</span>
        </v-tooltip>
      </div> -->

      <AddParticipantsCall
        :closeModal="closeModalSettings"
        :showModalSettings="showModalSettings"
        :instaLink="instaLink"
        :handlerOpenVisitor="handlerOpenVisitor"
        :openUsers="openUsers"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../../store";
import { waitingInfoStore, waitingForInfoStore } from "../../../../effector/users/waitingInfo";
import { setSendBugModalEvent } from "../../../../effector/modals";
import {
  oneCallAndWaitingUser,
  hasPrivilege,
  basicView,
} from "../../../../utils/privileges";
import { canIShowOrganigram } from "../../../../utils/basicFunctions";
import CallLateralPanel from "./callLateralPanel.vue";
import ClickOutside from "vue-click-outside";
import { getDirectCallInviteLink } from "../../../../lib/wsMsg";
import { unseenMessagesToMe } from "../../../../effector/message";
import SettingsCall from "./settingsCall.vue";
import AddParticipantsCall from "./addParticipantsCall.vue";
import { isConferenceHallCall, amInABridgeCall, isConferenceCall, amInAStaticRoom } from "../../../../utils/calls";
import { isIpad, isLandscape } from "../../../../lib/mobileUtil";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  directives: {
    ClickOutside,
  },
  components: { CallLateralPanel, SettingsCall, AddParticipantsCall },
  props: ["maximizeScreen", "isConferenceCall", "getAmIModerator", "showAskAiUser", "removeParticipantPerson", "isMobile", "showTimeLine"],
  data() {
    const effector = {
      waitingUsesEffect: waitingInfoStore,
      waitingForUsesEffect: waitingForInfoStore,
      unseenMessagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      showLateralPanel: false,
      dataType: null,
      waitingRoomFor: false,
      showModalSettings: false,
      isIpad: isIpad(),
      isLandscape: isLandscape(),
      instaLink: "",
      frameMuted: undefined,
      showAiMenu: false,
      aiUserRecording: false,
      processingAiAudio: false,
      showLandscapeMenu:false,
      // Effector
      ...effector,
    };
  },
  mounted() {
    EventBus.$on("openModalSettingsCall", this.openModalSettings);
    EventBus.$on("openAiPanel", this.openAiPanel);
    EventBus.$on("closeAiPanel", this.onClose);
    EventBus.$on("sambaFrameMuted", this.setFrameMuted);
    EventBus.$on("sambaProcessingAiAudio", this.setProcessingAiAudio);
    EventBus.$on("sambaAiUserRecording", this.setAiUserRecording);
    // Listen for orientation changes
    window.addEventListener('resize', this.updateOrientation);
    // Also check the initial orientation when the component is mounted
    this.updateOrientation();

  },
  unmounted() {
    EventBus.$off("openModalSettingsCall", this.openModalSettings);
    EventBus.$off("openAiPanel", this.openAiPanel);
    EventBus.$off("closeAiPanel", this.onClose);
    EventBus.$off("sambaFrameMuted", this.setFrameMuted);
    EventBus.$off("sambaProcessingAiAudio", this.setProcessingAiAudio);
    EventBus.$off("sambaAiUserRecording", this.setAiUserRecording);
    // Cleanup the event listener when the component is destroyed
    window.removeEventListener('resize', this.updateOrientation);
  },
  methods: {
    updateOrientation() {
      // Update isLandscape based on the device's current orientation
      this.isLandscape = isLandscape();
    },
    toggleAskAiUser(e) {
      EventBus.$emit("toggleAskAiUserFromSidebarCall");
    },
    stopTalkingAi() {
      EventBus.$emit("stopTalkingAiFromSidebarCall");
    },
    openConversationAi() {
      if(this.aiChatConversationOpen){
        EventBus.$emit("closeAiPanel");
      } else {
        EventBus.$emit("openAiPanel");
      }
    },
    closeLandscapeMenu() {
      this.showLandscapeMenu = false;
    },
    closeAiMenuClick(event) {
      this.showAiMenu = false;
    },
    setAiUserRecording(value) {
      this.aiUserRecording = value;
    },
    setProcessingAiAudio(value) {
      this.processingAiAudio = value;
    },
    setFrameMuted(value) {
      this.frameMuted = value;
    },
    toggleMuteAudioFunction() {
      EventBus.$emit("toggleMuteAudioFunctionFromSidebarCall");
    },
    toggleLeaveSession() {
      EventBus.$emit("toggleLeaveSessionFromSidebarCall");
    },
    usersIconClick(){
      if (!this.isMobile) {
        this.gotoPage('/users');
      } else {
        if (this.showLateralPanel && this.dataType == 'users') {
          this.handlerShowLateralPanel(false, null);
        } else {
          this.openUsers();
        }
      }
    },
    openAiPanel(){
      setTimeout(() => {
        this.waitingRoomFor = null;
        this.showLateralPanel = true;
        this.dataType = 'aiText';
      }, 300);
    },
    openGpt4bot() {
      EventBus.$emit("openCloseGpt4bot");
    },
    async openModalSettings() {
      this.showModalSettings = true;
      this.instaLink = await getDirectCallInviteLink();
    },
    openBugModal() {
      setSendBugModalEvent(true)
    },
    closeModalSettings() {
      this.showModalSettings = false;
    },
    handlerOpenVisitor(navigateToWaitingRoom = false) {
      if (navigateToWaitingRoom) {
        this.goToWaitingRoomPage();
      } else {
        EventBus.$emit("handlerOpenVisitor");
      }
    },
    async copyLink(evt) {
      let instalink = this.instaLink; //await getDirectCallInviteLink();
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    openUsers() {
      this.showModalSettings = false;
      setTimeout(() => {
        this.handlerShowLateralPanel(true, "users");
      }, 250);
    },
    onClose(event) {
      if (event?.target?.className === 'v-list-item__title') return;
      this.handlerShowLateralPanel(false, null);
    },
    handlerShowLateralPanel(show, dataType, uuid = false) {
      this.waitingRoomFor = uuid;
      this.showLateralPanel = show;
      this.dataType = dataType;
    },
    gotoPage(route) {
      return this.setCurrentContentVisile(route, true, this.$router);
    },
    async goToDefaultPage() {
      await this.gotoPage(store.state.user.userSettings.startView);
      await this.$nextTick(); // Wait for render after navigation
      // setTimeout(() => {
      //   EventBus.$emit("openCloseSidebar");
      // }, 50);
    },
    async goToWaitingRoomPage() {
      await this.gotoPage("/waitingroom");
      await this.$nextTick(); // Wait for render after navigation
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    oneCallAndWaitingUser(uuid) {
      return oneCallAndWaitingUser(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    inBridgeConference() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return amInABridgeCall() && (isConferenceCall(callUUID) || amInAStaticRoom());
    },
    marriageProjectList() {
      return store.state.namespaceSettings.marriageProject;
    },
    getConnectedCoffeeBreak() {
      let tempCoffeeBreak = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Coffee break"
          ) {
            tempCoffeeBreak++;
          }
        }
      }
      return tempCoffeeBreak;
    },
    getIsConferenceHall() {
      return isConferenceHallCall(this.getCallUUID);
    },
    getCallUUID() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return callUUID;
    },
    totalMessagesNotification() {
      return (
        this.unseenMessagesToMe + this.state.persisted.totalNotificationFile
      );
    },
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getconnectedWaitingrooms() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    getconnectedAssistantWaitingrooms() {
      const connectedAssistantWaitingrooms = {};
      for (let waitingRoomFor of this.assistantsUser) {
        const assistantWaitingRoomList = store.getWaitingRoomList(waitingRoomFor);
        if (!assistantWaitingRoomList || !assistantWaitingRoomList.length) {
          connectedAssistantWaitingrooms[waitingRoomFor] = 0;
        } else {
          const calledVisitors = Object.entries(this.waitingForUsesEffect.called)
            .filter(([uuid, value]) => {
              return !!(value && assistantWaitingRoomList.find((e) => e.uuid === uuid))
            })
            .map(([uuid, value]) => uuid);
          connectedAssistantWaitingrooms[waitingRoomFor] = assistantWaitingRoomList.length - calledVisitors.length;
        }
      }
      return connectedAssistantWaitingrooms;
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
    isBasicView() {
      return basicView();
    },
    featureOrganizationOption() {
      return store.state.namespaceSettings.featureOrganization;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    assistantsUser() {
      let finalResult = [];
      if (this.state.user.assists && this.state.user.assists.length > 0) {
        finalResult = JSON.parse(JSON.stringify(this.state.user.assists));
      }
      if (
        this.state.user.waitingRoomAssists &&
        this.state.user.waitingRoomAssists.length > 0
      ) {
        this.state.user.waitingRoomAssists.forEach((uuid) => {
          if (finalResult.indexOf(uuid) == -1) {
            finalResult.push(uuid);
          }
        });
      }
      return finalResult.filter(uuid => hasOwn.call(this.state.group, uuid) && !!this.state.group[uuid]);
    },
    showWaitingRoom() {
      return this.state.user.userSettings.showWaitingRoom;
    },
  },
};
</script>
<style lang="scss">
.flip-horizontal {
  transform: scaleX(-1);
}
.waitingRoomBadge, .coffeeBreakBadge {
  .v-badge__wrapper {
    /* position: fixed !important; */
    .v-badge__badge {
      padding-left: 2px;
      padding-right: 0;
      position: absolute;
      /* width: 100%; */
    }
  }
}
</style>
<style scoped lang="scss">
.w100{
  width: 100%!important;
}
.askmeContainerCall{
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.darkGreyIcon {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
  height: 36px;
  width: 36px !important;
  min-width: 100%;
  text-align: center;
  border-radius: 0px;
}
.color-white {
  color: white;
}
.sidebarCall {
  background-color: #202124;
  height: 100%;
}
.w55 {
  width: 55px;
}
.w40 {
  width: 40px;
}
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  // padding: 2px 6px !important;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  // align-items: center;
  // justify-content: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
</style>