<template>
  <div class="w-100">
    <v-row
      class="w-100 mx-0 my-0 justify-space-between d-flex"
      v-if="!actualDate"
    >
      <v-col class="px-0 d-flex align-center" align="left">
        <v-card-title class="d-flex align-center w100" :class="isMobile ? 'headlineMobile' : 'headline'">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            height="30"
            width="30"
            contain
          ></v-img>
          <span
            :class="isMobile ? 'pl-2 spanModalCallMobile' : 'pl-4 spanModalCall'"
            class="font-weight-medium pr-2"
            v-html="titleModal"
          ></span>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                density="compact"
                variant="text"
                @click.stop.prevent="startGroupCall()"
                icon
                :disabled="disableGroupCallBtn() == true"
                v-bind="props"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  class="greenButton"
                  :style="{ fontSize: '16px' }"
                ></font-awesome-icon>
              </v-btn>
            </template>
            <span>{{ $t("components.addGroupModal.callAll") }}</span>
          </v-tooltip>

          <div class="ml-auto">
            <template v-if="groupMembers">
              <div class="text-center">
                <v-menu
                  open-on-hover
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      variant="outlined"
                      v-bind="props"
                    >
                      {{$t('generics.groupMembers')}} ({{ groupMembers.length }})
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in groupMembers"
                      :key="index"
                    >
                      <v-list-item-title>{{ item.user.user.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </div>
          <DeleteBasket 
            v-if="isAdmin(ownUUID) && openChat"
            indexData=""
            :delFunction="deleteMessagesFunction" 
            extraClasses="ml-4" 
            fontSize="17" 
            :prevent="false"
          />
          <LeftGroup 
          v-if="openChat"
          indexData=""
          :leftFunction="leaveGroup" 
          fontSize="17" 
          :prevent="false" />

          <AdminSelectionDialog
          :show="showAdminSelectionDialog"
          :group-members="groupMembers"
          :current-admin-uuid="ownUUID"
          @admin-selected="handleAdminSelected"
          @cancelled="handleSelectionCancelled"
        />
          <v-btn
            v-if="isGroupAdmin"
            :small="isMobile"
            density="comfortable"
            variant="text"
            class="ml-2"
            icon
              v-tooltip.top="$t('components.userSettings.groupChatSetting')"
            :color="'white'"
            v-on="props"
            @click="applyAction"
            id="v-tour-messages"
          >
            <font-awesome-icon
              v-bind="props"
              :color="state.persisted.isDark ? 'white' : 'black'"
              :icon="['fal', 'cog']"
              :style="{
                fontSize: '20px',
                position: 'relative',
              }"
            />
          </v-btn>
          <v-tooltip location="top" v-if="showInfoButton" content-class="videoButtonTT">
            <template v-slot:activator="{ props }">
              <font-awesome-icon
                v-bind="props"
                color="#2a3133"
                :icon="['fal', 'info-circle']"
                :style="{
                  fontSize: '17px',
                  position: 'relative',
                }"
              />
            </template>
            <span>
              {{
                showInfoButton == "Holidays"
                  ? $t("components.holidays.infoTT")
                  : showInfoButton == "Ill"
                    ? $t("components.holidays.infoIllTT")
                    : showInfoButton == "No Calls"
                      ? $t("status-tooltips.No Calls")
                      : ""
              }}
            </span>
          </v-tooltip>
          <slot></slot>
        </v-card-title>
      </v-col>

      <div :class="isMobile ? 'mr-4' : 'pa-4'" class="my-auto" v-if="closeModalFunction">
        <div>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                icon
                :size="isMobile ? 'x-small' : ''"
                color="black"
                ref="closeButton"
                @click="closeModalFunction"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-row>

    <v-card-title :class="`${isMobile ? 'headlineMobile' : 'headline styleHeader'}`" primary-title v-else>
      <!-- <v-row class="mx-0"> -->
      <v-row class="w-100 mx-0 my-0 justify-space-between d-flex align-center">
        <v-col cols="8" class="d-flex px-1 py-1">
          <v-img
            :max-height="30"
            :max-width="30"
            :src="'assets/icon.png'"
            class="imageLogo"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall"
            >{{ titleModal }}
          </span>
        </v-col>
        <v-col cols="4" class="px-1 py-1 text-right">
          <span class="font15 pr-2">{{ actualDate }}</span>
          <v-tooltip location="top" v-if="closeModalFunction">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                icon
                variant="text"
                color="black"
                ref="closeButton"
                @click="closeModalFunction($event)"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                  :color="state.persisted.isDark ? 'white' : 'black'"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.close") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
  </div>
</template>


<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import { disableCallBtn, wsCallStartBridgeCall } from "../../../utils/calls";
import { groupChattingInfoStore } from "../../../effector/users/groupChattingInfo";
import { uid } from "../../../utils/basicFunctions";
import { resetSendGroupChatMessageModalEvent } from "../../../effector/modals";
import { isAdmin } from "../../../utils/privileges";
import LeftGroup from "../../ui/leftGroup.vue";
import DeleteBasket from "../../ui/deleteBasket.vue";
import AdminSelectionDialog from "../../ui/adminSelectionDialog.vue";
export default {
  components: {DeleteBasket, LeftGroup, AdminSelectionDialog},
  props: ["titleModal", "closeModalFunction", "actualDate", "actionButton", "handleAction", "showInfoButton","settingButton", "groupMembers", "groupData", "openChat", "deleteMessagesFunction", "leaveFunction"],
  data() {
    const effector = {
      groupUsesEffect: groupChattingInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      isMobile: isMobile(),
      ownUUID: store.state.ownUUID,
      showAdminSelectionDialog: false,
      ...effector,
    }
  },
  mounted() {},
  methods: {
    applyAction() {
      if(this.handleAction){
        this.handleAction(true);
      }
    },
    deleteGroupMessages(){
      for (const groupMessage of this.groupData.messages){
        dispatchDeleteMessageEvent(groupMessage)
      }
    },
    isAdmin(uuid) {
      return isAdmin(uuid)
    },
    leaveGroup() {
      if (this.isGroupAdmin) {
        this.showAdminSelectionDialog = true;
      } else {
        this.showAdminSelectionDialog = false;
        this.leaveFunction();
      }
    },
    handleAdminSelected(newAdmin) {
      this.leaveFunction(newAdmin.uuid);
      this.showAdminSelectionDialog = false;
    }, 
    handleSelectionCancelled() {
      this.showAdminSelectionDialog = false;
    },
    startGroupCall() {
      const group = this.getGroupUsesEffect.groups?.find(sgc => sgc.uuid === this.groupData.uuid) || {};
      // Take all online users + ownUUID
      const usersToCall = [];

      group.members
        .filter(member => !disableCallBtn(this.state.group[member.user.uuid]))
        .map(member => (usersToCall.push(member.user.uuid)));
      //usersToCall.push(this.ownUUID);
      const duration = (24 * 3600 * 1000);
      const id = uid();

      const info = {
        callUUID: id,
        calling: usersToCall,
        isNormalMode: true,
        initiator: this.checkInitiator === '' ? this.state.ownUUID : this.checkInitiator,
        callStartTs: Date.now(),
        callDurationMs: duration,
        infiniteCall: false,
      };
  
      usersToCall.forEach((user, idx) => {
        const infoStartBridgeCall = {
          callUUID: id,
          isNormalMode: group.members > 0 ? true : false,
          initiator: this.state.ownUUID,
          callStartTs: Date.now(),
          callDurationMs: info.callDurationMs,
          infiniteCall: false,
          conferenceDocuments: this.documentsPdf || [],
          userConferencePosition: user.usersFunction || [],
          excludedFromCalling: [],
          conferenceAttendees: this.groupMembers || [],
          conferenceSettings: {},
          usersToHideName: [],
          isConference: true,
          confId: this.groupId,
          isConferenceCall: undefined
        };
        wsCallStartBridgeCall(user, this.state.ownUUID, infoStartBridgeCall, (idx > 0))
      });

      this.closeModal();

      store.addRemoteBridgeStream(id, info);
      store.setCurrentContentVisile("", false, this.$router);
    },
    disableGroupCallBtn() {
      const group = this.getGroupUsesEffect.groups?.find(sgc => sgc.uuid === this.groupData.uuid) || {};

      if(!group.members) return true;

      const disableCallBtns = group.members.map(member => disableCallBtn(this.state.group[member.user.uuid]));

      // We only need 1 online user as disableCallBtn is returning false for the ownUserUUID
      return disableCallBtns.filter(disableCallBtn => !disableCallBtn).length < 1;
    },
    closeModal() {
      resetSendGroupChatMessageModalEvent();
    },
  },
  computed: {
    isGroupAdmin() {
      return this.groupMembers ? this.groupMembers.some(member => member.user.uuid === this.ownUUID && member.isAdmin) : false;
    },
    getGroupUsesEffect() {
      return this.groupUsesEffect;
    },
  },
};
</script>

<style scoped lang="scss">
.headlineMobile {
  flex-wrap: unset !important;
  max-width: 100%;
}
.videoButtonTT {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.spanModalCall {
  font-size: 18px;
  word-break: break-word;
  max-width: 470px;
  line-height: 2;
  text-wrap: wrap;
}
.spanModalCallMobile{
  font-size: 18px;
  word-break: break-word;
  max-width: 290px;
  line-height: 2;
  text-wrap: wrap;
}
.w-100 {
  width: 100%;
}
.font15 {
  font-size: 15px;
}
/*.styleHeader{
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}*/
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}

.greenButton{
  color: #008000;
}
</style>